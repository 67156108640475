import React, { useState, useEffect } from 'react'
import { Flex, Spinner } from '@chakra-ui/react'
import { getLoginSavedData } from 'services/hooks'
import { useBarbershopStore } from 'services/stores'
import { useBarbershop } from 'services/hooks'
import { useQuery } from 'react-query'

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const { setUserData, setUser, setSubscription } = useBarbershopStore()
  const { getSubscriptionDatails } = useBarbershop()

  const { refetch } = useQuery(
    'get-subscription-details',
    () =>
      getSubscriptionDatails({
        onSuccess: (d) => setSubscription(d),
      }),
    { enabled: true }
  )

  const getUserData = async () => {
    const { error, user, userData } = await getLoginSavedData()
    if (!error) {
      setUser(user)
      setUserData(userData)
      refetch()
    }
    setLoading(false)
  }

  useEffect(() => {
    getUserData()
  }, [])

  return <Flex background="backgroundLayout">{loading ? <Spinner color="textLight" /> : children}</Flex>
}
