import { Box } from "@chakra-ui/react"
import { Table } from "components/molecules"
import { servicesColumns } from "./settings"


export const ServicesGoalsResume = ({data, isLoading}) => {
  return (
        <Box flex={1} mt="12px">
        <Table
          data={data ?? []}
          loading={isLoading}
          columns={servicesColumns}
          isSelectableRow
        //   onSelect={(item) => {
        //     setSelectedBarber(item)
        //     setDetailsModalOpen(true)
        //   }}
        />
      </Box>
    )
}