import React, { useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import {
  Flex, Box, Layout, ProfileModal,
  AdsModal,
} from 'components'
import { LogoutModal } from 'components/organisms'
import { ProfileMenu } from 'components/molecules/ProfileMenu'
import { ProfileContainer } from 'components/templates/ProfileContainer'
import { pxToRem } from 'styles/metrics'
import { useBreakpoint } from 'services/hooks'
import { useTranslation } from 'react-i18next'
import { DeleteAccountModal } from 'components/organisms'
import { saveAdsData, getAdsData } from 'services/hooks'
import { useBarbershopStore } from 'services/stores'

export const ProfileScreen = ({ ...props }) => {
  const { t } = useTranslation()
  const params = useParams()
  const { isDesktop } = useBreakpoint()
  const [profileModalOpen, setProfileModalOpen] = React.useState(false)
  const [logoutModalOpen, setLogoutModalOpen] = React.useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
  const [adsModalOpen, setAdsModalOpen] = React.useState(false)
  const { userData } = useBarbershopStore()
  const location = useLocation()

  useEffect(() => {
    const getData = async () => {
      const response = await getAdsData('welcome-intro-video')
      if (response !== false) {
        setAdsModalOpen(true)
      }
    }
    getData()
  }, [adsModalOpen])

  return (
    <Layout {...props} headerTitle={t('BARBERSHOP_PROFILE')} fullHeight={false}>
      {isDesktop ? (
        <Flex sx={{ gap: pxToRem(42) }} width="100%" pb={pxToRem(100)}>
          <ProfileMenu
            route={params?.section}
            onLogout={() => setLogoutModalOpen(true)}
            onDelete={() => setDeleteModalOpen(true)}
          />
          <ProfileContainer
            route={params?.section}
            onLogout={() => setLogoutModalOpen(true)}
          />
        </Flex>
      ) : (
        <Box width="full">
          <ProfileMenu
            route={params?.section}
            onSelect={() => setProfileModalOpen(true)}
            onLogout={() => setLogoutModalOpen(true)}
            onDelete={() => setDeleteModalOpen(true)}
          />
          <ProfileModal
            route={params?.section}
            isOpen={profileModalOpen}
            onClose={() => setProfileModalOpen(false)}
          />
        </Box>
      )}
      {logoutModalOpen && (
        <LogoutModal
          isOpen={logoutModalOpen}
          onClose={() => setLogoutModalOpen(false)}
        />
      )}
      {deleteModalOpen && (
        <DeleteAccountModal
          isOpen={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
        />
      )}
      <AdsModal
        primary_title={"Seja muito bem vindo ao BestBarbers!"}
        subtitle={'Nosso objetivo é facilitar a gestão da sua barbearia a alavancar os seus resultados'}
        description={"Com o BestBarbers você vai poder controlar seus agendamentos, comissões dos barbeiros, o financeiro da sua barbearia e muito mais!"}
        isOpen={adsModalOpen !== false && location.search.includes('register')}
        onClose={async () => { await saveAdsData('welcome-intro-video', false); setAdsModalOpen(false) }}
        link='https://www.youtube.com/watch?v=BLXXDbPaeU8'
      />
    </Layout>
  )
}

ProfileScreen.path = '/perfil/:section'
ProfileScreen.title = 'Profile'
ProfileScreen.secured = true
