import { api } from 'services/api/config'

export const useTeam = () => {
  const getAllCollaborators = async (props) => {
    const response = await api({
      url: `v3/barbershop/team/barbers/${props.id}`,
    })
    return response.data
  }

  const updateNFbarberData = async (body) => {
    const response = await api.put(`v3/barbershop/update-nf-barber-data`, body)
    return response.data
  }

  return {
    updateNFbarberData,
    getAllCollaborators,
  }
}
