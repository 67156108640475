import React from 'react'
import { Image } from '@chakra-ui/react'

import { Text, Flex, Button, Link } from 'components'

import { theme } from 'styles'
import { useBreakpoint } from 'services/hooks'

import heroSrc from '../images/section-hero.png'
import heroDevices from '../images/hero-device.png'
import circleLogo from '../images/circle-logo.png'
import { StoreIcons } from './stores'
import ReactPixel from 'react-facebook-pixel'

export const Hero = ({ clickButton }) => {
  const { isDesktop } = useBreakpoint()

  if (!isDesktop) {
    return (
      <Flex
        w={'100vw'}
        backgroundImage={`url(${heroSrc})`}
        backgroundSize="cover"
        backgroundPosition="center center"
        align="flex-start"
        justify="center"
        px="5vw"
        pt="5vh"
        flexDir="column"
      >
        <Text
          fontSize={theme.pxToRem(32)}
          kind="extraBold"
          mb={theme.pxToRem(12)}
        >
          A revolução da sua barbearia começa aqui
        </Text>
        <Text mb={theme.pxToRem(12)} fontSize={theme.pxToRem(14)} kind="medium">
          O BestBarbers é a plataforma que vai te ajudar a alcançar resultados
          de verdade na sua barbearia.
        </Text>
        <Button mb={theme.pxToRem(12)} size="small" onClick={clickButton}>
          Quero ser premium
        </Button>
        <Link
          mb={theme.pxToRem(32)}
          fontSize={theme.pxToRem(14)}
          onClick={() => {
            ReactPixel.trackCustom('btn_saber_mais')
            window.open(
              'https://wa.me/5531984818031?text=Olá!%20Gostaria%20de%20saber%20mais%20sobre%20o%20BestBarbers'
            )
          }}
        >
          Saber mais
        </Link>
        <Text fontSize={theme.pxToRem(12)} kind="medium">
          App disponível para iOS, Android e navegadores web.
        </Text>
        <StoreIcons posicao="inicio" />
        <Image mt={theme.pxToRem(32)} src={heroDevices} h="40vh" w="auto" />
      </Flex>
    )
  }
  return (
    <Flex
      w={'100vw'}
      h={'88vh'}
      backgroundImage={`url(${heroSrc})`}
      backgroundSize="cover"
      backgroundPosition="center center"
      align="center"
      justify="center"
      pl="10vw"
      pr="10vw"
    >
      <Flex w="45%" flexDir="column">
        <Flex>
          <Text
            w={theme.pxToRem(500)}
            fontSize={theme.pxToRem(40)}
            kind="extraBold"
            mb={theme.pxToRem(24)}
          >
            A revolução da sua barbearia começa aqui
          </Text>
        </Flex>
        <Text
          mb={theme.pxToRem(40)}
          fontSize={theme.pxToRem(16)}
          kind="medium"
          w={theme.pxToRem(500)}
        >
          O BestBarbers é a plataforma que vai te ajudar a alcançar resultados
          de verdade na sua barbearia.
        </Text>
        <Button mb={theme.pxToRem(12)} size="small" onClick={clickButton}>
          Quero ser premium
        </Button>
        <Link
          mb={theme.pxToRem(60)}
          fontSize={theme.pxToRem(14)}
          onClick={() => {
            ReactPixel.trackCustom('btn_saber_mais')
            window.open(
              'https://wa.me/5531984818031?text=Olá!%20Gostaria%20de%20saber%20mais%20sobre%20o%20BestBarbers'
            )
          }}
        >
          Saber mais
        </Link>
        <Text fontSize={theme.pxToRem(14)} kind="medium">
          App disponível para iOS, Android e navegadores web.
        </Text>
        <StoreIcons posicao="inicio" />
      </Flex>
      <Flex h="100%" align="flex-end">
        <Image src={heroDevices} h="60vh" w="auto" />
      </Flex>
      <Image
        position="absolute"
        bottom="-3vh"
        src={circleLogo}
        h={theme.pxToRem(140)}
        w={theme.pxToRem(140)}
      />
    </Flex>
  )
}
