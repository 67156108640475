import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Flex,
  Text,
  BarbershopAdsModal,
  Image,
  Box,
  Button,
  Loader,
} from 'components'
import { Layout } from 'components/templates'
import { useBarbershopStore } from 'services/stores'
import { useQuery, useMutation } from 'react-query'
import { useBarbershopAds } from 'services/hooks/use-barbershop-ads'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'

export const BarbershopAds = ({ props }) => {
  const { t } = useTranslation()
  const { userData } = useBarbershopStore()
  const { getBarbershopAds, deleteBarbershopAds } = useBarbershopAds()
  const { onLogout } = useLogoutPaymentIssues()

  const [addModalOpen, setModalOpen] = useState(false)

  const { data, isLoading, refetch, isRefetching } = useQuery(
    'get-barbershop-ads',
    () =>
      getBarbershopAds({
        barbershop_id: userData?.barbershop?.id || userData?.id,
      })
  )

  const deleteMutation = useMutation(
    'delete-ads',
    (ads_id) => deleteBarbershopAds({ ads_id }),
    {
      onSuccess: () => refetch(),
    }
  )

  useEffect(() => {
    onLogout()
  }, [userData])
  return (
    <Layout
      {...props}
      headerTitle={t('Anúncios')}
      fullHeight
      headerActionText="Adicionar novo anúncio"
      headerActionClick={() => setModalOpen(true)}
    >
      {(isLoading || isRefetching) && <Loader mb={8} />}
      {!isLoading &&
        data?.map((item) => {
          return (
            <Flex
              mb={4}
              borderColor="border"
              borderWidth={1}
              borderRadius={8}
              align="center"
              justify="space-between"
            >
              <Flex align="center">
                <Image
                  objectFit="cover"
                  w={400}
                  h={120}
                  src={item?.url}
                  borderRadius={8}
                  mr={8}
                />
                <Box>
                  <Text color="textMedium">ID do anúncio: {item?.id}</Text>
                  <Text>Link de redirecionamento ao clicar no anúncio:</Text>
                  <Text kind="semiBold">
                    {item?.redirect_link || 'Não informado'}
                  </Text>
                </Box>
              </Flex>
              <Flex mr={8} flexDir="column">
                <Button onClick={() => setModalOpen(item)} size="x-small">
                  Alterar
                </Button>
                <Button
                  onClick={() => deleteMutation.mutate(item?.id)}
                  mt={2}
                  kind="danger"
                  size="x-small"
                  isLoading={deleteMutation.isLoading}
                >
                  Excluir
                </Button>
              </Flex>
            </Flex>
          )
        })}
      <BarbershopAdsModal
        onClose={() => setModalOpen(false)}
        isModalOpen={addModalOpen !== false}
        ads_data={addModalOpen?.id ? addModalOpen : null}
      />
    </Layout>
  )
}

BarbershopAds.path = '/ads'
BarbershopAds.title = 'Anúncios'
BarbershopAds.secured = true
