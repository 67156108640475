import React, { useEffect, useMemo, useState } from 'react'
import { Box, Tab, TabList, Tabs, TabPanels, TabPanel } from '@chakra-ui/react'
import { t } from 'services/translation'
import { Text, Button, TextInput, CheckBox } from 'components/atoms'
import { useTheme } from '@chakra-ui/react'
import { SelectCard } from 'components/molecules'
import { Flex } from 'components/atoms'
import { useMutation, useQuery } from 'react-query'
import { useBarbershopStore } from 'services/stores'
import { useBestcoins } from 'services/hooks/use-bestcoins'
import { useToast } from '@chakra-ui/react'
import { MultiColumnChart } from 'components/molecules/MultiColumnChart'
import { useBarbershopGoals } from 'services/hooks/use-barber-goals'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { useBarbershopGoalsV2 } from 'services/hooks/use-barber-goals-v2'
import { ProductsGoalsResume } from './ProductsGoalsResume'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import { GeneralGoalsResume } from './GeneralGoalsResume'
import { ServicesGoalsResume } from './ServicesGoalsResume'
import { SignatureGoalsResume } from './SignatureGoalsResume'

export const BarberGoalsV2 = ({selectedDay}) => {
  const { userData } = useBarbershopStore()
  const { getAllGoalsResume } = useBarbershopGoalsV2()
  const [data, setData] = useState(null)
  const [signatureEnabled, setSignatureEnabled] = useState(false)
  const { onLogout } = useLogoutPaymentIssues()

  const theme = useTheme()
  const toast = useToast()

  useEffect(() => {
    onLogout()
  }, [])

  const { isLoading} = useQuery(
    ['get-resume-goals', selectedDay, signatureEnabled],
    () =>
      getAllGoalsResume({
        body: {
          start_date: format(startOfMonth(selectedDay), 'yyyy-MM-dd'),
          end_date: format(endOfMonth(selectedDay), 'yyyy-MM-dd'),
          signature_enabled: signatureEnabled
        },
        barbershop_id: userData?.id,
      }),
    {
      onSuccess: (values) => {
        setData([
          ...values?.barbersResume,
          ...values?.employeesResume,
        ])
      },
      onError: (error) => {
        toast({
          title: 'Erro ao buscar dados',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
      refetchOnWindowFocus: false,
    }
  )

  const graphic_data = useMemo(() => {
    const series = [
      {
        name: 'Total Vendido',
        data: [],
      },
      {
        name: 'Meta Prevista',
        data: [],
      },
    ]

    const categories = []
    data?.filter(a => a.barber_goals)?.forEach((i) => {
      series[0].data.push(i.total_value)
      series[1].data.push(!i.barber_goals?.filter?.(b => b?.type === 'total_sales') || i.barber_goals?.filter?.(b => b?.type === 'total_sales')?.length === 0 ? 0 : i.barber_goals?.[i.barber_goals?.filter(bg => bg?.type === 'total_sales')?.length - 1]?.value)
      categories.push(i?.collaborator?.name)
    })

    return {
      series,
      categories,
    }
  }, [data])

  return (
    <Box h="100vh" w="100%">
      <Flex pb={theme.pxToRem(12)} w="100%" justify="space-between">
      </Flex>
      <MultiColumnChart
        h="400px"
        w="100%"
        series={graphic_data?.series || []}
        type="money"
        title="Metas dos colaboradores"
        name="Metas dos colaboradores"
        categories={graphic_data?.categories || []}
      />
        <>
          <Tabs w="100%" onChange={(index) => {}}>
            <TabList
              color="textGrey"
              borderColor="modalBorder"
              borderBottomWidth={4}
            >
              <Tab
                w="33%"
                fontSize={14}
                fontWeight={900}
                _selected={{
                  color: '#E5B817',
                  borderColor: '#E5B817',
                  borderBottomWidth: 4,
                }}
              >
                Metas por produtos
              </Tab>
              <Tab
                w="33%"
                fontSize={14}
                fontWeight={900}
                _selected={{
                  color: '#E5B817',
                  borderColor: '#E5B817',
                  borderBottomWidth: 4,
                }}
              >
                Metas por serviços
              </Tab>
              <Tab
                w="33%"
                fontSize={14}
                fontWeight={900}
                _selected={{
                  color: '#E5B817',
                  borderColor: '#E5B817',
                  borderBottomWidth: 4,
                }}
              >
                Metas gerais
              </Tab>
              <Tab
                w="33%"
                fontSize={14}
                fontWeight={900}
                _selected={{
                  color: '#E5B817',
                  borderColor: '#E5B817',
                  borderBottomWidth: 4,
                }}
              >
                Metas considerando assinatura
              </Tab>
            </TabList>

            <TabPanels p={0} marginTop="12px">
              <TabPanel p={0}>
                <ProductsGoalsResume data={data?.filter(i => i?.barber_goals?.some(t => t?.type === 'product'))} isLoading={isLoading} />
              </TabPanel>
              <TabPanel p={0}>
                <ServicesGoalsResume data={data?.filter(i => i?.barber_goals?.some(t => t?.type === 'service'))} isLoading={isLoading} />
              </TabPanel>
              <TabPanel p={0}>
                <GeneralGoalsResume data={data?.filter(i => i?.barber_goals?.some(t => t?.type === 'total_sales'))} isLoading={isLoading} />
                </TabPanel>
                <TabPanel p={0}>
                <SignatureGoalsResume data={data?.filter(i => i?.barber_goals?.some(t => t?.signature_enabled))} isLoading={isLoading} />
                </TabPanel>
            </TabPanels>
          </Tabs>
        </>
    </Box>
  )
}
