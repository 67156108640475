import React, { useState, useEffect } from 'react'
import { useTheme, Center, useToast, Flex } from '@chakra-ui/react'
import { Text, Loader, Button, ModalMenu, ModalAlert, ConfirmReceivedCashModal, Link } from 'components'
import { pxToRem } from 'styles/metrics'
import { formatCurrency } from 'utils/formatCurrency'
import { MobileList } from '../MobileList'
import { t } from 'services/translation'
import { useQuery, useMutation } from 'react-query'
import { useSignature } from 'services/hooks/use-signature'
import { ReleaseDetailsCard } from 'components/molecules/ReleaseDetailsCard'
import { addHours, format, parseISO } from 'date-fns'

export const ReleaseDetailsModal = ({ payment, refetch, isOpen, onClose }) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const { getPaymentDetails, changeToReceivedInCash } = useSignature()
  const toast = useToast()

  const { data, isLoading } = useQuery(
    ['get-release-details', payment?.payment_id],
    () => getPaymentDetails(payment?.payment_id),
  )

  const mutate = useMutation(
    ['change-receveid-cash'],
    (params) => changeToReceivedInCash({body: {
      payment_id: payment?.payment_id,
      payment_date: params,
    }}),
    {
      onSuccess: () => {
        onClose()
        refetch()
        toast({
          title: 'Pagamentos atualizado com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )

  return (
    <ModalMenu
      isOpen={isOpen}
      onClose={onClose}
      // size='lg'
    >
      <Flex flexDirection="column" h="100%" overflow="scroll">
        {isLoading ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <>
            <Text fontSize={pxToRem(18)} kind="bold" marginBottom={pxToRem(26)}>
                Detalhes do pagamento
            </Text>
            <Flex flexDirection="row">
              <Text mr={theme.pxToRem(6)}>Descrição</Text>
              <Text kind="bold" color="primary">
                {payment?.title}
              </Text>
            </Flex>
            <Flex mt={theme.pxToRem(16)} flexDirection="row">
              <Text mr={theme.pxToRem(6)}>{t('VALUE')}:</Text>
              <Text kind="bold">
                {formatCurrency(
                  Number(payment?.value
                  )
                )}
              </Text>
            </Flex>
            <Flex mt={theme.pxToRem(16)} flexDirection="row">
              <Text mr={theme.pxToRem(6)}>{t('STATUS')}:</Text>
              <Text kind="bold" color={payment?.status === 'PAID' ? 'success' : payment?.status === 'PENDING' ? 'error' : 'grey'}>
                {t(payment?.status)}
              </Text>
            </Flex>
            <Flex mt={theme.pxToRem(16)} flexDirection="row">
              <Text mr={theme.pxToRem(6)}>Data de vencimento:</Text>
              <Text kind="bold">
                {payment?.due_simple_date ? format(parseISO(payment?.due_simple_date), 'dd/MM/yyyy') : payment?.due_at_date ? format(addHours(parseISO(payment?.due_at_date), 3), 'dd/MM/yyyy') : ''}
              </Text>
            </Flex>
            <Flex mt={theme.pxToRem(16)} flexDirection="row">
           {payment?.status === 'PENDING' && <Link onClick={payment?.status === 'PENDING'
                          ? () => window.open(payment?.invoice_url, '_blank') : null}>
          <Text kind="bold" fontSize={theme.pxToRem(14)} as="u" textDecoration="none" color='primary'>
            Link da fatura
          </Text>
        </Link>}
            </Flex>
            <Flex mt={theme.pxToRem(24)} flexDirection="column" w="100%">
              <Text kind="bold">Detalhes da fatura</Text>
              <MobileList
                emptyListMessage='Sem detalhes'
                data={data?.payment_details || []}
                loading={isLoading}
                renderItem={({ item }) => (
                    <ReleaseDetailsCard item={item} />
                )}
              /> 
            </Flex>
            
          </>
        )}
      </Flex>
      {payment?.status === 'PENDING' &&
      <Flex h="5%" mb="-60px" flexDir="row">
        <Button
          w='100%'
          type="submit"
          isLoading={mutate.isLoading}
          onClick={() => setOpen(true)}
        >
          Considerar como pago externamente
        </Button>
      </Flex>}

      <ConfirmReceivedCashModal
        isOpen={open}
        onClose={() => setOpen(false)}
        onClick={(date) =>
          mutate.mutate(date)
        }
        isLoading={mutate.isLoading}
      />

      {/* <SelectServiceModal
        isModalOpen={serviceOpen}
        onClose={() => setServiceOpen(false)}
        client_id={client_id}
        barbershop_id={barbershop_id}
      /> */}

     
    </ModalMenu>
  )
}
