import React, { useState } from 'react'
import { Box, Center, Flex, Spinner } from '@chakra-ui/react'
import { ColumnChart, SelectCard } from 'components/molecules'
import { Select } from 'components-v2/atoms/Select'
import { useBarbershop } from 'services/hooks'
import { filters, renderCards } from './settings'
import { InputCard } from 'components-v2/molecules'
import { getInputTimeProcessed } from 'utils'
import { useBarbershopStore } from 'services/stores'
import { useOccupancyRate } from 'services/hooks/use-occupancy-rate'
import { useQuery } from 'react-query'
import { productiveCapacity } from 'utils/indicators'
import { Text } from 'components/atoms'

export const OccupancyRate = ({ startDate, endDate }) => {
  const [type, setType] = useState('automatic')
  const [barber, setBarber] = useState()
  const [status, setStatus] = useState()
  const [graphicType, setGraphicType] = useState({
    id: 2,
    name: 'Por barbeiro',
    value: 'barbers',
  })
  const [graphic, setGraphic] = useState({ data: [], categories: [] })
  const [values, setValues] = useState(renderCards)
  const { getBarbers } = useBarbershop()
  const { userData } = useBarbershopStore()
  const { getOccupancyRate } = useOccupancyRate()

  const { data: barbers } = useQuery(['get-only-barbers'], () =>
    getBarbers({ barbershop_id: userData?.id })
  )

  const { isLoading, refetch } = useQuery(
    ['get-occupancy-rate', type, barber, startDate, endDate, status],
    () =>
      getOccupancyRate({
        id: userData?.id,
        type,
        barber,
        startDate,
        endDate,
        status,
      }),
    {
      onSuccess: (data) => {
        const val = values?.map((v) => {
          if (v?.key === 'product_capacity' || v?.key === 'occupancy_rate') {
            return {
              ...v,
            }
          }
          return {
            ...v,
            [v.key]: data[v.key],
          }
        })
        setGraphic(data?.graphic)
        setValues(val)
      },
      enabled: type === 'automatic',
      refetchOnWindowFocus: false,
    }
  )

  const handleSelectBarber = (b) => {
    const [_barber] = barbers?.filter(
      (item) => b?.target?.value === item?.name || b === item?.name
    )
    setBarber(_barber)
  }

  const handleSelectGraphic = (gra) => {
    const [_graphic] = filters?.filter(
      (item) => gra?.target?.value === item?.name || gra === item?.name
    )
    setGraphicType(_graphic)
  }

  const onChange = (key, value) => {
    const changed = values?.map((item) => {
      if (item.key === key) {
        return {
          ...item,
          [key]:
            key === 'working_hours' || key === 'working_minutes'
              ? getInputTimeProcessed(value)
              : value,
        }
      }
      return item
    })
    setValues(changed)
  }

  return (
    <Box w="100%" mt="24px">
      <Flex w="100%" align="center" mb="24px" justify="space-between">
        <SelectCard
          px="8px"
          item={{
            title: 'Cálculo automático',
          }}
          setSelected={() => {
            setType('automatic')
            refetch()
          }}
          selected={type === 'automatic'}
          h="52px"
          mr="5"
          w="20%"
        />
        <SelectCard
          px="8px"
          item={{
            title: 'Cálculo manual',
          }}
          setSelected={() => setType('manual')}
          selected={type === 'manual'}
          h="52px"
          mr="5"
          w="20%"
        />

        <Select
          w="250px"
          label="Barbeiro"
          name="select_barber"
          onChange={handleSelectBarber}
          value={barber?.name}
          list={barbers}
          render={'name'}
        />

        <Select
          w="300px"
          label="Status do agendamento"
          name="status"
          onChange={(e) => setStatus(e.target.value)}
          value={status}
          list={['Finalizado', 'Todos']}
        />

        <Select
          w="250px"
          defaultValue={graphicType?.name}
          mr="0"
          label="Gráfico"
          name="graphic"
          onChange={handleSelectGraphic}
          value={graphicType?.name}
          list={filters}
          render={'name'}
        />
      </Flex>
      {isLoading ? (
        <Center w="100%">
          <Spinner color="textLight" />
        </Center>
      ) : (
        <Flex w="100%" justify="space-between">
          <Flex flexDir="column" w="49%">
            <Text mb={4} color="white" fontSize={20} kind="bold">
              Taxa de ocupação da sua barbearia:{' '}
              {productiveCapacity(values)?.occupancy_rate}%
            </Text>
            {renderCards.map((render, idx) => (
              <InputCard
                text={render.name}
                input_value={
                  idx === 0 || idx === 2
                    ? getInputTimeProcessed(values[idx][render.key])
                    : values[idx][render.key] ?? 0
                }
                onChange={(e) => onChange(render.key, e?.target?.value)}
                disabled={type === 'automatic'}
              />
            ))}
            <InputCard
              text="Capacidade produtiva mensal:"
              input_value={productiveCapacity(values)?.product_capacity}
              disabled
            />
          </Flex>
          <Flex w="49%">
            <ColumnChart
              h="550px"
              w="100%"
              data={graphic[graphicType?.value]?.data}
              title="Taxa de ocupação"
              name="Taxa de ocupação"
              categories={graphic[graphicType?.value]?.categories}
            />
          </Flex>
        </Flex>
      )}
      {/* <Text
                        kind="extraBold"
                        fontSize='24px'
                      >
                        Memória de cálculo
                      </Text> */}
    </Box>
  )
}
