import React, { useState, useMemo, useEffect } from 'react'
import {
  Box,
  Text,
  Image,
  Icon,
  AppointmentDetailsModal,
  ClientObservationsModal,
  ModalAlert,
  ModalMenu,
  ObservationCard,
  AppointmentCard,
  FinanceResumeCard,
  Button,
  Link,
  FeatureBlockedMessage,
  ClientsModal,
  Loader,
  ClientReleaseModal,
} from 'components'

import { pxToRem } from 'styles/metrics'
import clientSrc from 'assets/img/placeholders/client.png'
import { Email, Money, Scissors, Star, Telephone } from 'assets/icons'
import { colors } from 'styles'
import { useBreakpoint } from 'services/hooks'
import { useBarbershopStore, useAppointmentDetailStore, useCustomerStore } from 'services/stores'
import { useTheme, useToast, Flex, useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'

import { GET_CLIENT_DETAIL, DELETE_CLIENT } from 'services/api/endpoints'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { api } from 'services/api/config'
import { formatPhone } from 'utils/phone-formater'
import { format, parseISO } from 'date-fns'
import { usePremiumBlock } from 'services/hooks/use-premium-block'
import { mockHistoricClient, mockObservation } from './mocks'
import { usePermissionCollaborator } from 'services/hooks'
import { useBarbershop } from 'services/hooks'
import { formatPrice } from 'utils'

const BlockedFeature = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Flex
      flexDir="column"
      align="center"
      justify="center"
      width="100%"
      height="100%"
      position="absolute"
      bg="rgba(17, 17, 17, 0.7)"
      zIndex={5}
    >
      <Icon name="PremiumCrown" size={26} />

      <Text kind="bold" fontSize={14} color="primary" mt={theme.pxToRem(2)}>
        {t('PREMIUM_EXCLUSIVE')}
      </Text>
    </Flex>
  )
}

export const ClientDetailModal = ({ isModalOpen, onClose }) => {
  const theme = useTheme()
  const toast = useToast()
  const { isDesktop } = useBreakpoint()
  const { userData } = useBarbershopStore()
  const { selectedCustomer } = useCustomerStore()
  const { t } = useTranslation()
  const isBlocked = usePremiumBlock()
  const [detailsModalVisible, setDetailsModalVisible] = useState(false)
  const [clientReleaseModal, setClientReleaseModal] = useState(false)
  const [data, setData] = useState([])
  const [observationsModalVisible, setObservationsModalVisible] = useState(false)
  const [deleteCustomerModal, setDeleteCustomerModal] = useState(false)
  const clients = usePermissionCollaborator('clients')
  const [app, setApp] = useState()
  const { getClientsAppointments } = useBarbershop()

  async function getClientDetail() {
    if (!userData?.id || (!selectedCustomer?.client_id && !selectedCustomer?.id)) {
      throw new Error('Cliente não identificado')
    }
    const res = await api
      .get(GET_CLIENT_DETAIL(userData?.id, selectedCustomer?.client_id || selectedCustomer?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { isLoading, refetch } = useQuery(['get-client-detail', selectedCustomer], getClientDetail, {
    refetchOnWindowFocus: false,
    onSuccess: (dat) => {
      setData(dat)
    },
  })

  const signature = useMemo(() => {
    return data?.subscriptions?.find((i) => i?.type === 'signature')
  }, [data])

  const package_service = useMemo(() => {
    return data?.subscriptions?.find((i) => i?.type === 'individual_package_service')
  }, [data])

  async function deleteClient() {
    const res = await api
      .delete(DELETE_CLIENT(selectedCustomer?.client_id || selectedCustomer?.id, userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }
  const mutate = useMutation('delete-client', deleteClient, {
    onSuccess: () => {
      setDeleteCustomerModal(false)
      onClose()
      toast({
        title: t('DELETE_CLIENT'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
    },
    onError: (error) => {
      toast({
        title: t('DELETE_CLIENT_ERROR'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const { isLoading: loadingAppointments, refetch: refetchAppointments } = useQuery(
    'get-client-appointments',
    () =>
      getClientsAppointments({
        barbershop_id: userData?.id,
        client_id: selectedCustomer?.client_id || selectedCustomer?.id,
      }),
    {
      enabled: false,
      onSuccess: (val) => {
        setData({
          ...data,
          appointments: [...val?.appointments],
        })
      },
    }
  )

  const onSelectAppointment = (item) => {
    setApp(item)
    setDetailsModalVisible(true)
  }

  const onCloseModals = () => {
    setDetailsModalVisible(false)
    setApp(null)
  }

  // const totalProduct = useMemo(() => {
  //   const totalProduct =
  //     !!data?.statistics?.length &&
  //     data?.statistics
  //       .map((item) => item?.additional_products_total_price)
  //       .reduce((total, current) => total + current, 0)
  //   return totalProduct
  // }, [data?.statistics])

  // const totalService = useMemo(() => {
  //   const total =
  //     !!data?.statistics?.length &&
  //     data?.statistics
  //       .map((item) => item?.product_price)
  //       .reduce((total, current) => total + current, 0)
  //   return total
  // }, [data])

  const { isOpen, onOpen, onClose: onCloseBlock } = useDisclosure()

  const { isOpen: isEditModalOpen, onOpen: onOpenEditModal, onClose: onCloseEditModal } = useDisclosure()

  const handleCloseEditModal = () => {
    onCloseEditModal()
    onClose()
  }
  return (
    <>
      <FeatureBlockedMessage isModal isOpen={isOpen} onClose={onCloseBlock} title="WHATSAPP_BLOCKED" />

      <ModalMenu title="Detalhes do cliente" isOpen={isModalOpen} onClose={onClose} className="modal">
        {(isLoading || loadingAppointments) && <Loader />}
        {!isLoading && !loadingAppointments && (
          <>
            <Box marginBottom={pxToRem(22)}>
              <Flex alignItems="center" marginBottom={pxToRem(10)}>
                <Image
                  w={32}
                  h={32}
                  fit={data?.client?.profile_image_url ? 'cover' : 'contain'}
                  src={data?.client?.profile_image_url || clientSrc}
                  mr={4}
                />
                <Box>
                  <Text kind="extraBold" fontSize={pxToRem(24)}>
                    {data?.client?.name}
                  </Text>
                  <Text kind="normal" fontSize={pxToRem(14)}>
                    ID do cliente: {data?.client?.id}
                  </Text>
                  {data?.barbershopClient?.createdAt && (
                    <Text kind="normal" fontSize={14}>
                      {`${t('CLIENT_UNTIL')} ${format(parseISO(data?.barbershopClient?.createdAt), 'dd/MM/yyyy')}`}
                    </Text>
                  )}
                  {clients === 'edit' && (
                    <Button mt={4} size="x-small" onClick={() => onOpenEditModal()}>
                      Editar dados do cliente
                    </Button>
                  )}
                </Box>
              </Flex>

              <Text kind="extraBold" size={pxToRem(18)} marginBottom={pxToRem(16)}>
                {t('INFORMATIONS')}
              </Text>

              <Flex align="center" justify="space-between" width="100%">
                <Box display="flex" alignItems="center">
                  <Box
                    marginRight={pxToRem(16)}
                    padding={pxToRem(12)}
                    backgroundColor={colors.whiteTransparent[100]}
                    borderRadius={pxToRem(5)}
                    width={pxToRem(44)}
                    height={pxToRem(44)}
                    display="flex"
                    alignItems="center"
                  >
                    <Telephone color={colors.primary} height={pxToRem(20)} />
                  </Box>
                  <Box>
                    <Text>{formatPhone(data?.client?.user?.phone, userData)}</Text>
                    <Text kind="regular">{t('PHONE')}</Text>
                  </Box>
                </Box>

                {clients === 'edit' && (
                  <Box cursor="pointer" onClick={() => isBlocked && onOpen()}>
                    <Link
                      pointerEvents={isBlocked && 'none'}
                      href={`https://wa.me/${data?.client?.user?.phone}?text=Olá, ${data?.client?.name}!`}
                      isExternal
                    >
                      <Icon name="WhatsApp" size={40} />
                    </Link>
                  </Box>
                )}
              </Flex>
            </Box>

            {!!data?.client?.user?.email && (
              <Box display="flex" alignItems="center" mb={theme.pxToRem(24)}>
                <Box
                  marginRight={pxToRem(16)}
                  padding={pxToRem(12)}
                  backgroundColor={colors.whiteTransparent[100]}
                  borderRadius={pxToRem(5)}
                  width={pxToRem(44)}
                  height={pxToRem(44)}
                  display="flex"
                  alignItems="center"
                >
                  <Email color={colors.primary} width={pxToRem(20)} />
                </Box>
                <Box maxW="85%">
                  <Text kind="bold" maxW={'100%'} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                    {data?.client?.user?.email}
                  </Text>
                  <Text kind="regular">Email</Text>
                </Box>
              </Box>
            )}
            <Box display="flex" alignItems="center" mb={theme.pxToRem(24)}>
              <Box
                marginRight={pxToRem(16)}
                padding={pxToRem(12)}
                backgroundColor={colors.whiteTransparent[100]}
                borderRadius={pxToRem(5)}
                width={pxToRem(44)}
                height={pxToRem(44)}
                display="flex"
                alignItems="center"
              >
                <Money color={colors.primary} width={pxToRem(20)} />
              </Box>
              <Box maxW="85%">
                <Text kind="bold" maxW={'100%'} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                  {`Saldo do cliente: ${formatPrice(data?.barbershopClient?.client_balance || 0)}`}
                </Text>
                <Text
                  kind="medium"
                  color={isBlocked ? 'textGrey' : 'primary'}
                  cursor={clients === 'edit' && 'pointer'}
                  onClick={() => clients === 'edit' && setClientReleaseModal(true)}
                >
                  Gerenciar
                </Text>
              </Box>
            </Box>
            {signature && (
              <Box display="flex" alignItems="center" mb={theme.pxToRem(24)}>
                <Box
                  marginRight={pxToRem(16)}
                  padding={pxToRem(12)}
                  backgroundColor={colors.whiteTransparent[100]}
                  borderRadius={pxToRem(5)}
                  width={pxToRem(44)}
                  height={pxToRem(44)}
                  display="flex"
                  alignItems="center"
                >
                  <Star color={colors.primary} width={pxToRem(20)} />
                </Box>
                <Box maxW="85%">
                  <Text kind="bold" maxW={'100%'} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                    {`Cliente assinante do plano: ${signature?.plan?.name ?? signature?.product?.name}`}
                  </Text>
                  <Text kind="regular">Assinatura</Text>
                </Box>
              </Box>
            )}

            {package_service && (
              <Box display="flex" alignItems="center" mb={theme.pxToRem(24)}>
                <Box
                  marginRight={pxToRem(16)}
                  padding={pxToRem(12)}
                  backgroundColor={colors.whiteTransparent[100]}
                  borderRadius={pxToRem(5)}
                  width={pxToRem(44)}
                  height={pxToRem(44)}
                  display="flex"
                  alignItems="center"
                >
                  <Scissors color={colors.primary} width={pxToRem(20)} />
                </Box>
                <Box maxW="85%">
                  <Text kind="bold" maxW={'100%'} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                    {`Cliente usuário do pacote: ${package_service?.product?.name}`}
                  </Text>
                  <Text kind="regular">Pacote de serviço</Text>
                </Box>
              </Box>
            )}

            <Text kind="extraBold" fontSize={18}>
              {t('CUSTOMER_STATISTICS')}
            </Text>

            <Box position="relative" py={theme.pxToRem(1)}>
              {isBlocked && <BlockedFeature />}

              {isDesktop ? (
                <Flex w="100%" flexDir="row" alignItems="stretch" justifyContent="space-between" my={theme.pxToRem(24)}>
                  <FinanceResumeCard
                    notCurrency
                    label={t('APPOINTMENTS')}
                    color="textLight"
                    value={isBlocked ? 10 : data?.totalAppointment || 0}
                    description={t('FINISHED')}
                    width="33%"
                  />
                  <FinanceResumeCard
                    label={t('PROFIT')}
                    color="success"
                    sizeFont
                    value={isBlocked ? 190 : data?.totalProduct?.[0]?.total || 0}
                    description={t('PRODUCTS')}
                    width="33%"
                  />

                  <FinanceResumeCard
                    last
                    label={t('PROFIT')}
                    sizeFont
                    color="success"
                    value={isBlocked ? 345.9 : data?.totalService?.[0]?.total || 0}
                    description={t('SERVICES')}
                    width="33%"
                  />
                </Flex>
              ) : (
                <Box my={theme.pxToRem(24)}>
                  <ScrollMenu>
                    <FinanceResumeCard
                      notCurrency
                      label={t('APPOINTMENTS')}
                      color="textLight"
                      value={isBlocked ? 10 : data?.totalAppointment || 0}
                      description={t('FINISHED')}
                    />

                    <FinanceResumeCard
                      label={t('PROFIT')}
                      color="success"
                      value={isBlocked ? 190 : data?.totalProduct?.[0]?.total || 0}
                      description={t('PRODUCTS')}
                    />

                    <FinanceResumeCard
                      last
                      label={t('PROFIT')}
                      color="success"
                      value={isBlocked ? 345.9 : data?.totalService?.[0]?.total || 0}
                      description={t('SERVICES')}
                    />
                  </ScrollMenu>
                </Box>
              )}
            </Box>

            <Box w="100%" mb={theme.pxToRem(44)}>
              <Flex w="100%" alignItems="center" justifyContent="space-between" mt={theme.pxToRem(24)}>
                <Text kind="extraBold" fontSize={pxToRem(18)}>
                  {t('RECENT_OBSERVATIONS')}
                </Text>
                <Text
                  kind="medium"
                  color={isBlocked ? 'textGrey' : 'primary'}
                  cursor="pointer"
                  onClick={() => !isBlocked && setObservationsModalVisible(true)}
                >
                  {t('SEE_ALL')}
                </Text>
              </Flex>

              <Box height={isBlocked && 150} position="relative">
                {isBlocked && <BlockedFeature />}

                {!isBlocked && !data?.observations?.length && (
                  <Flex flexDir="column" alignItems="center" justifyItems="center" w="100%" py={theme.pxToRem(32)}>
                    <Icon mb={theme.pxToRem(8)} color="textGrey" name="Edit" />
                    <Text fontSize={14} kind="medium" color="textGrey">
                      {t('NO_OBSERVATIONS')}
                    </Text>
                  </Flex>
                )}

                {(isBlocked || !!data?.observations?.length) &&
                  (isBlocked ? mockObservation : data)?.observations?.map((item) => (
                    <ObservationCard key={item.id} mb={theme.pxToRem(12)} item={item} />
                  ))}
              </Box>
            </Box>

            <Box w="100%">
              <Text mt={theme.pxToRem(24)} mb={theme.pxToRem(16)} kind="extraBold" size={pxToRem(18)}>
                {t('NEXT_APPOINTMENT')}
              </Text>

              {data?.appointments?.length === 0 && (
                <Flex flexDir="column" alignItems="center" justifyItems="center" w="100%" py={theme.pxToRem(32)}>
                  <Icon mb={theme.pxToRem(8)} color="textGrey" name="Calendar" />
                  <Text fontSize={14} kind="medium" color="textGrey">
                    {t('NO_APPOINTMENT')}
                  </Text>
                </Flex>
              )}

              {!!data?.next_appointment?.length && data?.next_appointment?.length > 0 ? (
                <AppointmentCard
                  item={data?.next_appointment}
                  onClick={() => onSelectAppointment(data?.next_appointment)}
                />
              ) : (
                <Flex flexDir="column" alignItems="center" justifyItems="center" w="100%" py={theme.pxToRem(32)}>
                  <Icon mb={theme.pxToRem(8)} color="textGrey" name="Calendar" />
                  <Text fontSize={14} kind="medium" color="textGrey">
                    {t('NO_APPOINTMENT')}
                  </Text>
                </Flex>
              )}
            </Box>

            <Box w="100%" mb={theme.pxToRem(24)}>
              <Text mt={theme.pxToRem(24)} mb={theme.pxToRem(16)} kind="extraBold" size={pxToRem(18)}>
                {t('HISTORIC')}
              </Text>

              <Box position="relative">
                {isBlocked && <BlockedFeature />}

                {isBlocked || !!data?.appointments?.length ? (
                  <>
                    {(isBlocked ? mockHistoricClient : data)?.appointments.map((item) => (
                      <AppointmentCard
                        key={item.id}
                        item={item}
                        type="historic"
                        mb={theme.pxToRem(12)}
                        onClick={() => !isBlocked && onSelectAppointment(item)}
                      />
                    ))}
                    {!isLoading && (
                      <Button
                        size="block"
                        disabled={loadingAppointments}
                        isLoading={loadingAppointments}
                        onClick={refetchAppointments}
                      >
                        Carregar mais
                      </Button>
                    )}
                  </>
                ) : (
                  <Flex flexDir="column" alignItems="center" justifyItems="center" w="100%" py={theme.pxToRem(32)}>
                    <Icon mb={theme.pxToRem(8)} color="textGrey" name="Calendar" />
                    <Text fontSize={14} kind="medium" color="textGrey">
                      {t('NO_APPOINTMENT')}
                    </Text>
                  </Flex>
                )}
              </Box>
            </Box>

            <Box w="100%">
              <Text fontSize={18} kind="extraBold" mb={theme.pxToRem(8)}>
                {t('DELETE_CLIENT_MODAL_TITLE')}
              </Text>

              <Text fontSize={12} color="textGrey" mb={theme.pxToRem(24)}>
                {t('DELETE_CLIENT_WARNING')}
              </Text>

              {clients === 'edit' && (
                <Button
                  kind="outline"
                  size="block"
                  icon="Trash"
                  iconColor="baseRed"
                  onClick={() => setDeleteCustomerModal(true)}
                >
                  {t('DELETE_CLIENT_BUTTON')}
                </Button>
              )}
            </Box>

            {detailsModalVisible && (
              <AppointmentDetailsModal
                isModalOpen={detailsModalVisible}
                onClose={onCloseModals}
                setAppointmentId={setApp}
                app={app}
              />
            )}

            {observationsModalVisible && (
              <ClientObservationsModal
                isModalOpen={observationsModalVisible}
                onClose={() => {
                  setObservationsModalVisible(false)
                  refetch()
                }}
              />
            )}

            {isEditModalOpen && (
              <ClientsModal
                isEdit
                isModalOpen={isEditModalOpen}
                onClose={handleCloseEditModal}
                clientData={data?.client}
              />
            )}

            {clientReleaseModal && (
              <ClientReleaseModal
                isModalOpen={clientReleaseModal}
                onClose={() => setClientReleaseModal(false)}
                data={data?.barbershopClient}
              />
            )}

            <ModalAlert
              isOpen={deleteCustomerModal}
              modalTitle={t('DELETE_CLIENT_MODAL_TITLE')}
              primaryButtonLabel={t('DELETE_CLIENT_CONFIRM')}
              loading={mutate.isLoading}
              onConfirm={() => mutate.mutate()}
              onClose={() => setDeleteCustomerModal(false)}
            >
              {t('DELETE_CLIENT_MODAL') + data?.client?.name}?
            </ModalAlert>
          </>
        )}
      </ModalMenu>
    </>
  )
}
