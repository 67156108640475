import { Box, Flex } from '@chakra-ui/react'
import { Avatar, Text } from 'components/atoms'
import { formatPrice } from 'utils'

export const productsColumns = [
    {
      key: 'barber',
      label: 'COLLABORATOR',
      render: (item) => {
        return (
          <Flex flexDir="row" align="center">
            <Avatar size="medium" src={item?.collaborator?.profile_image_url} mr="16px" />
            <Box>
              <Text>{item?.collaborator?.name}</Text>
            </Box>
          </Flex>
        )
      },
    },
    {
      key: 'total_value',
      label: 'TOTAL_SALES_TABLE',
      render: (item) => <Text>{formatPrice(item?.products_value)}</Text>,
    },
    {
      key: 'min_goal',
      label: 'META MINIMA PREVISTA',
      render: (item) => (
          <Text>
          {!item?.barber_goals
            ? 0
            : formatPrice(item?.barber_goals?.filter(i => i.type === 'product')?.sort?.((a, b) => a.value - b.value)?.[0]?.value)}
        </Text>
        )
    },
    {
      key: 'max_goal',
      label: 'META MAXIMA PREVISTA',
      render: (item) =>  (
<Text>
          {!item?.barber_goals
            ? 0
            : formatPrice(item?.barber_goals?.filter(i => i.type === 'product')?.sort?.((a, b) => a.value - b.value)?.[item?.barber_goals?.filter(bg => bg?.type === 'product')?.length - 1]?.value)}
        </Text>
        )
    },
  ]