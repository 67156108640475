import { useBarbershopStore } from 'services/stores'
import { saveLoginData } from './use-auth'
import { useHistory } from 'react-router-dom'

export const useLogoutPaymentIssues = () => {
  const { userData, setData } = useBarbershopStore()
  const history = useHistory()
  const onLogout = async () => {
    const positive_access = ['LEAD', 'TRIAL', 'ONBOARDING', 'SUBSCRIBED', 'SUBSCRIBED_PARTNER']

    const ids = [
      1, 9030, 9031, 9334, 9512, 10387, 10489, 10562, 10593, 10811, 11070, 11071, 11682, 11764, 11839, 12139, 12229,
      11271, 11379, 11406, 11408, 11559, 11560, 11565, 11968, 12034, 12125, 12205, 2116, 2877, 6634, 7649, 7652, 8023,
      7654, 7655, 7657, 7666, 7758, 7668, 7683, 7703, 7756, 7704, 7705, 7757, 7750, 7752, 7753, 7754, 7779, 7660, 7895,
      8132, 8133, 8134, 8468, 8469, 8482, 8505, 8553, 8671, 8740, 8742, 8860, 8861, 8825, 9004, 9005, 9006, 8930, 8938,
      8974, 9109, 9594, 10313, 10351, 10369, 10401, 12652, 10512, 10589, 10631, 10659, 10679, 11417,
    ]
    if (ids.includes(userData?.id) && userData?.version !== 'v5') {
      saveLoginData({})
      setData({}, {})
      localStorage.clear()
      history.replace('/')
      return
    }

    if (!positive_access.includes(userData?.account_status)) {
      saveLoginData({})
      setData({}, {})
      localStorage.clear()
      history.replace('/')
      return
    }
  }

  return { onLogout }
}
