import React from 'react'
import {
  Text,
  Flex,
  Icon,
  Logo,
} from 'components'
import { theme } from 'styles'
import { useHistory } from 'react-router-dom'
import { Spinner } from '@chakra-ui/react'

export const Layout = ({ title, withoutHeader, children }) => {
  const history = useHistory()
  return (
    <Flex align="flex-start" minH="100vh" flexDir="column" w="100vw" h="100%" p={theme.pxToRem(40)}>
      {!withoutHeader && <Flex flexDir="row" align="center" mb={theme.pxToRem(32)}>
        <Icon
          name={'ArrowLeft'}
          size={32}
          color="white"
          onClick={() => history.push('/internal-adm/home')}
          cursor="pointer"
        />
        <Text
          align="center"
          kind="bold"
          fontSize={theme.pxToRem(32)}
          ml={theme.pxToRem(24)}
        >
          {title}
        </Text>
      </Flex>}
      {children}
    </Flex>
  )
}

export const ResultCard = ({ value, description, label }) => {
  return (
    <Flex
      bg="background"
      align="flex-start"
      flexDir="column"
      p={theme.pxToRem(24)}
      borderRadius={theme.pxToRem(10)}
      mt={theme.pxToRem(24)}
      w={theme.pxToRem(437)}
    >
      <Text
        kind="extraBold"
        fontSize={theme.pxToRem(20)}
        mb={theme.pxToRem(8)}
      >
        {value}
      </Text>
      <Text fontSize={theme.pxToRem(12)} color="textGrey">
        {label}
      </Text>
      <Text fontSize={theme.pxToRem(12)} color="textLight">
        {description}
      </Text>
    </Flex>
  )
}

export const Loader = () => {
  return (
    <Flex
      w="100vw"
      h="100vh"
      bg="background"
      align="center"
      justify="center"
      flexDir="column"
      position="absolute"
      bottom={0}
      left={0}
      opacity={0.5}
    >
      <Logo size="medium" />
      <Spinner mt={theme.pxToRem(48)} color="textLight" />
    </Flex>
  )
}
