import React, { useState, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useBarbershopStore } from 'services/stores/barbershop'
import { getCurrentStatus } from 'utils/status'
import { useBreakpoint } from 'services/hooks'
import { MenuDesktop } from './MenuDesktop'
import { MobileMenu } from './MenuMobile'
import { internalMenuOptions, menuOptions } from './settings'
import { useUserType } from 'services/hooks/use-user-type'
import { LogoutModal } from '..'

export const Menu = ({ isInternalAdm, isOpen, onToggle, isBannerOpen }) => {
  const [openStatusModal, setOpenStatusModal] = useState(false)
  const { userData } = useBarbershopStore()
  const { isDesktop } = useBreakpoint()
  const [logoutModalOpen, setLogoutModalOpen] = useState(false)
  const type = useUserType()

  const history = useHistory()
  const location = useLocation()

  const handleUserStatus = useMemo(() => {
    return isInternalAdm ? null : getCurrentStatus(userData)
  }, [userData])

  const onClickToNavigate = (path) => {
    if (!path) {
      return
    }
    if (path === '/perfil/sair') {
      setLogoutModalOpen(true)
      return
    }
    history.push(path)
  }

  if (!isDesktop) {
    return (
      <>
        <MobileMenu
          userData={userData}
          openStatusModal={openStatusModal}
          setOpenStatusModal={setOpenStatusModal}
          handleUserStatus={handleUserStatus}
          onClickToNavigate={onClickToNavigate}
          path={location.pathname}
          isInternalAdm={isInternalAdm}
          options={
            isInternalAdm
              ? internalMenuOptions
              : menuOptions(userData?.permissions, type, userData?.barbershop?.user_id || userData?.user_id, userData?.barbershop || userData)
          } 
          isOpen={isOpen}
          isBannerOpen={isBannerOpen}
          onToggle={onToggle}
        />
        {logoutModalOpen && (
          <LogoutModal
            isOpen={logoutModalOpen}
            onClose={() => setLogoutModalOpen(false)}
          />
        )}
      </>
    )
  }
  return ( 
    <>
      <MenuDesktop
        userData={userData}
        setOpenStatusModal={setOpenStatusModal}
        handleUserStatus={handleUserStatus}
        onClickToNavigate={onClickToNavigate}
        openStatusModal={openStatusModal}
        path={location.pathname}
        isInternalAdm={isInternalAdm}
        options={ 
          isInternalAdm
            ? internalMenuOptions
            : menuOptions(userData?.permissions, type, userData?.barbershop?.user_id || userData?.user_id, userData?.barbershop || userData)
        }
        isBannerOpen={isBannerOpen}
      />
      {logoutModalOpen && (
        <LogoutModal
          isOpen={logoutModalOpen}
          onClose={() => setLogoutModalOpen(false)}
        />
      )}
    </>
  )
}
