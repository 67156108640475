import React, { useEffect, useState } from 'react'
import { useToast, useTheme } from '@chakra-ui/react'
import { Layout, Table, Box, Text } from 'components'
import { useBreakpoint } from 'services/hooks'
import { useBarbershopStore } from 'services/stores/barbershop'
import { usePermissionCollaborator } from 'services/hooks'
import { useUserType } from 'services/hooks/use-user-type'
import { useQuery, useMutation } from 'react-query'
import { formatBlockedDate } from 'utils/date'
import { t } from 'services/translation'
import { ModalAlert } from 'components/molecules'
import { BlockedTimeModal } from 'components/organisms'
import { api } from 'services/api/config'
import { BLOCK_TIME_DELETE, BLOCK_TIMES } from 'services/api/endpoints'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'

const columns = [
  {
    key: 'blocked_date',
    label: 'BLOCKED_DATE',
    render: (item) => (
      <>
        <Text>{t(formatBlockedDate(item))}</Text>
      </>
    ),
  },
  {
    key: 'blocked_date',
    label: 'DESCRIPTION_',
    render: (item) => (
      <>
        <Text>{item?.title}</Text>
      </>
    ),
  },
  {
    key: 'hour',
    label: 'PERIOD',
    render: (item) => (
      <Text>
        {item?.start_hour.substring(0, 5)} - {item?.finish_hour.substring(0, 5)}
      </Text>
    ),
  },
  {
    key: 'barber',
    label: 'BARBER',
    render: (item) => (
      <Text>
        {t('BARBER')}: {item?.barber?.name || t('EVERYBODY')}
      </Text>
    ),
  },
]

export const BlockedTimesScreen = ({ ...props }) => {
  const [isModalAlertOpen, setIsModalAlertOpen] = useState(false)
  const [isModalAddOpen, setIsModalAddOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const permission = usePermissionCollaborator('blocked_hours')

  const { userData } = useBarbershopStore()
  const theme = useTheme()
  const toast = useToast()

  async function getBlockedTimes() {
    const res = await api
      .get(BLOCK_TIMES('', userData?.barbershop?.id || userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data, isLoading, refetch } = useQuery(
    'get-blocked-times',
    getBlockedTimes
  )

  async function deleteBlockedTimes(params) {
    const res = await api
      .delete(BLOCK_TIME_DELETE(selectedItem?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const handleDelete = () => {
    setIsModalAlertOpen(false)
    setSelectedItem()
    refetch()
  }

  const {onLogout} = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [userData])

  const mutateDelete = useMutation('change-blocked-time', deleteBlockedTimes, {
    onSuccess: (data) => {
      handleDelete()
      toast({
        title: t('UNBLOCKED_TIME_TOAST'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
    },
    onError: (error) => {
      toast({
        title: t('ERROR_DELETE_DATA'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const onClose = () => {
    setIsModalAddOpen(false)
    refetch()
  }

  return (
    <Layout
      {...props}
      headerTitle={t('BLOCKED_HOURS')}
      modalFilter
      modalFilterWidth="12%"
      headerActionText={permission === 'edit' ? t('ADD') : null}
      headerActionClick={() => setIsModalAddOpen(true)}
    >
      <Box flex={1}>
        <Table
          deleteButtom
          isSelectableRow
          data={data?.filter((i) => !i?.past) || []}
          loading={isLoading}
          columns={columns}
          onSelect={(item) => {
            setIsModalAlertOpen(true)
            setSelectedItem(item)
          }}
        />

        <BlockedTimeModal isModalOpen={isModalAddOpen} onClose={onClose} />

        <ModalAlert
          isOpen={isModalAlertOpen}
          onClose={() => {
            setIsModalAlertOpen(false)
            setSelectedItem()
          }}
          loading={mutateDelete.isLoading}
          onConfirm={() => mutateDelete.mutate()}
          primaryButtonLabel={t('YES')}
          modalTitle={t('DELETE_BLOCKED_HOUR')}
        >
          <Text fontWeight="bold" m={`${theme.pxToRem(16)} 0`}>
            {t('BLOCK_TIME_CONFIRMATION')}
          </Text>
        </ModalAlert>
      </Box>
    </Layout>
  )
}

BlockedTimesScreen.path = '/horarios-bloqueados'
BlockedTimesScreen.title = 'Horarios Bloqueados'
BlockedTimesScreen.secured = true
