import React, { useState } from 'react'
import { useTheme } from '@chakra-ui/react'
import { Flex, Table, TextInput, Button, Text } from 'components'
import { useAppointmentStore, useBarbershopStore } from 'services/stores'
import { colors } from 'styles'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useSignature } from 'services/hooks/use-signature'
import { formatPhone } from 'utils/phone-formater'

export const AppointmentSignatureSelectClient = ({ goBack }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { setClient, setClientsList, clientsList, loading } = useAppointmentStore()
  const [query, setQuery] = useState('')
  const { getAllSignatures } = useSignature()

  const { userData } = useBarbershopStore()

  const columns = [
    {
      key: 'name',
      label: 'NAME_TABLE',
      render: (item) => (
        <Text w="100%" kind="bold" color="textLight">
          {item.client.name}
        </Text>
      ),
    },
    {
      key: 'phone',
      label: 'PHONE_NUMBER',
      width: 130,
      render: (item) => (
        <Text w="100%" kind="medium" color="textGrey">
          {formatPhone(item.client.user.phone, userData)}
        </Text>
      ),
    },
  ]
  const { refetch } = useQuery(['get-clients-signature'], () => getAllSignatures({ barbershop_id: userData?.id }), {
    onSuccess: (data) => {
      setClientsList({ data: data?.clientsSignature, filtered: data?.clientsSignature })
    },
  })

  const onSearch = (text) => {
    setQuery(text)
    if (text.length === 0) {
      setClientsList({ ...clientsList, filtered: clientsList?.data })
    } else {
      const search_result = clientsList?.data?.filter(
        (item) =>
          String(item?.client?.name.toLowerCase()).includes(query.toLowerCase()) ||
          String(item?.client?.user?.email?.toLowerCase()).includes(query.toLowerCase())
      )
      setClientsList({ ...clientsList, filtered: search_result })
    }
  }

  const handleSelectCustomer = (item) => {
    setClient({
      ...item,
      created: false,
    })
    goBack()
  }

  return (
    <Flex flexDirection="column" flex="1">
      <Flex w="100%" justify="space-between" mb={4}>
        <TextInput
          noBorder
          type="text"
          color={colors.white}
          placeholder={t('SEARCH_CLIENT')}
          backgroundColor="backgroundLayout"
          borderWidth={1}
          borderColor="modalBorder"
          borderRadius={12}
          paddingLeft={theme.pxToRem(16)}
          mb={theme.pxToRem(12)}
          onChange={(event) => {
            onSearch(event?.target?.value)
          }}
          w="95%"
          mr="3%"
        />
        <Button
          onClick={refetch}
          icon="Search"
          iconColor="black"
          iconSize={20}
          w={theme.pxToRem(52)}
          h={theme.pxToRem(52)}
        />
      </Flex>

      <Table
        withoutHeader
        loading={loading}
        columns={columns}
        data={clientsList?.filtered}
        isSelectableRow
        onSelect={handleSelectCustomer}
      />
    </Flex>
  )
}
