import React, { useState, useMemo, useEffect } from 'react'
import { format, getISODay } from 'date-fns'

import {
  DraggableTimeline,
  Layout,
  SelectDateModal,
  SelectDateButton,
  AppointmentsModal,
  AppointmentDetailsModal,
  RecurrentAppointmentsModal,
  Button,
  Text,
  Box,
  SelectFilter,
  BlockedTimeModal,
  AgendaContextMenu,
  Icon,
  Avatar,
  ModalAlert,
} from 'components'
import { handleBgColor } from 'components/organisms/AppointmentAgenda/settings'
import { useToast, Alert, AlertIcon, AlertTitle, AlertDescription, Flex, useTheme, useMenu } from '@chakra-ui/react'
import { useAppointmentDetailStore, useBarbershopStore, useAppointmentStore } from 'services/stores'
import { saveLoginData, useBreakpoint } from 'services/hooks'
import { useTranslation } from 'react-i18next'

import { useQuery, useMutation } from 'react-query'
import { usePermissionCollaborator, useBarbershop, useAppointment } from 'services/hooks'
import { useHistory } from 'react-router-dom'
import { formatPhone } from 'utils/phone-formater'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { AppointmentsSignatureModal } from 'components-v2/organisms/CreateSignatureAppointment'

export const AppointmentsScreen = (props) => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpoint()
  const { getBarbers } = useBarbershop()
  const { getAppointments, updateAppointmentHourAndBarber } = useAppointment()
  const history = useHistory()
  const toast = useToast()
  const theme = useTheme()
  const { userData, setData } = useBarbershopStore()
  const { setTime, setDate, setBarber, setPackageSell, setScheduleType } = useAppointmentStore()
  const [dateModalOpen, setDateModalOpen] = useState(false)
  const [clickEmpty, setClickEmpty] = useState(false)
  const [show, setShow] = useState(false)
  const [appointmentModalOpen, setAppointmentModalOpen] = useState(false)
  const [appointmentSignatureModalOpen, setAppointmentSignatureModalOpen] = useState(false)

  const [recurrentAppointmentModalOpen, setRecurrentAppointmentModalOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [detailsModalVisible, setDetailsModalVisible] = useState(false)
  const [blockedModalVisible, setBlockedModalVisible] = useState(false)
  const [showBlockedTimes, setShowBlockedTimes] = useState(true)
  const [points, setPoints] = useState({ X: 0, Y: 0, time: null, barber: null })
  const [barberFilter, setBarberFilter] = useState({
    name: 'Todos os visíveis',
  })
  const [isAlertModalOpen, setAlertModalOpen] = useState(false)
  const [contextMenuCoord, setContextMenuCoord] = useState(null)
  const [isLoading, setLoading] = useState(false)

  const [appointmentsTimeline, setAppointmentsTimeline] = useState([])
  const [app, setApp] = useState(null)

  const appointment = usePermissionCollaborator('appointment')
  const blockedTimes = usePermissionCollaborator('blocked_hours')

  const onCloseModals = () => {
    setAppointmentModalOpen(false)
    setBlockedModalVisible(false)
    setDetailsModalVisible(false)
    setAppointmentSignatureModalOpen(false)
    setApp(null)
    setDate(null)
    setTime(null)
    setRecurrentAppointmentModalOpen(false)
    setPoints({ X: 0, Y: 0, time: null, barber: null })
    refetch()
  }

  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [userData])

  const {
    data: appointments,
    refetch,
    isLoading: isAppointmentsLoading,
  } = useQuery(
    ['get-appointments', selectedDate, showBlockedTimes],
    () =>
      getAppointments({
        date: selectedDate,
        showBlockedTimes,
        barbershop_id: userData?.barbershop?.id || userData?.id,
        onSuccess: () => setLoading(false),
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  )
  const { data: barbers, isLoading: isBarbersLoading } = useQuery('get-team', () =>
    getBarbers({
      barbershop_id: userData.id,
    })
  )

  const appointmentDisabled = useMemo(() => {
    return userData.status !== 'published' && userData.status !== 'private'
  }, [userData])

  const onClickEmpty = (time, barber) => {
    if (appointment === 'edit') {
      setDate(selectedDate)
      setTime(time)
      setBarber(barber)
      setClickEmpty(true)
      setAppointmentModalOpen(true)
    } else {
      toast({
        title: t('PERMISSION_DENIED'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const Header = () => {
    return (
      <Flex pb={theme.pxToRem(12)} flexDir="column">
        {/* // Title and subtitle */}
        <Flex pb={theme.pxToRem(12)} w="100%" justify="space-between">
          <Flex align="center" mb={theme.pxToRem(10)}>
            <Text kind="extraBold" fontSize={theme.pxToRem(24)}>
              Agendamentos
            </Text>
          </Flex>
          <Flex>
            {blockedTimes === 'edit' && (
              <Button
                icon="DeleteAlert"
                size="fit-small"
                iconColor={'white'}
                kind={'danger'}
                ml={theme.pxToRem(12)}
                onClick={() => setBlockedModalVisible(true)}
              >
                Bloquear horário
              </Button>
            )}
            <Button
              size="small"
              kind={'primary'}
              ml={theme.pxToRem(12)}
              w={theme.pxToRem(210)}
              onClick={() => setAppointmentSignatureModalOpen(true)}
            >
              Agendamento avulso clube
            </Button>
            <Button
              icon="Shampoo"
              size="fit-small"
              iconColor={'background'}
              kind={'primary'}
              ml={theme.pxToRem(12)}
              w={theme.pxToRem(210)}
              onClick={() => setAppointmentModalOpen('singleSale')}
            >
              Nova venda de produtos
            </Button>
            <Button
              icon="Plus"
              size="fit-small"
              iconColor={'background'}
              kind={'primary'}
              ml={theme.pxToRem(12)}
              onClick={() => setRecurrentAppointmentModalOpen(true)}
            >
              Novo agendamento recorrente
            </Button>
            <Button
              icon="Calendar"
              size="fit-small"
              iconColor={'background'}
              kind={'primary'}
              ml={theme.pxToRem(12)}
              onClick={() => {
                setScheduleType('normal')
                setPackageSell(false)
                setAppointmentModalOpen(true)
              }}
            >
              Novo agendamento
            </Button>
          </Flex>
        </Flex>
        {/* Filters */}
        <Flex>
          <SelectDateButton
            date={selectedDate}
            setDate={setSelectedDate}
            onClickCenter={() => setDateModalOpen(true)}
          />
          <SelectFilter
            withoutPlaceholder
            onChange={(value) => setShowBlockedTimes(value === 'Sim' ? true : false)}
            value={showBlockedTimes === true ? 'Sim' : 'Não'}
            options={[{ name: 'Sim' }, { name: 'Não' }]}
            label="HIDE_BLOCKED_TIMES"
            ml={theme.pxToRem(12)}
          />
          <SelectFilter
            onChange={(value) => setBarberFilter(barbers?.find((i) => i?.name?.trim() === value?.trim()))}
            value={barberFilter?.name}
            placeholder="Todos"
            options={barbers ? [{ name: 'Todos os visíveis' }, ...barbers] : []}
            label="BARBER"
            maxW={barberFilter?.name === 'Todos os visíveis' && 250}
          />
          <Button
            leftIcon="Atualizar"
            size="medium"
            iconColor={'background'}
            kind={'secondary'}
            h="40px"
            onClick={() => {
              setLoading(true)
              refetch()
            }}
            px="16"
            w="46"
          />
        </Flex>
      </Flex>
    )
  }
  useEffect(() => {
    const handleClick = () => setShow(false)
    window.addEventListener('click', handleClick)
  }, [])

  useMemo(() => {
    const blocked_times = appointments?.filter((a) => a.type === 'blocked_times') ?? []
    const appointments_without_blocked = appointments?.filter((a) => a.type !== 'blocked_times') ?? []
    setAppointmentsTimeline(
      barbers
        ?.filter((barber) =>
          !barberFilter
            ? true
            : barberFilter?.name === 'Todos os visíveis'
            ? barber?.visible_for_clients
            : barber?.id === barberFilter.id
        )
        ?.sort((a, b) => a?.createdAt - b?.createdAt)
        ?.map((barber) => ({
          header: {
            title: barber.name,
            image: barber.profile_image_url,
            metadata: barber,
          },
          items: [
            ...(showBlockedTimes
              ? blocked_times
                  .filter(
                    (blocked) =>
                      (blocked?.barber_id === barber?.id || blocked?.all_barbers) &&
                      !blocked?.exceptions?.find(
                        (i) =>
                          i?.date &&
                          selectedDate &&
                          i?.date?.substring(0, 10) === format(selectedDate, 'yyyy-MM-dd') &&
                          (blocked?.all_barbers ? i?.barber_id === barber?.id : true)
                      )
                  )
                  .map((apt) => ({
                    id: `${barber.id}/${apt.id}`,
                    startTimeStr: apt.start_hour.slice(0, 5),
                    endTimeStr: apt.finish_hour.slice(0, 5),
                    isDraggable: false,
                    isResizable: false,
                    metadata: apt,
                  })) ?? []
              : []),
            ...appointments_without_blocked
              .filter(
                (aptToOne) =>
                  aptToOne.barber_id === barber.id &&
                  !aptToOne?.exceptions?.find(
                    (i) => i?.date && selectedDate && i?.date?.substring(0, 10) === format(selectedDate, 'yyyy-MM-dd')
                  )
              )
              .map((aptToOne) => ({
                id: `${barber.id}/${aptToOne.id}`,
                startTimeStr: aptToOne.start_hour.slice(0, 5),
                endTimeStr: aptToOne.finish_hour.slice(0, 5),
                metadata: aptToOne,
                isDraggable: aptToOne?.repeat_every_week_day ? false : true,
                isResizable: aptToOne?.repeat_every_week_day ? false : true,
              })),
          ],
        })) ?? []
    )
  }, [appointments, barberFilter, barbers, selectedDate, showBlockedTimes])

  const mutation = useMutation('update-appointment-hour-and-barber', (params) => updateAppointmentHourAndBarber(params))

  const timesAppointment = useMemo(() => {
    const appArr = appointments?.filter(
      (itm) => !itm?.exceptions?.find((dt) => dt?.date === format(selectedDate, 'yyyy-MM-dd'))
    )
    return {
      start_hour: appArr?.sort((a, b) => a.start_hour - b.start_hour)[0]?.start_hour,
      close_hour: appArr?.sort((a, b) => a.finish_hour - b.finish_hour)[appArr?.length - 1]?.finish_hour,
    }
  }, [appointments])

  const minTime = useMemo(() => {
    const min =
      (userData?.personalized_opening_hours &&
        userData?.personalized_opening_hours?.find((i) => i?.date === format(selectedDate, 'yyyy-MM-dd'))
          ?.start_hour) ||
      userData?.opening_hours[getISODay(selectedDate) - 1].start_hour

    if (timesAppointment?.start_hour < min) {
      return timesAppointment?.start_hour
    }

    return min
  }, [userData, selectedDate, timesAppointment])
  const maxTime = useMemo(() => {
    const max =
      (userData?.personalized_opening_hours &&
        userData?.personalized_opening_hours?.find((i) => i?.date === format(selectedDate, 'yyyy-MM-dd'))
          ?.close_hour) ||
      userData?.opening_hours[getISODay(selectedDate) - 1].close_hour

    if (timesAppointment?.close_hour > max) {
      return timesAppointment?.close_hour
    }

    return max
  }, [userData, selectedDate, timesAppointment])

  return (
    <Layout {...props} withoutHeader>
      <Flex h="97vh" flexDir="column" w="full" overflowY="hidden">
        <Header />
        <Flex w="100%" h="fit-content" flexDir="column" backgroundColor="menuBackground">
          {appointmentDisabled && (
            <Alert
              h={theme.pxToRem(isMobile ? 94 : 56)}
              borderRadius={theme.pxToRem(12)}
              mb={theme.pxToRem(24)}
              status="error"
            >
              <AlertIcon />
              <AlertTitle mr={2}>{t('BARBERSHOP_CANNOT_SCHEDULE')}</AlertTitle>
              <AlertDescription>{t('VERIFY_STATUS_TRY_AGAIN')}</AlertDescription>
            </Alert>
          )}
          <Box
            // overflowX="scroll"
            sx={{
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              '&::-webkit-scrollbar-thumb': {
                display: 'none',
              },
            }}
          >
            <DraggableTimeline
              minTime={userData?.opening_hours[getISODay(selectedDate) - 1].start_hour}
              maxTime={userData?.opening_hours[getISODay(selectedDate) - 1].close_hour}
              isClosed={userData?.opening_hours[getISODay(selectedDate) - 1].is_closed}
              data={appointmentsTimeline}
              isLoading={isAppointmentsLoading || isBarbersLoading || isLoading}
              headerRender={(headerData) => (
                <Flex align="center" p={2}>
                  <Avatar src={headerData.image} type="barber" size="medium" />
                  <Text kind="semiBold" fontSize={14} ml={2}>
                    {headerData.title}
                  </Text>
                </Flex>
              )}
              minTime={minTime}
              maxTime={maxTime}
              isClosed={
                userData?.personalized_opening_hours &&
                userData?.personalized_opening_hours?.find((i) => i?.date === format(selectedDate, 'yyyy-MM-dd'))
                  ? userData?.personalized_opening_hours?.find((i) => i?.date === format(selectedDate, 'yyyy-MM-dd'))
                      ?.is_closed
                  : userData?.opening_hours[getISODay(selectedDate) - 1].is_closed
              }
              agenda_interval={userData?.agenda_time_interval}
              onDragStop={(i) => setAlertModalOpen(i)}
              onItemChange={(i) => setAlertModalOpen(i)}
              onContextMenu={(ev) => {
                ev.preventDefault()
                setContextMenuCoord({ x: ev.pageX, y: ev.pageY })
              }}
              onMouseDown={() => {
                if (!contextMenuCoord) return
                setTimeout(() => setContextMenuCoord(null), 100)
              }}
              onGridClick={(ev) => {
                setScheduleType('normal')
                setPackageSell(false)
                setDate(selectedDate)
                setTime(ev.startTimeStr)
                setBarber(ev.header.metadata)
                if (contextMenuCoord) return
                setAppointmentModalOpen(true)
              }} 
              h="calc(100vh - 160px)"
              itemRender={({ header, item, canItemClick }) => {
                const client = item.metadata?.client
                const credit =
                  client?.barbershop_client?.[0]?.client_balance && client?.barbershop_client?.[0]?.client_balance !== 0
                    ? client?.barbershop_client?.[0]?.client_balance
                    : 0
                const subs = client?.subscriptions?.find(
                  (i) => i?.type === 'signature' && item?.metadata?.type === 'signature'
                )
                return (
                  <Box
                    bg={item.isDraggable ? 'backgroundComponent' : 'gray.900'}
                    h="full"
                    px={2}
                    py={2}
                    overflow="hidden"
                    borderRadius={6}
                    borderWidth={1}
                    borderColor="gray.500"
                    borderLeftWidth={8}
                    borderLeftColor={handleBgColor(item.metadata)}
                    mt="-0.5px"
                    onTouchStart={() => {
                      if (!canItemClick) return
                      if (item.metadata?.type === 'blocked_times') {
                        setBlockedModalVisible({
                          ...item?.metadata,
                          barber: header?.metadata,
                        })
                        return
                      }
                      setApp(item.metadata)
                      setDetailsModalVisible(true)
                    }}
                    onClick={() => {
                      if (!canItemClick) return
                      if (item.metadata?.type === 'blocked_times') {
                        setBlockedModalVisible({
                          ...item?.metadata,
                          barber: header?.metadata,
                        })
                        return
                      }
                      setApp(item.metadata)
                      setDetailsModalVisible(true)
                    }}
                  >
                    <Flex>
                      <Text kind="semiBold" fontSize={14} noOfLines={1}>
                        {item.metadata?.type === 'blocked_times'
                          ? item.metadata?.title || 'Horário Bloqueado'
                          : `${item.startTimeStr} - ${client?.name.substring(0, 14)}${
                              client?.name?.length > 14 ? '...' : ''
                            }`}
                      </Text>
                      {credit !== 0 && (
                        <Icon name="Dollar" color={credit > 0 ? 'success' : 'error'} size={16} ml="4px" />
                      )}
                      {subs && (
                        <Icon name="Star" color={subs?.status === 'ACTIVE' ? 'success' : 'error'} size={16} ml="4px" />
                      )}
                    </Flex>
                    <Text noOfLines={2} color="textGreyMedium" kind="medium" fontSize={12}>
                      {item?.metadata?.product?.[0]?.name || item?.metadata?.services?.[0]?.name || ''}
                      {(item?.metadata?.product?.length > 1 ||
                        item?.metadata?.services?.length > 1) && (
                          <Text mb={4} color="textGreyMedium" kind="medium" fontSize={12}>
                            + {item?.metadata?.product?.length - 1 || item?.metadata?.services?.length - 1} serviço(s)
                          </Text>
                        )}
                    </Text>
                    <Text color="textGreyMedium" kind="medium" fontSize={12}>
                      {item.startTimeStr} - {item.endTimeStr}
                    </Text>
                    {client?.client_observations?.length > 0 && (
                      <Text bg="black" color="white" kind="medium" fontSize={12} pl={2} borderRadius={4}>
                        {client?.client_observations?.[0]?.observation_text}
                      </Text>
                    )}
                    {item?.metadata?.client?.user?.phone && (
                      <Text color="textGreyMedium" kind="medium" fontSize={12}>
                        {formatPhone(item?.metadata?.client?.user?.phone, userData)}
                      </Text>
                    )}
                    {item?.metadata?.type !== 'blocked_times' && (
                      <Text color="textGreyMedium" kind="medium" fontSize={12}>
                        Nº da comanda: {item?.metadata?.id}
                      </Text>
                    )}
                    {item.metadata?.repeat_every_day && (
                      <Text color="textGreyMedium" kind="medium" fontSize={12}>
                        Bloqueio Recorrente
                      </Text>
                    )}
                  </Box>
                )
              }}
            />
          </Box>
        </Flex>
      </Flex>

      {contextMenuCoord && (
        <AgendaContextMenu
          position="fixed"
          X={contextMenuCoord?.x}
          Y={contextMenuCoord?.y}
          onClickAppointment={() => {
            setAppointmentModalOpen(true)
            setContextMenuCoord(null)
          }}
          onClickBlockedTimes={() => {
            setBlockedModalVisible(true)
            setContextMenuCoord(null)
          }}
          onClickProducts={() => {
            setAppointmentModalOpen('singleSale')
            setContextMenuCoord(null)
          }}
        />
      )}

      {show && (
        <AgendaContextMenu
          position="absolute"
          X={points?.X}
          Y={points?.Y}
          onClickProducts={() => setAppointmentModalOpen('singleSale')}
          onClickAppointment={() => onClickEmpty(points?.time, points?.barber)}
          onClickBlockedTimes={() => setBlockedModalVisible(true)}
        />
      )}

      {dateModalOpen && (
        <SelectDateModal
          onClose={() => setDateModalOpen(false)}
          isModalOpen={dateModalOpen}
          selectedDate={selectedDate}
          onSelectDate={(date) => {
            setSelectedDate(date)
            setDateModalOpen(false)
          }}
        />
      )}

      {appointmentModalOpen !== false && (
        <AppointmentsModal
          isModalOpen={appointmentModalOpen !== false}
          clickEmpty={clickEmpty}
          setClickEmpty={setClickEmpty}
          onClose={onCloseModals}
          withoutTabs
          isSingleSale={appointmentModalOpen === 'singleSale'}
        />
      )}

      {appointmentSignatureModalOpen && (
        <AppointmentsSignatureModal
          isModalOpen={appointmentSignatureModalOpen}
          clickEmpty={clickEmpty}
          setClickEmpty={setClickEmpty}
          onClose={onCloseModals}
        />
      )}

      {recurrentAppointmentModalOpen !== false && (
        <RecurrentAppointmentsModal
          isModalOpen={recurrentAppointmentModalOpen !== false}
          clickEmpty={clickEmpty}
          setClickEmpty={setClickEmpty}
          onClose={onCloseModals}
          withoutTabs
        />
      )}

      {detailsModalVisible && (
        <AppointmentDetailsModal
          isModalOpen={detailsModalVisible}
          onClose={onCloseModals}
          selectedDate={selectedDate}
          app={app}
          setAppointmentId={setApp}
        />
      )}

      {blockedModalVisible !== false && (
        <BlockedTimeModal
          isModalOpen={blockedModalVisible !== false}
          onClose={onCloseModals}
          blocked_time={blockedModalVisible}
          selectedDate={selectedDate}
          preset={points?.time && points?.barber ? points : null}
        />
      )}
      {isAlertModalOpen !== false && (
        <ModalAlert
          isOpen={isAlertModalOpen !== false}
          onClose={() => {
            const states = appointmentsTimeline
            setAppointmentsTimeline([])
            setAlertModalOpen(false)
            setTimeout(() => setAppointmentsTimeline(states), 1)
          }}
          loading={mutation.isLoading}
          onConfirm={() => {
            const params = {
              start_hour: isAlertModalOpen?.startTimeStr,
              finish_hour: isAlertModalOpen?.endTimeStr,
            }
            if (isAlertModalOpen?.byDrag) {
              params.barber_id = isAlertModalOpen?.barber?.id
            }
            mutation.mutate({
              appointment_id: isAlertModalOpen?.appointment_id,
              params,
              onSuccess: () => {
                refetch()
                setAlertModalOpen(false)
              },
              onError: () => {
                setAlertModalOpen(false)
              },
            })
          }}
          primaryButtonLabel={t('CONFIRM')}
          primaryButtonColor="primary"
          modalTitle={t('Alterar horário do agendamento')}
        >
          <Text kind="bold">ATENÇÃO!</Text>
          <Text mt={2}>
            Deseja mesmo alterar este horário? Este agendamento passará a ser considerado como ENCAIXE e não nos
            responsabilizaremos em caso de conflitos de horários.
          </Text>
          <Text mt={4} kind="semiBold">
            Nº da comanda: {isAlertModalOpen?.appointment_id}
          </Text>
          <Text>Cliente: {isAlertModalOpen?.metadata?.metadata?.client?.name}</Text>
          <Text kind="semiBold">
            Barbeiro:{' '}
            {isAlertModalOpen?.byDrag
              ? isAlertModalOpen?.barber?.name
              : isAlertModalOpen?.metadata?.metadata?.barber?.name}
          </Text>
          <Text kind="semiBold">
            Novo horário: {isAlertModalOpen?.startTimeStr} - {isAlertModalOpen?.endTimeStr}
          </Text>
        </ModalAlert>
      )}
    </Layout>
  )
}

AppointmentsScreen.path = '/agenda'
AppointmentsScreen.title = 'Agendamentos'
AppointmentsScreen.secured = true
