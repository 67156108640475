import { api } from 'services/api/config'

export const usePlans = () => {
  const getPlans = async (props) => {
    const response = await api({
      url: `v3/barbershop/plans/${props.id}`,
    })
    return response.data
  }

  const getServicesPlans = async (props) => {
    const response = await api({
      url: `v3/barbershop/services-plans/${props.plan_id}`,
    })
    return response.data
  }

  const createPlans = async (props) => {
    const response = await api.post('v3/barbershop/plans', props?.body)
    return response.data
  }

  const updatePlans = async (props) => {
    const response = await api.put('v3/barbershop/plans', props?.body)
    return response.data
  }

  const updatePlanVisible = async (props) => {
    const response = await api.put('v3/barbershop/visible-plan', props?.body)
    return response.data
  }

  const deletePlan = async (props) => {
    const response = await api.delete(`v3/barbershop/plans/${props?.id}`)
    return response.data
  }

  return {
    updatePlanVisible,
    getServicesPlans,
    updatePlans,
    deletePlan,
    getPlans,
    createPlans,
  }
}
