import React, { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import { Modal, ModalOverlay, Flex, ModalBody, ModalHeader, ModalContent, ModalCloseButton } from '@chakra-ui/react'
import { Button, Icon, Loader, Text, TextInput } from 'components/atoms'
import { useBarbershop } from 'services/hooks'
import { useQuery } from 'react-query'
import { useBarbershopStore } from 'services/stores'
import { format, parseISO } from 'date-fns'
import { SelectDateModal } from '..'

export const UpdateServiceModal = ({ isOpen, onClose, isLoading, onClick, barber_id, service_id }) => {
  const theme = useTheme()
const {userData} = useBarbershopStore()
  const {getAppointmentsCount} = useBarbershop()
  const [type, setType] = useState('only_next')
  const [date, setDate] = useState(format(new Date(), 'yyyy-MM-dd'))

  const {data, isLoading: loadingData} = useQuery('get-appointments-count', () => getAppointmentsCount({barbershop_id: userData?.barbershop?.id || userData?.id, barber_id, service_id}))

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      {loadingData && <Loader/>}
      {!loadingData &&<ModalContent maxW={theme.pxToRem(700)} borderColor="primary" borderWidth={1}
        bg='backgroundLayout' flexDir='column' align='center' justify='center' p='32px' borderRadius='12px'
        maxH={theme.pxToRem(1028)}>
        <ModalHeader
          textAlign="center"
          w={'100%'} 
        >
          <Flex
            flexDir="column"
            w="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Text mb='8px' kind='extraBold' fontSize={28} textAlign='center'>
            Deseja alterar o valor deste serviço
para todos os agendamentos futuros?
            </Text>
          </Flex>
          <Text color='white' kind='normal' fontSize={16} textAlign='center'>
          {`Existem ${data?.quantity} agendamentos criados com este serviço
dentro do período do dia ${format(new Date(), 'dd/MM/yyyy')} à ${data?.max_date && format(parseISO(data?.max_date), 'dd/MM/yyyy')}.
`}
          </Text>
          <Text color='white' kind='normal' mt='16px' fontSize={16} textAlign='center'>
Você deseja que o valor desse serviço
seja alterado em todos estes agendamentos?
Ou somente para os novos agendamentos que forem criados
          </Text>
        </ModalHeader>
        <ModalCloseButton mt="8px" color="white" onClick={onClose} />
        <Flex justify='space-between'>
        <Button
                  kind={type === 'change_all' ? 'primary' : 'secondary'}
                  size="medium"
                  marginRight="32px"
                  onClick={() => {
                    setType('change_all')
                  }}
                >
                  Alterar para todos
                </Button>
                <Button
                  kind={type === 'only_next' ? 'primary' : 'secondary'}
                  size="medium"
                  onClick={() => {
                    setType('only_next')
                  }}
                >
                  Somente para os próximos
                </Button>
            
        </Flex> 
        {type === 'change_all' && (
            <>
            <Text color='white' kind='normal' mt='16px' fontSize={16} textAlign='center'>
            A partir de qual data você quer que os agendamentos sejam alteados?
          </Text>
          <TextInput
                mt='18px'
                noBorder
                type="date"
                name="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                padding={theme.pxToRem(10)}
              />
            </>
               
            )}
        {type === 'change_all' ? (
            <Text color='white' kind='normal' mt='16px' fontSize={16} textAlign='center'>
            {`Confirmo meu desejo de modificar o valor para os ${data?.quantity} agendamentos existentes e para todos os futuros agendamentos criados. Assumo total responsabilidade por essa alteração de valores e pela comunicação com os clientes, isentando o BestBarbers de qualquer responsabilidade por eventuais inconveniências decorrentes dessa ação.`}
          </Text>
        ) : (
            <Text color='white' kind='normal' mt='16px' fontSize={16} textAlign='center'>
            Confirmo meu desejo de salvar essa alteração apenas os próximos agendamentos criados. Assumo total responsabilidade por essa alteração de valores e pela comunicação com os clientes, isentando o BestBarbers de qualquer responsabilidade por eventuais inconveniências decorrentes dessa ação.
          </Text>
        )}
<Button
                  kind='primary'
                  size="block"
                  onClick={() => onClick({type, date})}
                  mt='16px'
                  isLoading={isLoading}
                  isDisabled={type === 'change_all' && !date}
                >
                 Salvar alterações
                </Button>
      </ModalContent>}


    </Modal>
  )
}
