import React from 'react'
import { format, add } from 'date-fns'
import { Icon } from '../Icon'
import { Box, useTheme } from '@chakra-ui/react'
import { useBreakpoint } from 'services/hooks'

export const SelectDateButton = (props) => {
  const { onClickCenter, setDate, date, onlyMonth, disableChangeMonth, width, h } = props
  const theme = useTheme()
  const { isDesktop } = useBreakpoint()

  const onClickRight = () => {
    const param = onlyMonth ? { months: 1 } : { days: 1 }
    const newDate = add(date, param)
    setDate(newDate)
  }

  const onClickLeft = () => {
    const param = onlyMonth ? { months: -1 } : { days: -1 }
    const newDate = add(date, param)
    setDate(newDate)
  }

  return (
    <Box
      display="flex"
      flexDir="row"
      alignItems="center"
      justifyContent={disableChangeMonth ? 'center' : 'space-between'}
      px={theme.pxToRem(12)}
      py={theme.pxToRem(6)}
      w={width ? width : isDesktop ? theme.pxToRem(184) : theme.pxToRem(150)}
      bgColor="cardBackground"
      borderWidth={1}
      borderColor="modalBorder"
      borderRadius={theme.pxToRem(4)}
      fontFamily="Montserrat ExtraBold"
      fontSize={14}
      color="textLight"
      h={h}
    >
      {!disableChangeMonth && (
        <Box cursor="pointer" onClick={onClickLeft}>
          <Icon name="ChevronLeft" size={12} color="primary" />
        </Box>
      )}

      <Box cursor="pointer" onClick={onClickCenter}>
        {format(date, onlyMonth ? 'MM/yyyy' : 'dd/MM/yyyy')}
      </Box>

      {!disableChangeMonth && (
        <Box cursor="pointer" onClick={onClickRight}>
          <Icon name="ChevronRight" size={12} color="primary" />
        </Box>
      )}
    </Box>
  )
}
