import { api } from 'services/api/config'

export const useBarbershopAds = () => {
  const getBarbershopAds = async (props) => {
    const response = await api({
      url: `v3/ads/barbershop/${props.barbershop_id}`,
    })
    return response.data
  }

  const createBarbershopAds = async (body) => {
    const response = await api.post(`v3/ads/barbershop/create`, body)
    return response.data
  }

  const updateBarbershopAds = async (props) => {
    const response = await api.put(
      `v3/ads/barbershop/update/${props.ads_id}`,
      props.body
    )
    return response.data
  }

  const deleteBarbershopAds = async (props) => {
    const response = await api.delete(
      `v3/ads/barbershop/delete/${props.ads_id}`
    )
    return response.data
  }

  return {
    getBarbershopAds,
    createBarbershopAds,
    updateBarbershopAds,
    deleteBarbershopAds,
  }
}
