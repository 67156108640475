import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useToast } from '@chakra-ui/react'
import { Box, Button, Flex, FileInput, TextInput, Icon } from 'components/atoms'
import { ModalMenu, Form, SelectCard } from 'components/molecules'
import { Text } from 'components/atoms/Text'
import { theme } from 'styles'
import { pxToRem } from 'styles/metrics'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'
import { useHistory } from 'react-router-dom'
import { dataBarber, initialBarber, validationBarber, select } from './settings'
import { Select } from '@chakra-ui/react'
import { colors } from 'styles'

import { api } from 'services/api/config'
import { useMutation, useQueryClient } from 'react-query'
import { BARBERSHOP_ADD_BARBER, USER_DATA, UPDATE_PROFILE_IMAGE } from 'services/api/endpoints'
import { useLazyFetch } from 'services/hooks'
import { formatPhone } from 'utils/phone-formater'
import { ModalButtonBackground } from 'components-v2/atoms'

export const TeamModal = ({
  isModalOpen,
  onClose,
  isRegister,
  barbersList,
  isBlocked,
  isEdit = false,
  collaboratorData = {},
}) => {
  const toast = useToast()
  const { t } = useTranslation()
  const { userData } = useBarbershopStore()
  const [openPass, setOpenPass] = useState(false)
  const [work, setWork] = useState(isEdit ? false : true)
  const [openNew, setOpenNew] = useState(false)
  const [photo, setPhoto] = useState(collaboratorData?.profile_image_url)
  const [error, setError] = useState({})
  const [agenda_time_interval, setAgendaTimeInterval] = useState(collaboratorData?.agenda_time_interval ?? 10)
  const [individualTime, setIndividualTime] = useState(collaboratorData?.agenda_time_interval ? true : false)
  const [visibleForClients, setVisibleForClients] = useState(collaboratorData?.visible_for_clients || false)
  const [values, getValues] = useState({
    params: isEdit
      ? {
          ...collaboratorData,
          phone: formatPhone(collaboratorData?.user?.phone, userData),
          email: collaboratorData?.user?.email,
        }
      : initialBarber,
    isValid: false,
  })
  const [type, setType] = useState(
    collaboratorData?.type || collaboratorData?.user?.type
      ? collaboratorData?.type || collaboratorData?.user?.type
      : 'barber'
  )

  const history = useHistory()
  const queryClient = useQueryClient()

  const cantAddMore = useMemo(() => {
    return isBlocked && barbersList?.length >= 2
  }, [barbersList])

  const formatPercentage = (percentage) => {
    if (!percentage) return 0

    return typeof percentage === 'number' ? percentage : Number(percentage.replace('%', ''))
  }

  async function addTeam({ params }) {
    const p = {
      name: params?.name,
      email: params?.email,
      phone: params?.phone,
      type: isRegister ? 'barber' : type,
      barbershop_id: userData?.barbershop?.id || userData?.id,
      percentage: formatPercentage(params?.percentage),
      products_percentage: formatPercentage(params?.products_percentage),
      password: params?.password,
      visible_name: params?.visible_name,
      agenda_time_interval: individualTime ? agenda_time_interval : null,
    }
    const res = await api
      .post(BARBERSHOP_ADD_BARBER, p)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutate = useMutation('add-team', (params) => addTeam(params), {
    onSuccess: async (value) => {
      toast({
        title: t('ADD_BARBER_SUCCESS'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      queryClient.invalidateQueries('get-team')
      getValues({ params: initialBarber, isValid: false })
      setType('')
      onClose()
    },
    onError: (error) => {
      toast({
        title: t('ADD_BARBER_ERROR'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const [onEdit, { loading: loadingEdit }] = useLazyFetch(USER_DATA(collaboratorData?.user_id, 'team'), {
    method: 'put',
    onCompleted: () => {
      toast({
        title: t('EDIT_TEAM_SUCCESS'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })

      onClose()
    },
    onError: ({ error }) => {
      toast({
        title: t('EDIT_TEAM_ERROR'),
        description: error,
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const editCollaborator = async (params) => {
    const { name, phone, email, percentage, products_percentage, visible_name, identifier_doc } = params

    const payload = {
      name,
      phone,
      email,
      percentage: formatPercentage(percentage),
      products_percentage: formatPercentage(products_percentage),
      barbershop_id: userData?.barbershop?.id || userData?.id,
      type,
      visible_for_clients: visibleForClients,
      visible_name,
      agenda_time_interval: individualTime ? agenda_time_interval : null,
      identifier_doc: identifier_doc?.replace(/[^0-9]/g, ''),
    }

    await onEdit({ params: payload })
  }

  const handleConfirm = () => {
    if (cantAddMore) {
      history.push('/perfil/meu-plano')
      return
    }

    if (isEdit) {
      editCollaborator(values?.params)
      return
    }
    mutate.mutate({
      params: {
        ...values?.params,
        type: 'barber',
        visible_for_clients: visibleForClients,
        agenda_time_interval,
      },
    })
  }

  const isButtonDisabled = useMemo(
    () =>
      mutate?.isLoading ||
      values?.params?.name === '' ||
      values?.params?.phone === '' ||
      values?.params?.email === '' ||
      (!isRegister && !type && !work)
  )

  const selectOptions = useMemo(() => {
    if (isBlocked) {
      return select.filter((option) => option.id === 0)
    }

    if (
      isEdit &&
      collaboratorData?.user?.type !== 'barber' &&
      collaboratorData?.user?.type !== 'barbershopAndBarber' &&
      collaboratorData?.user?.type !== 'ownerAndBarber'
    ) {
      return select.filter((option) => option.id !== 0)
    }

    if (!work) {
      return select.filter((option) => option.id !== 0)
    }

    return select
  }, [isBlocked, work])

  async function changeImageProfile(params) {
    const res = await api
      .post(
        UPDATE_PROFILE_IMAGE(
          collaboratorData?.user?.type || collaboratorData?.type,
          collaboratorData?.barbershop_id,
          collaboratorData?.user_id
        ),
        params
      )
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutateAvatar = useMutation('change-image-profile', (params) => changeImageProfile(params), {
    onSuccess: (data) => {
      setPhoto(data?.profile_image_url)
      // handleSuccess(data)
    },
    onError: (error) => {
      toast({
        title: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const handleSelectPhoto = async (photo) => {
    const reader = new FileReader()
    reader.readAsDataURL(photo)
    reader.onload = () => {
      const splitted = reader.result.split(',')
      const mime = splitted[0].toString()
      const mime1 = mime.split(':')
      const mime2 = mime1[1].split(';')
      const params = {
        base64: splitted[1],
        mime: mime2[0],
      }

      mutateAvatar.mutate(params)
    }
  }

  useEffect(() => {
    setPhoto(collaboratorData.profile_image_url)
  }, [collaboratorData])

  const showType = useMemo(() => {
    if (isRegister) {
      return false
    }
    if (isEdit && collaboratorData?.user?.type === 'barber') {
      return false
    }
    if (
      isEdit &&
      collaboratorData?.user?.type !== 'barber' &&
      collaboratorData?.user?.type !== 'barbershopAndBarber' &&
      collaboratorData?.user?.type !== 'ownerAndBarber'
    ) {
      return true
    }
    if (collaboratorData?.user?.type !== 'barbershopAndBarber' && collaboratorData?.user?.type !== 'ownerAndBarber') {
      return true
    }
    return false
  })

  const showVisible = useMemo(() => {
    if (
      collaboratorData?.user?.type === 'barber' ||
      collaboratorData?.user?.type === 'barbershopAndBarber' ||
      collaboratorData?.user?.type === 'ownerAndBarber' ||
      type === 'barber' ||
      type === 'barbershopAndBarber' ||
      type === 'ownerAndBarber'
    ) {
      return true
    }
    return false
  }, [collaboratorData?.user?.type, isEdit, type, isRegister])

  const handleAddMinutes = () => {
    setAgendaTimeInterval(agenda_time_interval + 5)
  }

  const handleDecreaseMinutes = () => {
    setAgendaTimeInterval(agenda_time_interval - 5 > 5 ? agenda_time_interval - 5 : 5)
  }

  return (
    <ModalMenu isOpen={isModalOpen} onClose={onClose} title={t(isEdit ? 'EDIT_TEAM' : 'ADD_BARBER')}>
      {cantAddMore ? (
        <Text kind="bold" marginTop={theme.pxToRem(32)} marginBottom={pxToRem(36)}>
          {t('BARBER_LIMIT')}
        </Text>
      ) : (
        <>
          <Box marginBottom={pxToRem(200)}>
            <Flex flexDir="column" alignItems="center" justifyContent="center" w="100%">
              <FileInput
                type="logo"
                defaultImage={photo}
                canDelete={false}
                onChange={handleSelectPhoto}
                loading={mutateAvatar.isLoading}
              />

              <Text fontSize={12} color="textGrey" mt={theme.pxToRem(16)}>
                {t('CHANGE_IMAGE')}
              </Text>
            </Flex>
            <Form
              mb={32}
              initialValues={values?.params}
              data={dataBarber({
                type,
                theme,
                setOpenPass,
                openPass,
                openNew,
                setOpenNew,
                errors: error,
                isEdit,
                userData,
              })}
              validationSchema={validationBarber}
              getValues={getValues}
            />

            {showType && (
              <>
                {!isEdit && (
                  <>
                    <Text kind="semiBold" mt={theme.pxToRem(16)}>
                      Seu colaborador faz algum tipo de atendimento?
                    </Text>
                    <Flex mt={theme.pxToRem(12)} w="100%" justifyContent="space-between">
                      <Button
                        onClick={() => {
                          setWork(false)
                          setType(collaboratorData?.type || collaboratorData?.user?.type || '')
                          setIndividualTime(false)
                        }}
                        h={theme.pxToRem(48)}
                        kind={!work ? 'primary' : 'outline'}
                        w="48%"
                      >
                        {t('NO')}
                      </Button>
                      <Button
                        onClick={() => {
                          setWork(true)
                          setType('barber')
                        }}
                        h={theme.pxToRem(48)}
                        kind={work ? 'primary' : 'outline'}
                        w="48%"
                      >
                        {t('YES')}
                      </Button>
                    </Flex>
                  </>
                )}
                {!work ? (
                  <>
                    <Text kind="medium" fontSize={theme.pxToRem(14)} color="textGrey" mb={theme.pxToRem(8)} mt="16px">
                      {t('FUNCTION')}
                    </Text>
                    <Flex
                      bg="backgroundLayout"
                      flexDir="column"
                      h={theme.pxToRem(56)}
                      align="center"
                      justify="center"
                      px={theme.pxToRem(16)}
                      borderRadius={theme.pxToRem(8)}
                      borderWidth={1}
                      borderColor="border"
                    >
                      <Select
                        name="service"
                        value={type}
                        placeholder={t('SELECT')}
                        w="full"
                        fontFamily="Montserrat Bold"
                        fontSize={14}
                        textColor="textLight"
                        iconColor="primary"
                        borderColor="transparent"
                        errorBorderColor="baseRed"
                        focusBorderColor="transparent"
                        variant="unstyled"
                        marginBottom={theme.pxToRem(2)}
                        defaultValue={collaboratorData?.type || collaboratorData?.user?.type}
                        onChange={(e) => setType(e.target.value)}
                      >
                        {selectOptions.map((item, index) => (
                          <option
                            style={{
                              backgroundColor: colors.menuBackground,
                              fontFamily: 'Montserrat Medium',
                            }}
                            key={`service_${item?.id}`}
                            value={item?.value}
                          >
                            {item?.name}
                          </option>
                        ))}
                      </Select>
                    </Flex>
                  </>
                ) : (
                  <TextInput
                    mt="16px"
                    noBorder
                    name="visible_name"
                    value={values?.visible_name}
                    onChange={(e) =>
                      getValues({
                        isValid: values?.isValid,
                        params: { ...values?.params, visible_name: e.target.value },
                      })
                    }
                    label="Definir função"
                    placeholder="Barbeiro"
                  />
                )}
                {isBlocked && (
                  <Text kind="bold" fontSize={14} color="primary" mt={theme.pxToRem(8)}>
                    {t('ADD_OTHER_FUNCTIONS')}
                  </Text>
                )}
              </>
            )}
            {((!isRegister && work) || (isEdit && !collaboratorData?.type)) && (
              <SelectCard
                item={{
                  title: 'Deseja que seu barbeiro tenha um intervalo de agenda diferente do padrão?',
                }}
                setSelected={(e) => setIndividualTime(e)}
                selected={individualTime}
                mb="16px"
                mt="42px"
              />
            )}
            {individualTime && (
              <Flex flexDir="row" w="100%">
                <Flex
                  alignItems="center"
                  justifyContent="flex-start"
                  px={theme.pxToRem(18)}
                  mr={theme.pxToRem(16)}
                  height={theme.pxToRem(56)}
                  width="90%"
                  bgColor="agendaIntervalBackground"
                  borderRadius={8}
                >
                  <Icon name="Clock" size={16} mr={theme.pxToRem(16)} />
                  <Text fontSize={14}>{`${agenda_time_interval} ${t('MINUTES')}`}</Text>
                </Flex>

                <Button
                  disabled={agenda_time_interval === 5}
                  size="square"
                  kind="outline"
                  color="primary"
                  borderColor="agendaIntervalBackground"
                  borderRadius={8}
                  mr={theme.pxToRem(16)}
                  onClick={handleDecreaseMinutes}
                >
                  <Icon name="Minus" size={20} />
                </Button>
                <Button
                  size="square"
                  kind="outline"
                  color="primary"
                  borderColor="agendaIntervalBackground"
                  borderRadius={8}
                  onClick={handleAddMinutes}
                >
                  <Icon name="Plus" size={20} />
                </Button>
              </Flex>
            )}
            {showVisible && (
              <>
                <Text kind="semiBold" mt={theme.pxToRem(16)}>
                  Colaborador visível para os clientes:
                </Text>
                <Flex mt={theme.pxToRem(12)} w="100%" justifyContent="space-between">
                  <Button
                    onClick={() => setVisibleForClients(false)}
                    h={theme.pxToRem(48)}
                    kind={visibleForClients === false ? 'primary' : 'outline'}
                    w="48%"
                  >
                    {t('NO')}
                  </Button>
                  <Button
                    onClick={() => setVisibleForClients(true)}
                    h={theme.pxToRem(48)}
                    kind={visibleForClients === true ? 'primary' : 'outline'}
                    w="48%"
                  >
                    {t('YES')}
                  </Button>
                </Flex>
              </>
            )}
          </Box>
        </>
      )}

      <ModalButtonBackground>
        <Button
          w="100%"
          type="submit"
          onClick={handleConfirm}
          disabled={
            (!cantAddMore && isButtonDisabled) ||
            (!isEdit && values?.params?.password !== values?.params?.confirmPassword)
          }
        >
          {cantAddMore ? t('SUBSCRIBE') : t(isEdit ? 'EDIT_TEAM' : 'ADD_BARBER')}
        </Button>
      </ModalButtonBackground>
    </ModalMenu>
  )
}

TeamModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
}
