import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react'
import { Text, Button, Flex, TextInput } from 'components/atoms'

export const ConfirmReceivedCashModal = ({ isOpen, onClose, onClick, isLoading }) => {
  const theme = useTheme()
  const [date, setDate] = useState()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bgColor="cardBackground" bgRepeat="no-repeat" bgPosition="top">
        <ModalHeader textAlign="flex-start" mt={theme.pxToRem(20)} w={'100%'}>
          <Text kind="extraBold" fontSize={24} w="100%">
            Selecione a data de recebimento da fatura
          </Text>
          {/* <Flex mt={theme.pxToRem(12)} mb={theme.pxToRem(24)} align="center" justify="flex-start" flexDir="column">
            <Text kind="regular" fontSize={14}>
              O sistema só permite o download de no máximo 100 registros por vez.
            </Text>
            <Text kind="regular" fontSize={12}>
              Escreve abaixo de quanto a quanto deseja baixar: Ex. 0 a 100
            </Text>
            <Text kind="regular" fontSize={12}>
              {`Caso deseje baixar todos os registros inserir primeiro de 0 a 100, depois 100 a 200, até o número máximo de: ${totalRegister} `}
            </Text>
          </Flex> */}
          <Flex>
          <TextInput
            //   noBorder
            mt='16px'
              type="date"
              name="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              label='Data de recebimento'
              padding="5%"
              width='100%'
            />
          </Flex>
        </ModalHeader>
        <ModalCloseButton color="white" />

        <ModalBody mt={theme.pxToRem(24)} w="100%">
          <Button isLoading={isLoading} isDisabled={!date || isLoading} w="100%" mb={theme.pxToRem(24)} onClick={() => onClick(date)}>
            Confirmar
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
