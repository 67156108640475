import { api } from 'services/api/config'
import { useBarbershopStore } from 'services/stores'

export const useServices = () => {
  const { userData } = useBarbershopStore()
  const getServices = async (props) => {
    const response = await api({
      url: `v3/barbershop/services/${userData?.id}`,
    })
    return response.data
  }

  const updateServiceOrder = async (props) => {
    console.log(props)
    const response = await api.put(`v3/barbershop/order-services`, props?.body)
    return response.data
  }

  return {
    updateServiceOrder,
    getServices,
  }
}
