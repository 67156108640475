import React, { useState, useEffect } from 'react'
import { useTheme } from '@emotion/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useBarbershopStore } from 'services/stores'
import { useQuery, useMutation } from 'react-query'
import { Text, Button, Avatar, Flex, TextInput, Box } from 'components/atoms'
import { t } from 'services/translation'
import { format } from 'date-fns'
import { useBarbershop } from 'services/hooks'
import { clearCurrency } from 'utils/clearCurrency'
import { formatCurrency } from 'utils/formatCurrency'

export const CashierUpdateStatusModal = ({
  cashierValue,
  isCashierOpen,
  isOpen,
  onClose,
  date,
  resume,
  current_cashier,
}) => {
  const theme = useTheme()
  const [value, setValue] = useState(0)
  const { getCashierUserInfo, newCashierTransaction } = useBarbershop()
  const { userData, setUserData } = useBarbershopStore()

  const { data, isLoading, isRefetching, refetch } = useQuery(
    'get-user-info',
    () => getCashierUserInfo(userData?.barbershop?.id || userData?.id),
    { enabled: false }
  )

  const mutation = useMutation('open-cashier-transaction', () =>
    newCashierTransaction({
      params: {
        id: current_cashier?.transaction?.id,
        barbershop_id: userData?.barbershop?.id || userData?.id,
        value: isCashierOpen ? cashierValue : value?.length === 0 ? 0 : value,
        type: isCashierOpen ? 'close_finish_value' : 'open_initial_value',
        simple_date: format(date || new Date(), 'yyyy-MM-dd'),
        hour: format(new Date(), 'HH:mm'),
      },
      onSuccess: (d) => {
        setUserData({ ...userData, ...d?.barbershop })
        onClose(false)
      },
    })
  )

  useEffect(() => {
    if (isOpen === true) {
      refetch()
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bgColor="cardBackground"
        bgRepeat="no-repeat"
        bgPosition="top"
      >
        <ModalHeader textAlign="flex-start" mt={theme.pxToRem(20)} w={'100%'}>
          <Text kind="extraBold" fontSize={24} w="100%">
            {isCashierOpen ? 'Fechamento' : 'Abertura'} de Caixa
          </Text>
          <Flex
            mt={theme.pxToRem(12)}
            mb={theme.pxToRem(24)}
            align="center"
            justify="flex-start"
          >
            <Avatar type="barber" size="medium" />
            <Box ml={4}>
              <Text kind="extraBold" fontSize={16}>
                Usuário:{' '}
                {data?.employer?.name ||
                  data?.barber?.name ||
                  data?.barbershop?.owner_name}
              </Text>
              <Text kind="regular" fontSize={14}>
                Data: {format(new Date(), 'dd/MM/yyyy HH:mm')}
              </Text>
            </Box>
          </Flex>
          {!isCashierOpen && (
            <TextInput
              placeholder="0,00"
              onChange={(e) => setValue(clearCurrency(e.target.value))}
              leftElement={
                <Text
                  color="textGrey"
                  kind="medium"
                  mt={theme.pxToRem(2)}
                  width={theme.pxToRem(40)}
                >
                  {t('CURRENCY')}
                </Text>
              }
              value={value}
              mask="CURRENCY_MASK"
              label={'Valor inicial do caixa'}
            />
          )}

          {isCashierOpen && (
            <Box>
              <Flex mb={4} w="100%" justify="space-between">
                <Text kind="extraBold" fontSize={16}>
                  {resume?.initial?.title}
                </Text>
                <Text kind="regular" fontSize={14}>
                  {formatCurrency(resume?.initial?.value || 0)}
                </Text>
              </Flex>
              {resume?.data?.length &&
                resume?.data?.map((item) => (
                  <Flex w="100%" justify="space-between">
                    <Text kind="extraBold" fontSize={16}>
                      {item?.title}
                    </Text>
                    <Text kind="regular" fontSize={14}>
                      {formatCurrency(item?.total_value || item?.value || 0)}
                    </Text>
                  </Flex>
                ))}
              <Box mt={4} />
              {resume?.final?.length &&
                resume?.final?.map((item) => (
                  <Flex w="100%" justify="space-between">
                    <Text kind="extraBold" fontSize={16}>
                      {item?.title}
                    </Text>
                    <Text kind="regular" fontSize={14}>
                      {formatCurrency(item?.total_value || item?.value || 0)}
                    </Text>
                  </Flex>
                ))}
            </Box>
          )}
        </ModalHeader>
        <ModalCloseButton color="white" />

        <ModalBody mt={theme.pxToRem(24)} w="100%">
          <Button w="100%" mb={theme.pxToRem(24)} onClick={mutation.mutate}>
            {isCashierOpen ? 'Fechar' : 'Abrir'} Caixa
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
