import React, { useState } from 'react'
import { Center, Tab, TabList, TabPanel, TabPanels, Tabs, useToast } from '@chakra-ui/react'
import { Box, Flex, Text, Button, Loader } from 'components/atoms'
import { FinanceResumeCard, AntecipationCard, ExtractCard, Table } from 'components/molecules'
import { pxToRem } from 'styles/metrics'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'
import { parseISO, format } from 'date-fns'

import { api } from 'services/api/config'
import { REQUEST_ANTECIPATION, GET_BALANCE, GET_ASAAS_EXTRACT } from 'services/api/endpoints'
import { useQuery, useMutation } from 'react-query'
import { theme } from 'styles'
import { PixModal } from 'components/organisms/PixModal'
import { columns } from './settings'
import { Layout } from 'components'
import { ExportAsaasExtractModal } from 'components/organisms/ExportAsaasExtractModal'

export const Exchange = (props) => {
  const toast = useToast()
  const { t } = useTranslation()
  const { userData } = useBarbershopStore()
  let [skip, setSkip] = useState(0)
  const [loadMore, setLoadMore] = useState(false)
  const [extract, setExtract] = useState([])
  const [pixModalOpen, setPixModalOpen] = useState(false)
  const [downloadModalOpen, setDownloadModalOpen] = useState(false)

  async function getAsaasExtract() {
    const res = await api
      .get(GET_ASAAS_EXTRACT(userData?.barbershop?.id || userData?.id), {
        params: { skip },
      })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  async function getBalance() {
    const res = await api
      .get(GET_BALANCE(userData?.barbershop?.id || userData?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const { data: balance, isLoading: isLoadingBalance } = useQuery('get-balance', getBalance, {
    onError: (error) => {
      toast({
        message: t('ERROR_LOADING_DATA'),
        description: error.toString().substring(7),
        type: 'error',
        duration: 4000,
      })
    },
    refetchOnWindowFocus: false,
  })

  const { data, isLoading } = useQuery(['get-asaas-extract', skip], getAsaasExtract, {
    onSuccess: (values) => {
      setExtract([...extract, ...values?.extract])
      setLoadMore(false)
    },
    onError: (error) => {
      toast({
        message: t('ERROR_LOADING_DATA'),
        description: error.toString().substring(7),
        type: 'error',
        duration: 4000,
      })
      setLoadMore(false)
    },
    refetchOnWindowFocus: false,
  })

  return (
    <Layout
      {...props}
      isPremiumScreen
      headerTitle="Saldo e saque"
      widthTitle="100%"
      // secondActionClick={handleExport}
      // secondActionText="Exportar em excel"
      // secondLoading={mutate.isLoading}
      // iconSecondActionText={'Excel'}
      thirtyClick={{
        icon: 'Money',
        iconColor: 'black',
        onClick: () => setPixModalOpen(true),
        text: t('EXCHANGE'),
        disabled: !data?.balance === 0 || data?.balance === 0,
      }}
    >
      {(isLoading || isLoadingBalance) && !loadMore ? (
        <Center>
          <Loader />
        </Center>
      ) : (
        <Flex flexDirection="column" h="100%">
          <FinanceResumeCard
            label="BALANCE_EXCHANGE"
            background="success"
            balance="successDark"
            width="100%"
            mb={16}
            value={balance?.balance || 0}
          />
          <Text kind="extraBold" fontSize={18} w="100%">
            {`Total de registros: ${data?.total}`}
          </Text>
          <Table data={extract} columns={columns} loading={isLoading && !loadMore} />

          {data?.hasMore && (
            <Flex width="full" justifyContent="center" paddingY={theme.pxToRem(32)}>
              <Button
                disabled={loadMore}
                isLoading={loadMore}
                onClick={() => {
                  setLoadMore(true)
                  setSkip((skip += 100))
                }}
              >
                {t('LOAD_MORE')}
              </Button>
            </Flex>
          )}
          <PixModal isOpen={pixModalOpen} onClose={() => setPixModalOpen(false)} total={data?.balance} />
          {/* <ExportAsaasExtractModal
            isOpen={downloadModalOpen}
            onClose={() => setDownloadModalOpen(false)}
            totalRegister={extract?.total}
            onClick={}
          /> */}
        </Flex>
      )}
    </Layout>
  )
}

Exchange.path = '/saldo'
Exchange.title = 'Saldo e saque'
Exchange.secured = true
