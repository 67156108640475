import { t } from 'services/translation'
import { format, parseISO } from 'date-fns'
import { formatToSimpleDate, formatPrice, handleAppoitmentStatusColor } from 'utils'
import { Text, Flex } from 'components'
import { pxToRem } from 'styles/metrics'
import { colors } from 'styles'
const cellColors = [...colors.pieChart]

export const Dot = ({ bg }) => {
  return (
    <Flex
      ml={pxToRem(-8)}
      mr={pxToRem(8)}
      h={pxToRem(8)}
      w={pxToRem(8)}
      bgColor={bg}
      borderRadius={100}
    />
  )
}

export const settingsCards = [
  // {
  //   label: 'Valor em Caixa',
  //   description: 'Dinheiro',
  //   color: 'white',
  //   info: 'disabled',
  //   infoText: 'Valor total que deve estar disponível no caixa, considerando os pagamentos recebidos em Dinheiro + Valor de abertura do Caixa',
  //   value_key: 'cashier_din_value',
  // },
  {
    label: 'Saldo do dia',
    description: 'Valor total',
    color: 'white',
    info: 'disabled',
    infoText: 'Total de entradas - total de saídas',
    value_key: 'balance',
  },
  //receitas
  {
    label: 'Valor bruto',
    description: 'Entradas',
    color: 'success',
    info: 'disabled',
    infoText:
      'Valor total bruto de entradas (vendas do dia + receitas lançadas manualmente)',
    value_key: 'total_entries',
  },
  //despesas
  {
    label: 'Valor bruto',
    description: 'Saídas',
    color: 'danger',
    info: 'disabled',
    infoText:
      'Valor total de despesas lançadas manualmente e descontos oferecidos',
    value_key: 'expenses_value',
  },
  //recebidos
  {
    label: 'Valor bruto',
    description: 'Serviços',
    color: 'success',
    info: 'disabled',
    infoText: 'Valor total bruto de venda de serviços',
    value_key: 'services_value',
  },
  //recebidos
  {
    label: 'Valor bruto',
    description: 'Produtos',
    color: 'success',
    info: 'disabled',
    infoText: 'Valor total bruto de venda de produtos',
    value_key: 'products_value',
  },
  //recebidos
  {
    label: 'Valor bruto',
    description: 'Lançamentos',
    color: 'success',
    info: 'disabled',
    infoText: 'Valor total bruto de receitas lançadas manualmente',
    value_key: 'revenues_value',
    last: true,
  },
]

export const cardsMobile = (monthExtract) => {
  return [
    //cebidos
    {
      label: 'APPOINTMENTS',
      color: 'success',
      value: monthExtract?.total_entries || 0,
      description: 'TOTAL_ENTRIES',
    },
    //lançamentos receitas
    {
      label: 'APPOINTMENTS',
      color: 'danger',
      value: -monthExtract?.total_exits || 0,
      description: 'TOTAL_EXITS',
      last: true,
    },
  ]
}

const formatPaymentName = (item) => {
  if(item?.appointment_payments?.length) {
    const { length } = item?.appointment_payments
    return `${item?.appointment_payments?.[0]?.payment_method_choosed?.name}${length > 1 ? ` + ${length}` : ''
      }`
  }
  if (item?.payment_method_choosed?.name) {
    return item?.payment_method_choosed?.name
  }

  if (item?.payment_methods?.length) {
    const { length } = item?.payment_methods
    return `${item?.payment_methods[0]?.name}${length > 1 ? ` + ${length}` : ''
      }`
  }

  if (item?.payment_method) {
    return item?.payment_method?.name
  }

  if (item?.status === 'client_missed') {
    return 'Sem pagamento'
  }

  if (item?.type === 'worth' || item?.type === 'revenue') {
    return ' - '
  }

  if (item?.status !== 'finished' && item?.status !== 'paid') {
    return 'Aguardando pagamento'
  }

  return '-'
}

const formatDateHour = (item) => {
  const date =
    formatToSimpleDate(
      item?.simple_date || item?.due_at_date || item?.createdAt
    ) || ''
  const hour =
    item?.start_hour?.substring(0, 5) ||
    format(parseISO(item?.createdAt), 'HH:mm') ||
    ''
  return `${date} - ${hour}`
}


export const appointmentsColumns = [
  {
    key: 'id',
    label: 'Nº da comanda',
    width: 80,
    render: (item) => (
      <Flex flexDir="row" align="center">
        <Dot
          bg={item?.type === 'singleProduct' ? 'pieChart.8' : 'pieChart.0'}
        />
        <Text noOfLines={1}>{item?.id}</Text>
      </Flex>
    ),
  },
  {
    key: 'date',
    label: 'Data - Hora',
    width: 100,
    render: (item) => <Text noOfLines={1}>{formatDateHour(item)}</Text>,
  },
  {
    key: 'client',
    label: 'Cliente',
    render: (item) => (
      <Text noOfLines={1}>{item?.client?.name || item?.title}</Text>
    ),
  },
  {
    key: 'profissional',
    label: 'Profissional',
    render: (item) => (
      <Text noOfLines={1} kind="medium">
        {item?.barber?.name ||
          item?.user?.barber?.[0]?.name ||
          item?.user?.employer?.[0]?.name ||
          item?.employee?.name ||
          '-'}
      </Text>
    ),
  },
  {
    key: 'payment_method',
    label: 'Pagamento',
    render: (item) => (
      <Text noOfLines={1} kind="medium">
        {formatPaymentName(item)}
      </Text> 
    ),
  },
  {
    key: 'value',
    label: 'Valor bruto',
    render: (item) => (
      <Text
        kind="medium"
        color={
          item?.type === 'expense'
            ? 'danger'
            : item?.status === 'client_missed'
              ? 'textMedium'
              : 'success'
        }
      >
        {item?.type === 'expense' ? '- ' : ''}
        {formatPrice(
          (item?.total_value || item?.value || 0) + (item?.discount || 0)
        )}
      </Text>
    ),
  },
  {
    key: 'discount',
    label: 'Desconto p/ cliente',
    width: 80,
    render: (item) => (
      <Text kind="medium" color={'danger'}>
        {'- '}
        {formatPrice(item?.discount || 0)}
      </Text>
    ),
  },
  {
    key: 'status',
    label: 'STATUS_TABLE',
    render: (item) => (
      <Text
        kind="bold"
        color={handleAppoitmentStatusColor(item?.status)}
      >
        {t(item?.status)}
      </Text>
    ),
  },
]

export const releasesColumns = [
  {
    key: 'date',
    label: 'Data - Hora',
    render: (item) => <Text noOfLines={1}>{formatDateHour(item)}</Text>,
  },
  { 
    key: 'title',
    label: 'Título',
    render: (item) => <Text noOfLines={1}>{item?.title  ? item?.title : item?.type === 'worth' ? 'Vale' : item?.description}</Text>,
  },
  {
    key: 'profissional',
    label: 'Profissional',
    render: (item) => (
      <Text noOfLines={1} kind="medium">
        {item?.barber?.name ||
          item?.user?.barber?.[0]?.name ||
          item?.user?.employer?.[0]?.name ||
          '-'}
      </Text>
    ),
  },
  {
    key: 'payment_method',
    label: 'Pagamento',
    render: (item) => (
      <Text noOfLines={1} kind="medium">
        {formatPaymentName(item)}
      </Text>
    ),
  },
  {
    key: 'value',
    label: 'Valor bruto',
    render: (item) => (
      <Text
        kind="medium"
        color={item?.type === 'expense' || item?.type === 'worth' ? 'danger' : 'success'}
      >
        {item?.type === 'expense' || item?.type === 'worth' ? '- ' : ''}
        {formatPrice(item?.total_value || item?.value)}
      </Text>
    ),
  },
  {
    key: 'status',
    label: 'STATUS_TABLE',
    render: (item) => (
      <Text
        kind="bold"
        color={
          item?.status === 'pending' || item?.status === 'PENDING'
            ? 'danger'
            : item?.status === 'awaiting'
              ? 'primary'
              : 'success'
        }
      >
        {t(item?.status)}
      </Text>
    ),
  },
]

export const entryColumns = (total) => [
  {
    key: 'name',
    label: 'Método de pagamento',
    render: (item, index) => {
      return (
        <Flex flexDir="row" align="center">
          <Dot bg={cellColors[index]} />
          <Text noOfLines={1}>
            {item?.payment_method?.payment_method_choosed?.name}
          </Text>
        </Flex>
      )
    },
  },
  {
    key: 'quantity',
    label: 'Nº pagamentos',
    render: (item) => (
      <Text noOfLines={1} kind="medium">
        {item?.quantity} de {total} (
        {((item?.quantity / total) * 100).toFixed(0)}%)
      </Text>
    ),
  },
  {
    key: 'total_value',
    label: 'Valor total',
    render: (item) => (
      <Text kind="medium" color={'success'}>
        {formatPrice(item?.value || 0)}
      </Text>
    ),
  },
]

export const exportOptions = [
  {
      text: 'Exportar relatório geral',
      value: 'general'
  },
  {
      text: 'Exportar relatório por colaborador',
      value: 'collaborators'
  },
]
