import React, { useState, useEffect } from 'react'
import {
  Layout,
  Table,
  Text,
  FinanceResumeCard,
  MobileList,
  PaymentMethodResumeCard,
  FeatureBlockedMessage,
  PieChart,
  Icon,
  TextInput,
  Box,
  AppointmentsResumeCard,
  Button,
} from 'components'
import { settings, settingsMobile } from './settings'
import { Center, Flex, Spinner, useToast, useTheme } from '@chakra-ui/react'
import { formatPrice } from 'utils'
import { useBreakpoint, useBarbershopFinance } from 'services/hooks'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'
import { format, startOfMonth, endOfMonth } from 'date-fns'
import './hideScrollbar.css'

import { useQuery } from 'react-query'
import { paymentReport } from 'utils/mockData'
import { UnitysModal } from 'components/organisms/Unitys'
import { financesStore } from 'services/stores/finances'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { getPaymentInfo } from 'utils/format-payment-report'

export const PaymentMethodReport = (props) => {
  const { isDesktop } = useBreakpoint()
  const { userData } = useBarbershopStore()
  const theme = useTheme()
  const toast = useToast()
  const { t } = useTranslation()
  const [unityModalOpen, setUnityModalOpen] = useState(false)
  const [formattedPayments, setFormattedPayments] = useState([])
  const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
  const [endDate, setEndDate] = useState(format(endOfMonth(new Date()), 'yyyy-MM-dd'))
  const { unity, setUnity } = financesStore()
  const cellColors = [...theme.colors.pieChart]

  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [userData])

  useEffect(() => {
    setUnity(userData)
  }, [userData])

  const columns = [
    {
      key: 'payment_method',
      label: 'PAYMENT_METHOD',
      render: (item, index) => {
        return (
          <Flex flexDir="row" align="center">
            <Text>{item?.name}</Text>
            <Box
              ml={theme.pxToRem(8)}
              h={theme.pxToRem(8)}
              w={theme.pxToRem(8)}
              bgColor={cellColors[index % cellColors?.length]}
              borderRadius={100}
            />
          </Flex>
        )
      },
    },
    {
      key: 'value',
      label: 'VALOR',
      render: (item) => <Text>{formatPrice(item?.value)}</Text>,
    },
    {
      key: 'payments',
      label: 'PAYMENTS',
      render: (item) => <Text>{item?.quantity}</Text>,
    },
    {
      key: 'discounts',
      label: 'DISCOUNTS',
      render: (item) => <Text>{formatPrice(item?.value_percentage)}</Text>,
    },
  ]

  const dataPie = () => {
    let label = formattedPayments?.map((item) => item?.name)

    let d = formattedPayments?.map((item) => item?.quantity)

    const values = {
      label,
      data: d,
    }

    return values
  }

  const { getExtract } = useBarbershopFinance()

  const { data, refetch, isLoading, isRefetching, isError } = useQuery(
    ['get-payment-method-resume', unity],
    () =>
      getExtract({
        start_date: startDate,
        end_date: endDate,
        barbershop_id: userData?.barbershop?.id || userData?.id,
        date_filter: 'date',
      }),
    {
      onSuccess: (value) => {
        const { formatted_payments } = getPaymentInfo(value?.all_transactions?.filter((i) => i?.status === 'finished'))
        setFormattedPayments(formatted_payments)
      },
      onError: (error) => {
        toast({
          title: t('Erro ao carregar relatório. Por favor, tente novamente'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  )

  return (
    <Layout
      {...props}
      isPremiumScreen
      headerTitle={t('PAYMENT_METHOD_REPORT')}
      widthTitle="100%"
      secondActionClick={() => setUnityModalOpen(true)}
      iconSecondActionText={'Shop'}
      subTitle={`Unidade: ${unity?.name}`}
      iconColor="black"
      secondActionText={t('CHANGE_UNITY')}
    >
      <>
        {(!data || isLoading || isRefetching) && !isError ? (
          <Center w="100%">
            <Spinner color="textLight" />
          </Center>
        ) : isDesktop ? (
          <Flex w="100%" flexDir="column">
            <Flex w="100%" flexDir="row" alignItems="stretch" justifyContent="space-between" pb={theme.pxToRem(16)}>
              <Flex h={theme.pxToRem(210)} w="79%" flexDir="column" justifyContent="space-between" flexWrap="wrap">
                {settings(data)?.map((item) => (
                  <FinanceResumeCard
                    width="40%"
                    notCurrency={item?.notCurrency}
                    label={t(item?.label)}
                    description={t(item?.description)}
                    value={item?.value}
                    color={item?.color}
                    info={item?.info}
                    infoText={item?.infoText}
                  />
                ))}
                <AppointmentsResumeCard mb={16} width="40.5%" item={data} infoText={'Resumo geral dos agendamentos'} />
                <Flex h={theme.pxToRem(210)} w="43%" align="center" justify="center">
                  {formattedPayments?.length !== 0 ? (
                    <PieChart title="PAYMENT_METHOD_REPORT" h="100%" w="100%" data={dataPie()} />
                  ) : (
                    <Flex flexDir="column" align="center">
                      <Icon name="Chart" size={40} />
                      <Text align="center">{t('NO_DATA_RESUME')}</Text>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Flex>

            <Flex w="40%">
              <TextInput
                noBorder
                type="date"
                name="start_date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                label={t('START')}
                padding="4%"
                width={'100%'}
              />
              <TextInput
                noBorder
                type="date"
                name="end_date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                label={t('END')}
                ml="10%"
                padding="5%"
                width={'90%'}
              />
              <Button
                loading={isRefetching}
                icon="Search"
                size="small"
                iconColor="black"
                ml="12px"
                mt="26px"
                onClick={() => {
                  refetch()
                }}
              >
                Pesquisar
              </Button>
            </Flex>
            <Table
              loading={isLoading}
              data={
                formattedPayments?.sort((a, b) => {
                  return a?.quantity > b?.quantity ? -1 : a?.quantity < b?.quantity ? 1 : 0
                }) || []
              }
              columns={columns}
            />
          </Flex>
        ) : (
          <Flex flexDirection="column" width="full">
            <Flex w="100%" flexWrap="wrap" flexDir="column" h={theme.pxToRem(116)}>
              {settingsMobile(data)?.map((item) => (
                <FinanceResumeCard
                  wMobile="50%"
                  last={item?.last}
                  notCurrency={item?.notCurrency}
                  label={t(item?.label)}
                  description={t(item?.description)}
                  value={item?.value}
                  color={item?.color}
                />
              ))}
            </Flex>

            <AppointmentsResumeCard full item={data} />

            <Flex h={theme.pxToRem(250)} mb={theme.pxToRem(16)}>
              {formattedPayments?.length !== 0 ? (
                <PieChart h="100%" w="100%" data={dataPie()} dataLabels title="PAYMENT_METHOD_REPORT" />
              ) : (
                <Flex flexDir="column" align="center">
                  <Icon name="Chart" size={20} />
                  <Text>{t('NO_DATA_RESUME')}</Text>
                </Flex>
              )}
            </Flex>

            <MobileList
              loading={isLoading}
              data={
                formattedPayments.sort((a, b) => {
                  return a?.quantity > b?.quantity ? -1 : a?.quantity < b?.quantity ? 1 : 0
                }) || []
              }
              renderItem={({ item, index }) => (
                <PaymentMethodResumeCard mb={16} full item={item} total={data?.payment_method_info?.total} />
              )}
            />
          </Flex>
        )}
      </>
      {unityModalOpen && (
        <UnitysModal
          isModalOpen={unityModalOpen}
          onClose={() => setUnityModalOpen(false)}
          selected={unity}
          setSelected={setUnity}
        />
      )}
    </Layout>
  )
}

PaymentMethodReport.path = '/relatorio-pagamentos'
PaymentMethodReport.title = 'Relatório - Pagamentos'
PaymentMethodReport.secured = true
