import { Box } from "@chakra-ui/react"
import { generalColumns } from "./settings"
import { Table } from "components/molecules"


export const GeneralGoalsResume = ({data, isLoading}) => {
  return (
        <Box flex={1} mt="12px">
        <Table
          data={data ?? []}
          loading={isLoading}
          columns={generalColumns}
          isSelectableRow
        //   onSelect={(item) => {
        //     setSelectedBarber(item)
        //     setDetailsModalOpen(true)
        //   }}
        />
      </Box>
    )
}