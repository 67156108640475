import { useQuery } from 'react-query'
import { new_api } from '../config-v2'
import { saveAs } from 'file-saver'

const getDownloadNF = async ({ barbershop_id, start_date, end_date }) => {
  const res = await new_api({
    url: `invoice-records/download/${barbershop_id}`,
    params: {
      start_date: start_date,
      end_date: end_date,
      type: 'xml',
    },
    responseType: 'blob'
  })

  return new Blob([res.data])
}

export const getDownloadKey = 'invoice-issuers'

export const useGetDownloadNF = ({ barbershop_id, start_date, end_date }) =>
  useQuery({
    queryKey: [getDownloadKey, barbershop_id],
    queryFn: () =>
      getDownloadNF({
        barbershop_id,
        start_date,
        end_date,
      }),
    onSuccess: (data) => {
      const link = document.createElement('a');
      const url = URL.createObjectURL(data);
      link.href = url;
      link.download = `NFS-${Number(new Date())}.zip`;
      link.click();
    },
    enabled: false,
  })
