import React, { useState } from 'react'
import { Text, Box } from 'components/atoms'
import { Table } from 'components/molecules'
import { useAppointmentDetailStore, useBarbershopStore, useAppointmentStore } from 'services/stores'
import { Flex } from '@chakra-ui/react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useBarbershop, useAppointment } from 'services/hooks'
import { formatPrice } from 'utils'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react'
import { t } from 'services/translation'
import { usePlans } from 'services/hooks/use-plans'

const columns = [
  {
    key: 'name',
    label: 'NAME_TABLE',
    render: (item) => (
      <Text w="100%" kind="bold" color="textLight">
        {item.name}
      </Text>
    ),
  },
  {
    key: 'price',
    label: 'VALUES',
    render: (item) => (
      <Text w="100%" kind="bold" color="textLight">
        {formatPrice(item.price)}
      </Text>
    ),
  },
  {
    key: 'time_required',
    label: 'LENGTH',
    render: (item) => <Text>{item.time_required.substring(0, 5)}</Text>,
  },
]

export const columnsSignature = [
  {
    key: 'name',
    label: 'NAME_TABLE',
    render: (item) => (
      <Text w="100%" kind="bold" color="textLight">
        {item?.name}
      </Text>
    ),
  },
]

export const Service = ({ goBack, isSingleSale, setEnabled, ...props }) => {
  const { selectedAppointment, setSelectedAppointment } = useAppointmentDetailStore()
  const { userData } = useBarbershopStore()
  const { setServiceArray, serviceArray } = useAppointmentStore()
  const [tabIndex, setTabIndex] = useState(false)
  const { getServicesByBarber } = useBarbershop()
  const { addServiceAppointment } = useAppointment()
  const { getServicesPlans } = usePlans()
  const queryClient = useQueryClient()
  const { isLoading, data } = useQuery(['get-services'], () =>
    getServicesByBarber({
      id: userData?.id,
      barber_id: selectedAppointment?.barber?.id,
    })
  )

  const { isLoading: isLoadingPlans, data: servicesPlans } = useQuery(['get-services-plans', selectedAppointment], () =>
    getServicesPlans({
      plan_id: selectedAppointment?.plan_id || selectedAppointment?.formatted_services?.find(i => i?.plan_id)?.plan_id ||  selectedAppointment?.client?.subscriptions?.find(a => !a?.inactive)?.plan_id || selectedAppointment?.client?.subscriptions?.[0]?.plan_id,
    })
  )


  const mutate = useMutation('add-service-appointment', (params) => addServiceAppointment(params))

  const handleSelect = (item) => {
    // if (
    //   (selectedAppointment?.type === 'servicePackage') &&
    //   selectedAppointment?.status !== 'reopened'
    // ) {
    //   setSelectedAppointment({
    //     ...selectedAppointment,
    //     formatted_services: [...selectedAppointment?.formatted_services, item],
    //   })
    //   setEnabled(false)
    //   goBack()
    //   return
    // }

    if (selectedAppointment?.status !== 'reopened') {
      // setSelectedAppointment({...selectedAppointment,
      //   formatted_services: [...selectedAppointment?.formatted_services,
      //     item]})
      const params = {
        appointment_id: selectedAppointment?.id,
        service_id: item?.service_id,
        barber_id: item?.barber_id || selectedAppointment?.barber_id,
      }
      mutate.mutate(
        { params, onSuccess: goBack },
        {
          onSettled: () => {
            queryClient.invalidateQueries('get-services-appointment')
          },
        }
      )
      return
    }

    if (serviceArray.includes(item)) {
      const updateItems = serviceArray.filter((i) => i !== item)
      setEnabled(false)
      setServiceArray(updateItems)
    } else {
      setEnabled(false)
      setServiceArray([...serviceArray, item])
    }
    goBack()
  }

  const component = (type) => {
    // if (type === 'package_service') {
    //   return (
    //     <Flex flexDir="column">
    //       <Box mb="8px">
    //         {packageService &&
    //           packageService?.map((item) => (
    //             <Text mt="8px" color={'primary'}>
    //               {`Quantidade de ${
    //                 data?.find((i) => i?.id === item?.service)?.name
    //               } restantes: ${item?.quantity}`}
    //             </Text>
    //           ))}
    //       </Box>

    //       <Table
    //         withoutHeader
    //         data={
    //           data?.filter((item) => packageService.some(obj => obj.service === item.id)) || []
    //         }
    //         loading={isLoading || loadingSignature}
    //         columns={columns}
    //         isSelectableRow
    //         onSelect={(sel) => handleSelect(sel, 'package')}
    //         selectedList={serviceArray}
    //         isMultiSelectable
    //       />
    //     </Flex>
    //   )
    // }

    if (type === 'signature') {
      return (
        <Flex flexDir="column">
          <Table
            withoutHeader
            data={servicesPlans?.services_plans || []}
            loading={isLoadingPlans}
            columns={columnsSignature}
            isSelectableRow
            // onSelect={(sel) => signatureStatus?.signature?.schedule?.status ? handleSelect(sel, 'signature') : null}
            onSelect={(sel) => handleSelect(sel)}
            selectedList={serviceArray}
            isMultiSelectable
          />
        </Flex>
      )
    }
    return (
      <Flex>
        <Table
          withoutHeader
          isMultiSelectable
          isSelectableRow
          data={
            data?.filter((i) => i?.active && i?.type !== 'signature' && i?.type !== 'individual_service_package') || []
          }
          loading={isLoading}
          columns={columns}
          onSelect={handleSelect}
        />
      </Flex>
    )
  }

  if (selectedAppointment?.type === 'signature') {
    return (
      <Tabs
        onChange={(index) => {
          setTabIndex(index)
        }}
      >
        <TabList color="textGrey" borderColor="modalBorder" borderBottomWidth={1}>
          <Tab
            fontSize={14}
            fontWeight={500}
            _selected={{
              color: '#E5B817',
              borderColor: '#E5B817',
              borderBottomWidth: 4,
            }}
          >
            {t('normal')}
          </Tab>
          {/* {packageService && packageService?.length > 0 && <Tab
              fontSize={14}
              fontWeight={500}
              _selected={{
                color: '#E5B817',
                borderColor: '#E5B817',
                borderBottomWidth: 4,
              }}
            >
              {'Pacote de serviço'}
            </Tab>} */}
          <Tab
            fontSize={14}
            fontWeight={500}
            _selected={{
              color: '#E5B817',
              borderColor: '#E5B817',
              borderBottomWidth: 4,
            }}
          >
            {t('signature')}
          </Tab>
        </TabList>

        <TabPanels p={0} marginTop="12px">
          <TabPanel p={0}>{component('normal')}</TabPanel>
          {/* {packageService && packageService?.length > 0 && <TabPanel p={0}>{component('package_service')}</TabPanel>} */}
          {<TabPanel p={0}>{component('signature')}</TabPanel>}
        </TabPanels>
      </Tabs>
    )
  } else {
    return (
      <Flex flexDirection="column" h="100%">
        <Box h="100%">
          <Table
            withoutHeader
            isMultiSelectable
            isSelectableRow
            data={
              data?.filter((i) => i?.active && i?.type !== 'signature' && i?.type !== 'individual_service_package') ||
              []
            }
            loading={isLoading}
            columns={columns}
            onSelect={handleSelect}
          />
        </Box>
      </Flex>
    )
  }
}
