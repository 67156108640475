import React, { useMemo } from 'react'
import { useToast } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useLazyFetch } from 'services/hooks/use-fetch-old'
import { People, Telephone, Email } from 'assets/icons'
import { Box, Text, TextInput, Button, Flex } from 'components/atoms'
import { ModalMenu } from 'components/molecules'
import { addUserValidations } from 'utils/user'
import { formatPayloadDate } from 'utils'
import { formatPhone } from 'utils/phone-formater'
import { colors } from 'styles'
import { pxToRem } from 'styles/metrics'
import { BARBERSHOP_ADD_CLIENT, USER_DATA } from 'services/api/endpoints'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'
import { addHours, format, parseISO } from 'date-fns'
import { useQueryClient } from 'react-query'

export const ClientsModal = ({ isModalOpen, onClose, reloadClientsList, isEdit = false, clientData = {} }) => {
  const toast = useToast()
  const { t } = useTranslation()
  const { userData } = useBarbershopStore()
  const queryClient = useQueryClient()

  const [submitNewCustomer, { loading }] = useLazyFetch(BARBERSHOP_ADD_CLIENT, {
    method: 'post',
    onCompleted: () => {
      toast({
        title: t('REGISTER_CLIENT'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })

      reloadClientsList((state) => !state)
      onClose()
      resetForm()
    },
    onError: ({ error }) => {
      toast({
        title: t('REGISTER_CLIENT_ERROR'),
        description: error,
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const [onEditClient, { loading: loadingEditClient }] = useLazyFetch(USER_DATA(clientData?.user_id), {
    method: 'put',
    onCompleted: () => {
      toast({
        title: t('EDIT_CLIENT_SUCCESS'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      queryClient.invalidateQueries(['get-client-detail'])
      onClose()
    },
    onError: ({ error }) => {
      toast({
        title: t('EDIT_CLIENT_ERROR'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const clientBirthDay = useMemo(() => {
    if (!clientData?.birth_date) return ''

    return format(addHours(new Date(parseISO(clientData?.birth_date)), 3), 'dd/MM/yyyy')
  }, [clientData])

  const { values, handleSubmit, handleChange, errors, resetForm, setFieldValue } = useFormik({
    initialValues: {
      name: isEdit ? clientData?.name : '',
      phone: isEdit ? formatPhone(clientData?.user?.phone, userData) : '',
      email: isEdit ? clientData?.user?.email || '' : '',
      birth_date: isEdit ? clientBirthDay : '',
      cpf: isEdit ? clientData?.cpf : '',
    },
    validationSchema: addUserValidations({ emailRequired: false }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: () => {
      const params = {
        name: values.name,
        phone: values.phone,
        email: values.email,
        birth_date: formatPayloadDate(values.birth_date),
        cpf: values.cpf,
        barbershop_id: !isEdit && (userData?.barbershop?.id || userData?.id),
      }

      if (isEdit) {
        onEditClient({
          params,
        })
        return
      }

      submitNewCustomer({
        params,
      })
    },
  })

  const isButtonDisabled = useMemo(
    () =>
      loading ||
      values.name === '' ||
      values.phone === '' ||
      errors.name ||
      errors.phone ||
      (errors.email && !errors?.email?.includes('email must be a `string` type')),
    [loading, values, errors]
  )

  return (
    <ModalMenu isOpen={isModalOpen} onClose={onClose} title={t(isEdit ? 'EDIT_CLIENT' : 'ADD_CLIENT_TITLE')}>
      <Box marginBottom={pxToRem(16)}>
        <Text fontSize={pxToRem(12)} marginBottom={pxToRem(16)} textTransform="uppercase" fontWeight="600">
          {t('CLIENT_NAME')}
        </Text>

        <TextInput
          noBorder
          type="text"
          name="name"
          id="name"
          placeholder={t('CLIENT_NAME_PLACEHOLDER')}
          color={colors.textLight}
          value={values.name}
          disabled={loading}
          error={errors.name}
          onChange={handleChange}
          leftElement={<People color={colors.primary} />}
        />
      </Box>

      <Box marginBottom={pxToRem(16)}>
        <Text fontSize={pxToRem(12)} marginBottom={pxToRem(16)} textTransform="uppercase" fontWeight="600">
          {t('PHONE')}
        </Text>

        <TextInput
          noBorder
          // mask="PHONE_MASK"
          type="text"
          name="phone"
          id="phone"
          placeholder={t('CLIENT_PHONE_PLACEHOLDER')}
          color={colors.textLight}
          value={values.phone}
          disabled={loading}
          error={errors.phone}
          leftElement={<Telephone color={colors.primary} width={pxToRem(16)} />}
          onChange={(text) => setFieldValue('phone', String(text.target.value).replace(/\D/g, ''))}
        />
      </Box>

      <Box marginBottom={pxToRem(16)}>
        <Text fontSize={pxToRem(12)} marginBottom={pxToRem(16)} textTransform="uppercase" fontWeight="600">
          {t('CLIENT_EMAIL')}
        </Text>

        <TextInput
          noBorder
          type="text"
          name="email"
          id="email"
          placeholder="E-mail"
          color={colors.textLight}
          value={values.email || ''}
          disabled={loading}
          error={!errors?.email?.includes('email must be a `string` type') && errors.email}
          onChange={handleChange}
          leftElement={<Email color={colors.primary} />}
        />
      </Box>

      <Box marginBottom={pxToRem(16)}>
        <Text fontSize={pxToRem(12)} marginBottom={pxToRem(16)} textTransform="uppercase" fontWeight="600">
          {t('BIRTH_DATE')}
        </Text>

        <TextInput
          noBorder
          type="text"
          name="birth_date"
          id="name"
          placeholder="XX/XX/XXXX"
          mask="DATE_MASK"
          color={colors.textLight}
          value={values.birth_date}
          disabled={loading}
          error={errors.birth_date}
          onChange={handleChange}
          leftElement={<People color={colors.primary} />}
        />
      </Box>

      <Box marginBottom={pxToRem(16)}>
        <Text fontSize={pxToRem(12)} marginBottom={pxToRem(16)} textTransform="uppercase" fontWeight="600">
          {t('CPF')}
        </Text>

        <TextInput
          noBorder
          type="text"
          name="cpf"
          id="name"
          placeholder="XXX.XXX.XXX-XX"
          mask="CPF_MASK"
          color={colors.textLight}
          value={values.cpf}
          disabled={loading}
          error={errors.cpf}
          onChange={handleChange}
          leftElement={<People color={colors.primary} />}
        />
      </Box>

      <Flex marginTop="auto">
        <Button
          w="100%"
          type="submit"
          onClick={handleSubmit}
          disabled={isButtonDisabled || loading || loadingEditClient}
        >
          {t(isEdit ? 'EDIT_CLIENT' : 'REGISTER_CLIENT_BUTTON')}
        </Button>
      </Flex>
    </ModalMenu>
  )
}

ClientsModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
