import { formatPrice } from './price'

const getPaymentMethod = (payment) => {
  if (
    payment?.payment_method_choosed?.type ||
    payment?.type ||
    payment?.payment_method_choosed?.name ||
    payment?.name
  ) {
    const type =
      payment?.payment_method_choosed?.type || payment?.type || payment?.payment_method_choosed?.name || payment?.name
    if (type?.toLowerCase()?.includes('déb') || type?.toLowerCase()?.includes('deb')) {
      return '04'
    }
    if (type?.toLowerCase()?.includes('créd') || type?.toLowerCase()?.includes('cred')) {
      return '03'
    }
    if (type?.toLowerCase()?.includes('pix')) {
      return '17'
    }
    if (type?.toLowerCase()?.includes('cash') || type?.toLowerCase()?.includes('din')) {
      return '01'
    }
    return '18'
  }
  return ''
}

const getPaymentMethodType = (payment) => {
  if (payment?.payment_method_choosed?.name || payment?.name) {
    const name = payment?.payment_method_choosed?.name || payment?.name
    if (name?.toLowerCase()?.includes('visa')) {
      return '01'
    }
    if (name?.toLowerCase()?.includes('master')) {
      return '02'
    }
    if (name?.toLowerCase()?.includes('americ')) {
      return '03'
    }
    if (name?.toLowerCase()?.includes('soro')) {
      return '04'
    }
    return ''
  }
  return ''
}

// NOTA FISCAL DE SERVIÇO (NFSe)
export const formatNFSeParams = ({
  type,
  invoiceIssuerQuery,
  services_list,
  client,
  appointment_id,
  discount,
  total_value,
}) => {
  const barber_service_value = Number(
    (services_list?.reduce((acc, val) => acc + Number(val.barber_value || 0), 0) || 0)?.toFixed?.(2)
  )
  const payment_value = services_list?.reduce(
    (acumulador, service) =>
      acumulador +
      Number(
        (service?.service_value || service?.price || 0) -
          (discount
            ? ((service?.service_value || service?.price || 0) / (total_value + discount)) * discount
            : service?.discount_value || 0)
      ),
    0
  )
  // barbearias que o municipio não permite dedução de comissão de barbeiros
  const barbershops_without_deductions = [
    16, // trato de barbados
    22, // zicartola
    26, // velho texas
    27, // velho texas
    28, // velho texas
    14, // santiago unid. mercadão
    5, // dilson barber
    25, // ludo barber
    29, // nell cortes 2
    30, // nell cortes 1
    65, // motiro
    66, // barboza
    130, // break barbers
  ]
  // barbearias que pedem os campos regime_especial_tributacao e optante_simples_nacional
  const barbershops_with_special_tribute = [
    5, // dilson barber
    31, // joao barbeiro
    64, // kaizer tattoo
  ]
  // barbearias que pedem o campo natureza_operacao
  const barbershops_with_operation_nature = [
    31, // joao barbeiro
    64, // kaizer tattoo
  ]
  // barbearias que não enviam o campo codigo_tributario_municipio
  const barbershops_without_tribute_code = [
    5, // dilson barber
  ]
  // barbearia onde o código do item_lista_servico é diferente de 0601
  const barbershop_service_item_dif_code = [
    29, // nell cortes 2
    30, // nell cortes 1
  ]
  // barbearia que emitem a nota com valor líquido (subtraindo a comissão do barbeiro)
  const barbershops_without_barber_value = [
    16, // trato de barbados
  ]
  // barbearia que emitem a nota com a comissão do barbeiro no campo desconto_incondicionado
  const barbershops_with_barber_value_incondicionado = [
    130, // break barbers
  ]
  // barbearias que pedem o campo CNAE
  const barbershop_with_cnae = {
    64: '9602501', // kaizer tattoo
  }

  const newInvoiceServiceData = {
    appointment_id,
    issuer_id: invoiceIssuerQuery.data.id,
    tomador: {
      cpf: client?.cpf || '',
      razao_social: client?.name || '',
      // endereco: {
      //   logradouro: invoiceIssuerQuery.data?.logradouro,
      //   numero: invoiceIssuerQuery.data?.numero,
      //   complemento: invoiceIssuerQuery.data?.complemento,
      //   bairro: invoiceIssuerQuery.data?.bairro,
      //   codigo_municipio: invoiceIssuerQuery.data?.codigo_municipio,
      //   uf: invoiceIssuerQuery.data?.uf,
      //   cep: invoiceIssuerQuery.data?.cep,
      //   municipio: invoiceIssuerQuery.data?.municipio,
      // },
      endereco: {
        ...client.address?.[0]?.focus_addresses,
      },
    },
    optante_simples_nacional: true,
    regime_especial_tributacao: 6,
    ...(barbershops_with_special_tribute?.includes(invoiceIssuerQuery.data.id)
      ? { optante_simples_nacional: true, regime_especial_tributacao: 6 }
      : {}),
    ...(barbershops_with_operation_nature?.includes(invoiceIssuerQuery.data.id) ? { natureza_operacao: 1 } : {}),
    servico: {
      aliquota: invoiceIssuerQuery.data?.aliquota_iss,
      discriminacao: `Lei nº 13.352/2016 - Lei do Salão-Parceiro\n\nServiços:\n${services_list
        ?.map((itm) => (type === 'historic' ? itm?.product?.name : itm?.name))
        ?.join('\n')} \n\nCota-parte Salão Parceiro: ${formatPrice(barber_service_value || 0)}`,
      iss_retido: invoiceIssuerQuery.data?.iss_retido,
      item_lista_servico: barbershop_service_item_dif_code?.includes(invoiceIssuerQuery.data.id)
        ? invoiceIssuerQuery.data?.codigo_tributario_municipio
        : '0601', // conferir
      ...(barbershops_without_tribute_code?.includes(invoiceIssuerQuery.data.id)
        ? {}
        : { codigo_tributario_municipio: invoiceIssuerQuery.data?.codigo_tributario_municipio }),
      valor_servicos: barbershops_without_barber_value.includes(invoiceIssuerQuery.data.id)
        ? payment_value - (barber_service_value || 0)
        : payment_value,
      valor_deducoes: barbershops_without_deductions.includes(invoiceIssuerQuery.data.id) ? 0 : barber_service_value,
      ...(!!barbershop_with_cnae?.[invoiceIssuerQuery.data.id]
        ? { codigo_cnae: barbershop_with_cnae?.[invoiceIssuerQuery.data.id] }
        : {}),
      ...(barbershops_with_barber_value_incondicionado?.includes(invoiceIssuerQuery.data.id)
        ? { desconto_incondicionado: barber_service_value || 0 }
        : {}),
    },
  }
  return newInvoiceServiceData
}

// NOTA FISCAL DE PRODUTO (NFCe)
export const formatNFCeParams = ({ type, products_list, client, payments, discount, total_value, appointment_id }) => {
  const itens = [
    ...(products_list?.map((product, index) => {
      const item_value =
        type === 'historic'
          ? Number(product?.product_value || 0)?.toFixed?.(2)
          : Number(product?.item_value || 0)?.toFixed?.(2)
      const name = type === 'historic' ? product?.additional_product?.name : product?.name
      const id = type === 'historic' ? product?.additional_product?.id : product?.id
      const item_discount =
        product?.discount ||
        (discount
          ? (Number(product?.product_value || product?.item_value || 0) / (total_value + discount)) * discount
          : product?.discount_value || 0)
      return {
        numero_item: index + 1,
        codigo_produto: id ?? '',
        descricao: name ?? '',
        codigo_ncm: '3305.10.00',
        cfop: '5102',
        quantidade_comercial: '1',
        quantidade_tributavel: '1',
        valor_bruto: item_value ?? '0.00',
        valor_desconto: item_discount?.toFixed(2) || '0.00',
        valor_unitario_comercial: item_value ?? '',
        unidade_comercial: 'UN',
        valor_unitario_tributavel: item_value ?? '',
        unidade_tributavel: 'UN',
        icms_origem: '0',
        icms_situacao_tributaria: '102',
        icms_aliquota: '',
        icms_base_calculo: '',
        icms_modalidade_base_calculo: '',
      }
    }) ?? []),
  ]

  const principalPaymentMethod = payments
    ?.filter((p) => p?.payment_value > 0)
    ?.sort((a, b) => b?.payment_value > a?.payment_value)?.[0]

  const paymentTotalValue = itens?.reduce(
    (accumulated, current) => accumulated + Number(current?.valor_bruto || 0) - Number(current?.valor_desconto || 0),
    0
  )

  const newInvoiceData = {
    helpers: {
      carrierType: '',
      addRecipientData: false,
      recipientType: 'PF',
      showAdvancedFields: false,
      additionalData: '{}',
      sendClientAddress: false,
    },

    appointment_id,
    natureza_operacao: 'VENDA AO CONSUMIDOR',
    presenca_comprador: '1',
    modalidade_frete: '9',
    local_destino: '1',
    informacoes_adicionais_contribuinte: '',

    // IssuerInvoiceItemsForm
    itens,
    // IssuerInvoicePaymentForm
    formas_pagamento: payments
      ?.filter((p) => p?.payment_value > 0)
      ?.map((payment, index) => {
        const payment_value = payment?.payment_value?.toFixed(2)
        return {
          numero_item: index + 1,
          forma_pagamento: getPaymentMethod(principalPaymentMethod),
          valor_pagamento: paymentTotalValue ?? '0.00',
          ...(getPaymentMethod(principalPaymentMethod) === '01' || getPaymentMethod(principalPaymentMethod) === '17'
            ? {}
            : {
                bandeira_operadora: getPaymentMethodType(principalPaymentMethod),
                tipo_integracao: '2',
                cnpj_credenciadora: '',
                numero_autorizacao: '',
              }),
        }
      }),

    // IssuerInvoiceCarrierForm
    nome_transportador: '',
    cnpj_transportador: '',
    cpf_transportador: '',
    inscricao_estadual_transportador: '',
    endereco_transportador: '',
    municipio_transportador: '',
    uf_transportador: '',

    // IssuerInvoiceRecipientForm
    nome_destinatario: client?.name ?? '',
    cpf_destinatario: '',
    telefone_destinatario: client?.user?.phone ?? '',
    cnpj_destinatario: '',
    indicador_inscricao_estadual_destinatario: '',
    cep_destinatario: '',
    uf_destinatario: '',
    municipio_destinatario: '',
    bairro_destinatario: '',
    logradouro_destinatario: '',
    numero_destinatario: '',
  }
  return newInvoiceData
}
