import React, { useState, useEffect, useMemo } from 'react'
import { format, startOfMonth, endOfMonth, differenceInDays, parseISO, isSameMonth } from 'date-fns'
import {
  Layout,
  FinanceReleaseModal,
  AppointmentFinanceModal,
  FeatureBlockedMessage,
  TextInput,
  Button,
  Text,
} from 'components'
import { Center, Flex, Spinner, useTheme, useToast } from '@chakra-ui/react'
import { useBreakpoint, usePremiumBlock } from 'services/hooks'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'
import './hideScrollbar.css'
import { financesStore } from 'services/stores/finances'
import { useQuery } from 'react-query'
import { RenderDesktop } from './desktop'
import { RenderMobile } from './mobile'
import { resumeReport } from 'utils/mockData'
import { UnitysModal } from 'components/organisms/Unitys'
import { usePermissionCollaborator, useBarbershopFinance } from 'services/hooks'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'

export const FinancesScreen = ({ ...props }) => {
  const { userData } = useBarbershopStore()
  const toast = useToast()
  const theme = useTheme()
  const { isDesktop } = useBreakpoint()
  const { t } = useTranslation()
  const [releaseModalOpen, setReleaseModalOpen] = useState(false)
  const [unityModalOpen, setUnityModalOpen] = useState(false)
  const [appointmentModalOpen, setAppointmentModalOpen] = useState(false)
  const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
  const [endDate, setEndDate] = useState(format(endOfMonth(new Date()), 'yyyy-MM-dd'))
  const [selectedItem, setSelectedItem] = useState()
  const [financeData, setFinanceData] = useState(null)

  const { setMonthExtract, unity, setUnity } = financesStore()
  const permission = usePermissionCollaborator('finance')
  const { getDashboard } = useBarbershopFinance()

  const isBlocked = usePremiumBlock()

  const onCloseModal = () => {
    refetch()
    setUnityModalOpen(false)
    setReleaseModalOpen(false)
    setAppointmentModalOpen(false)
    setSelectedItem(null)
  }

  useEffect(() => {
    setUnity(userData)
  }, [userData])

  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [userData])

  const { data, refetch, isLoading, isRefetching } = useQuery(
    ['get-month-resume'],
    () =>
      getDashboard({
        start_date: startDate,
        end_date: endDate,
        barbershop_id: userData?.barbershop?.id || userData?.id,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (value) => {
        setMonthExtract(value)
        setFinanceData(value)
      },
      onError: (error) => {
        toast({
          title: t('LOAD_COMMISSIONS_ERROR'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
      retry: false,
    }
  )

  const averageClients = () => {
    if (isSameMonth(parseISO(startDate), new Date())) {
      return (data?.total_clients / (differenceInDays(new Date(), parseISO(startDate)) + 1)).toFixed(2)
    }
    return (data?.total_clients / (differenceInDays(parseISO(endDate), parseISO(startDate)) + 1)).toFixed(2)
  }

  const renderChildren = () => {
    if (!data || isLoading || isRefetching) {
      return (
        <Center w="100%">
          <Spinner color="textLight" />
        </Center>
      )
    }

    if (!financeData) {
      return (
        <Center w="100%">
          <Text>Clique novamente em Pesquisar</Text>
        </Center>
      )
    }

    if (isDesktop) {
      return (
        <>
          {isBlocked && <FeatureBlockedMessage title="FINANCIAL_BLOCKED" section="FINANCES" />}
          <RenderDesktop
            startDate={startDate}
            endDate={endDate}
            data={isBlocked ? resumeReport : financeData}
            averageClients={averageClients}
            isBlocked={isBlocked}
            userData={userData}
          />
        </>
      )
    }

    return (
      <>
        {isBlocked && <FeatureBlockedMessage title="FINANCIAL_BLOCKED" section="FINANCES" />}
        <RenderMobile data={isBlocked ? resumeReport : data} isBlocked={isBlocked} userData={userData} />
      </>
    )
  }

  return (
    <Layout
      {...props}
      isPremiumScreen
      headerTitle={t('Dashboard Financeiro')}
      widthTitle="100%"
      contentPosition={isBlocked && 'relative'}
      mt={isBlocked && theme.pxToRem(24)}
      p={isBlocked && theme.pxToRem(24)}
      secondActionClick={() => setUnityModalOpen(true)}
      iconSecondActionText={'Shop'}
      subTitle={`Unidade: ${unity?.name}`}
      iconColor="black"
      secondActionText={t('CHANGE_UNITY')}
      px={100}
      headerActionText={!isBlocked && permission === 'edit' && t('ADD_RELEASE')}
      headerActionClick={() => setReleaseModalOpen(true)}
      dir="column"
    >
      <Flex w="40%" mb={theme.pxToRem(16)}>
        <TextInput
          noBorder
          type="date"
          name="start_date"
          value={startDate}
          onChange={(e) => {
            setStartDate(e.target.value)
            setFinanceData(null)
          }}
          label={t('START')}
          padding={theme.pxToRem(10)}
          mr={theme.pxToRem(16)}
        />
        <TextInput
          noBorder
          type="date"
          name="end_date"
          value={endDate}
          onChange={(e) => {
            setEndDate(e.target.value)
            setFinanceData(null)
          }}
          label={t('END')}
          padding={theme.pxToRem(10)}
          mr={theme.pxToRem(16)}
        />
        <Button
          icon="Search"
          size="small"
          iconColor="black"
          onClick={refetch}
          h={theme.pxToRem(42)}
          mt={theme.pxToRem(26)}
        >
          Pesquisar
        </Button>
      </Flex>
      {renderChildren()}

      {releaseModalOpen && (
        <FinanceReleaseModal isModalOpen={releaseModalOpen} onClose={onCloseModal} releaseData={selectedItem} />
      )}
      {appointmentModalOpen && (
        <AppointmentFinanceModal isModalOpen={appointmentModalOpen} onClose={onCloseModal} selected={selectedItem} />
      )}
      {unityModalOpen && (
        <UnitysModal isModalOpen={unityModalOpen} onClose={onCloseModal} selected={unity} setSelected={setUnity} />
      )}
    </Layout>
  )
}

FinancesScreen.path = '/financeiro'
FinancesScreen.title = 'Financeiro'
FinancesScreen.secured = true
