import { t } from 'services/translation'
import React from 'react'
import { Flex, Text } from 'components'
import { Select } from '@chakra-ui/react'
import { pxToRem } from 'styles/metrics'
import { colors } from 'styles'

export const settings = (monthExtract, products, services) => {
  return [
    {
      label: 'Total de vendas',
      description: 'Valor total de vendas - descontos oferecidos',
      value: monthExtract?.appointments_total_value,
      color: 'success',
      info: 'disabled',
      infoText:
        'Valor total (bruto) de vendas de serviços e produtos no período selecionado subtraindo os descontos oferecidos',
    },
    {
      cards: [
        {
          label: 'Valor das Comandas abertas',
          // description: 'Valores pendentes',
          value: monthExtract?.not_finished_appointments_total_value,
          color: 'primary',
          info: 'disabled',
          infoText:
            'Valor total das comandas em aberto (que não foram finalizadas). Obs.: Este valor está sendo considerado nos valores acima',
        },
        {
          label: 'Descontos oferecidos aos clientes',
          // description: 'Descontos ofertados',
          value: monthExtract?.appointments_total_discounts,
          color: 'success',
          info: 'disabled',
          infoText: 'Valor total de descontos oferecidos aos clientes, como por exemplo "Cortesia"',
        },
      ],
    },
    {
      label: services !== 0 ? `${services} ${t('TOTAL')}` : 'TOTAL',
      description: 'SALES_SERVICE',
      color: 'success',
      notCurrency: false,
      value: monthExtract?.appointments_total_services || 0,
      info: 'disabled',
      infoText: 'Valor total de vendas referentes a serviços',
    },
    {
      label: products !== 0 ? `${products} ${t('TOTAL')}` : 'TOTAL',
      description: 'SALES_PRODUCT',
      color: 'success',
      notCurrency: false,
      value: monthExtract?.appointments_total_products || 0,
      info: 'disabled',
      infoText: 'Valor total de vendas referentes a produtos',
    },
  ]
}

export const settingsMobile = (monthExtract, products, services) => {
  return [
    {
      label: 'SALES',
      description: 'TOTAL',
      value: monthExtract?.appointments_total_value,
      color: 'success',
      info: 'disabled',
      infoText: 'Valor total bruto de agendamentos do período - descontos',
    },
    {
      label: `${services} TOTAL`,
      description: 'SALES_SERVICE',
      color: 'success',
      notCurrency: false,
      value: monthExtract?.appointments_total_services || 0,
      info: 'disabled',
      infoText: 'Valor total de vendas referentes a serviços',
    },
    {
      label: 'SALES',
      description: 'PENDING',
      value: monthExtract?.not_finished_appointments_total_value,
      color: 'success',
      info: 'disabled',
      infoText: 'Valor total de agendamentos pendentes recebimento',
      last: true,
    },
    {
      label: `${products} TOTAL`,
      description: 'SALES_PRODUCT',
      color: 'success',
      notCurrency: false,
      value: monthExtract?.appointments_total_products || 0,
      info: 'disabled',
      infoText: 'Valor total de vendas referentes a produtos',
      last: true,
    },
  ]
}

export const options = [
  {
    id: 0,
    value: 'product',
    name: 'Produtos',
  },
  {
    id: 1,
    value: 'service',
    name: 'Serviços',
  },
  {
    id: 1,
    value: 'without_barber_sell',
    name: 'Produtos (s/ venda para barbeiro)',
  },
]

export const status_options = [
  {
    id: 0,
    value: 'awaiting',
    name: 'Aguardando',
  },
  {
    id: 1,
    value: 'pending',
    name: 'Pendentes',
  },
  {
    id: 2,
    value: 'finished',
    name: 'Finalizados',
  },
  {
    id: 3,
    value: 'client_missed',
    name: 'Cliente Faltou',
  },
  {
    id: 4,
    value: 'pending_and_finished',
    name: 'Pendentes e Finalizados',
  },
]

export const order_options = [
  {
    id: 0,
    value: 'total_value',
    name: 'Maior total em vendas',
  },
  {
    id: 1,
    value: 'quantity',
    name: 'Maior quantidade de vendas',
  },
]

export const SelectFilter = ({ onChange, value, options, label, withoutPlaceholder, width }) => {
  return (
    <Flex
      mr={pxToRem(16)}
      flexDir="row"
      borderRadius={pxToRem(8)}
      bg="cardBackground"
      h={pxToRem(40)}
      align="center"
      justify="center"
      // minW={pxToRem(201)}
    >
      <Text fontSize={14} kind="medium" ml={pxToRem(12)} mr={pxToRem(8)} color="textGrey">
        {t(label)}:
      </Text>

      <Select
        name={label}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        placeholder={!withoutPlaceholder && t('ALL')}
        w={pxToRem((width || value?.length || 7) * 13)}
        minW={pxToRem(120)}
        maxW={!width && pxToRem(230)}
        fontFamily="Montserrat Bold"
        fontSize={14}
        textColor="textLight"
        iconColor="primary"
        borderColor="transparent"
        errorBorderColor="baseRed"
        focusBorderColor="transparent"
        variant="unstyled"
        marginBottom={pxToRem(2)}
      >
        {options?.map((item) => (
          <option
            style={{
              backgroundColor: colors.menuBackground,
              fontFamily: 'Montserrat Medium',
            }}
            key={`select_${item?.name}_${item?.id}`}
          >
            {item?.name}
          </option>
        ))}
      </Select>
    </Flex>
  )
}
