import React, { useEffect, useState } from 'react'
import { Table, Box } from 'components'
import { useBarbershopStore } from 'services/stores/barbershop'
import { useQuery } from 'react-query'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { GOALS_COLUMNS } from './settings'
import { useBarbershopGoalsV2 } from 'services/hooks/use-barber-goals-v2'
import { EditGoalModalV2 } from './EditGoals'

export const ManagerGoalsV2 = () => {
  const { onLogout } = useLogoutPaymentIssues()
  const { getAllGoals } = useBarbershopGoalsV2()
  const { userData } = useBarbershopStore()
  const [editModal, setEditModalOpen] = useState(false)

  useEffect(() => { 
    onLogout()
  }, [])

  const { isLoading, data } = useQuery(['get-all-goals'], () => getAllGoals({ barbershop_id: userData?.id }))

  return (
    <Box flex={1} mt="12px">
      <Table
        data={data?.goals ?? []}
        loading={isLoading}
        columns={GOALS_COLUMNS(userData?.signature_club_enable)}
        isSelectableRow
        onSelect={(a) => setEditModalOpen(a)}
      />
      <EditGoalModalV2 isOpen={editModal !== false} onClose={() => setEditModalOpen(false)} goalData={editModal} />
    </Box> 
  )
}
