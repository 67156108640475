export const pt = {
  finished: 'Finalizado',
  awaiting: 'Aguardando',
  confirmed: 'Confirmado',
  client_missed: 'Cliente Faltou',
  reopened: 'Reaberto',
  pending: 'Pendente',
  paid: 'Pago',
  PAID: 'Pago',
  REFUND: 'Estornado',
  PENDING: 'Pendente',
  CONFIRMED: 'Confirmado',
  RECEIVED: 'Recebido',
  REFUNDED: 'Estornado',
  RECEVEID_IN_CASH: 'Recebido em dinheiro',
  // GENERAL
  OPEN_LINK: 'Abrir link da fatura',
  ERROR_LOADING_DATA: 'Erro ao buscar dados',
  ERROR_SAVE_DATA: 'Erro ao salvar dados',
  // LOG IN
  ACCESS_ACCOUNT: 'Acesse sua conta',
  MANAGE_YOUR_BARBERSHOP: 'Gerencie sua barbearia de forma fácil e rápida',
  EMAIL_PLACEHOLDER: 'E-mail ou Telefone',
  PASSWORD_PLACEHOLDER: 'Sua senha',
  FORGOT_PASSWORD: 'Esqueci minha senha',
  LOGIN_BUTTON: 'Entrar',
  SIGNUP1: 'Não possui uma conta?',
  SIGNUP2: 'Faça seu cadastro',
  USER_NOT_AUTH: 'Usuário não autorizado a usar esse ambiente.',
  USER_NOT_AUTH_DESC: 'O usuário precisa ser uma barbearia.',
  LOGIN_ERROR: 'Falha ao realizar login',
  INVALID_USERNAME: 'Usuário deve ter ao menos 8 caracteres',
  USERNAME_REQUIRED: 'Usuário é obrigatório',
  REGISTER_ERROR: 'Ocorreu um erro ao cadastrar. Por favor, tente novamente.',

  // FORGOT PASSWORD
  REDEFINE_PASSWORD_TEXT: 'Deseja redefinir sua senha?',
  ADD_EMAIL: 'Cadastre seu email',
  ADD_EMAIL_DESC: 'Cadastre ou atualize seu email para definir uma nova senha.',
  RESET_PASSWORD_SUCCESS: 'Senha redefinida com sucesso!',
  RESET_PASSWORD_SUCCESS_DESC: 'Baixe o nosso aplicativo, e agende seu serviço a qualquer momento!',
  RESET_PASSWORD: 'Redefinir senha',
  RESET_PASSWORD_DESC: 'Esqueceu sua senha? Tranquilo!',
  ENTER_THE_EMAIL: 'Informe o e-mail utilizado no seu cadastro que lhe ajudaremos a definir uma nova senha.',
  ENTER_THE_PHONE: 'Informe o celular utilizado no seu cadastro que lhe ajudaremos a definir uma nova senha.',
  REDEFINE_PASSWORD_BUTTON: 'Redefinir Senha',
  CANCEL: 'Cancelar',
  INVALID_TOKEN: 'Token inválido',
  INVALID_PHONE: 'Telefone deve ter ao menos 14 caracteres',

  // LOGIN NOTIFICATION
  SUCCESS: 'Sucesso!',
  PASSWORD_REDEFINED: () => (
    <>
      Sua nova senha foi cadastrada e já pode ser utilizada <br />
      para acessar o BestBarbers. <br />
      Aproveite!
    </>
  ),
  BACK: 'Voltar',

  // LOGIN SETTINGS
  EMAIL_SENT: 'Email enviado!',
  EMAIL_SENT_DESC: (email) => (
    <>
      Encaminhamos um email para
      <br />
      {email}
      <br />
      contendo os passos necessários para recuperar a senha da sua conta.
    </>
  ),
  USER_NOT_FOUND: 'Usuário não encontrado!',
  USER_NOT_FOUND_DESC: (email) => (
    <>
      O email
      <br />
      {email}
      <br />
      não foi encontrado em nosso
      <br />
      banco de dados. Verifique os dados e
      <br />
      tente novamente.
    </>
  ),
  SMS_SENT: 'SMS enviado!',
  SMS_SENT_DESC: (phone) => (
    <>
      Enviamos um SMS para
      <br />
      {phone}
      <br />
      contendo os passos necessários para recuperar a senha da sua conta.
    </>
  ),
  PHONE_NOT_FOUND: (phone) => (
    <>
      O telefone
      <br />
      {phone}
      <br />
      não foi encontrado em nosso
      <br />
      banco de dados. Verifique os dados e
      <br />
      tente novamente.
    </>
  ),

  // CHANGE PASSWORD
  NEW_PASSWORD: 'Criar Nova Senha',
  ENTER_TOKEN_FIRST: 'Insira o token enviado para ',
  ENTER_TOKEN_LAST: ' e a nova senha desejada.',
  CHANGE_PASSWORD_BUTTON: 'Alterar Senha',
  PASSWORD_CHANGED: 'Senha alterada com sucesso!',
  PASSWORD_NOT_CHANGED: 'Erro ao redefinir senha!',
  TOKEN_REQUIRED: 'Token é obrigatório',

  // LOGIN AUTH FACTOR
  VERIFICATION_CODE: 'Código de verificação',
  ENTER_VERIFICATION_CODE: 'Por favor, digite o código que enviamos para o seu telefone de celular cadastrado',
  RESEND_CODE: 'Reenviar código de verificação',

  // REGISTER

  // /cadastro
  CONTROL_YOUR_BARBERSHOP: 'Controle sua barbearia na palma da sua mão.',
  BESTBARBERS_HELPS_YOU:
    'O BestBarbers ajuda você a administrar sua barbearia por completo, desde a marcação de horários até a parte financeira. Para isso, fornecemos para você na plataforma:',
  ADMINISTRATION: 'Administração, histórico e marcação de horários.',
  PROFILE_MANAGEMENT: 'Gerenciamento do perfil (descrição, horário de funcionamento, localização, fotos do espaço).',
  TEAM_MANAGEMENT: 'Gestão do time de barbeiros.',
  SERVICES_MANAGEMENT: 'Criação e edição dos serviços prestados.',
  FINANCIAL_MANAGEMENT:
    'Gestão financeira completa (balanço mensal, fluxo de caixa, gerenciamento de despesas e receitas).',
  CLIENTS_MANAGEMENT: 'Gestão de clientes.',
  APPOINTMENT_REMINDER: 'Lembrete de agendamento para seus clientes não perderem horário.',
  EDIT_APPOINTMENT_SUCCESS: 'Agendamento editado com sucesso',
  MONTHLY_REPORTS: 'Relatórios mensais de barbeiros, serviços e comissões.',
  EXCLUSIVE_ACCESS: 'Acesso exclusivo para cada barbeiro.',
  CREATE_ACCOUNT: 'Criar Conta',
  START_NOW: 'Comece agora a gerenciar sua barbearia de forma fácil e prática.',
  BARBERSHOP_NAME: 'Nome da barbearia',
  PHONE: 'Celular',
  CONFIRM_PASSWORD_PLACEHOLDER: 'Confirme sua senha',
  CONTINUE_BUTTON: 'Continuar',
  ALREADY_REGISTERED: 'Já possui cadastro?',
  LOG_IN: 'Faça login',
  SUBSCRIPTION: 'Assinatura',
  SUBSCRIPTION_TEXT:
    'O BestBarbers não limita o numero de colaboradores ou agendamento. Apenas cobramos a mensalidade e você tem acesso ilimitado.',
  SUBSCRIPTION_FEE: 'Assinatura BestBarbers',
  SUBSCRIPTION_FEE_TEXT: 'Valor da mensalidade',
  FREE_TRIAL: 'Teste gratuitamente por 7 dias',
  CONTACT: 'Contato',
  TERMS: 'Termos de Uso',
  PRIVACY: 'Política de privacidade',

  // /cadastro/barbershop-intro
  BARBERSHOP_INTRO_TITLE: 'Vamos configurar sua barbearia!',
  BARBERSHOP_INTRO_DESC:
    'Antes de continuar, precisamos de algumas informações importantes, como horários de atendimento, serviços oferecidos, fotos e membros da equipe. Estas informações farão com que o perfil de sua barbearia ganhe mais visualizações e atraia mais clientes.',
  BEGIN: 'Começar',

  // /cadastro/perfil-barbearia
  YOUR_BARBERSHOP: 'Sua Barbearia',
  SHOW_WHO_YOU_ARE: 'Mostre quem você é para seus clientes.',
  BARBERSHOP_PHONE: 'Telefone de contato da Barbearia',
  BARBERSHOP_DESCRIPTION: 'Descrição da barbearia',
  BARBERSHOP_DESCRIPTION_PLACEHOLDER: 'Descreva aqui seus serviços, diferenciais...',

  // /cadastro/localizacao
  LOCATION: 'Localização',
  SET_UP_LOCATION: 'Configure seu endereço para os clientes te encontrarem.',
  ZIP_CODE_PLACEHOLDER: 'CEP',
  STATE: 'Estado',
  CITY: 'Cidade',
  ADDRESS: 'Endereço',
  NUMBER: 'Número',
  NEIGHBORHOOD: 'Bairro',
  LOCATION_TOAST: 'Endereço atualizado com sucesso!',
  LOCATION_TOAST_ERROR: 'Falha ao atualizar endereço',
  SEARCH_ADDRESS: 'Pesquisar endereço',
  LOADING: 'Carregando...',
  GOOGLE_MAPS_ERROR: 'Não foi possível carregar a API do Google Maps',

  // /cadastro/horario-funcionamento'
  WORKING_HOURS: 'Horário de Funcionamento',
  WORKING_HOURS_SUBTITLE: 'Disponibilizaremos agendamentos de acordo com seu horário de funcionamento.',
  OPENING_TIME: 'Horário de abertura',
  CLOSING_TIME: 'Horário de fechamento',
  CLOSED_DAY: 'Definir dia da semana como fechado',
  UPDATE_WORKING_HOURS: `Horários atualizados com sucesso!`,
  UPDATE_WORKING_HOURS_ERROR: 'Falha ao atualizar horários',

  // /cadastro/intervalo-agenda
  AGENDA_INTERVAL: 'Intervalo da Agenda',
  AGENDA_INTERVAL_SUBTITLE: 'Selecione o intervalo de tempo entre os horários de sua agenda',
  MINUTES: 'minutos',
  YOUR_AGENDA_INTERVAL: 'Sua agenda ficará assim',
  YOUR_AGENDA_INTERVAL_DESC: 'Altere o intervalo acima para visualizar como os horários de sua agenda serão afetados',
  AGENDA_INTERVAL_SAVED: 'Intervalo da agenda salvo com sucesso!',
  AGENDA_INTERVAL_SAVE_FAIL: 'Falha ao salvar intervalo da agenda!',

  // /cadastro/fotos-barbearia
  PHOTOS: 'Fotos',
  PHOTOS_DESC: 'Publique no mínimo 3 fotos da sua barbearia para os clientes conhecerem melhor seu estabelecimento.',
  PHOTO_LABEL: 'Foto',
  PHOTOS_TOAST: 'Fotos atualizadas com sucesso',

  // /cadastro/servicos-barbearia
  SERVICES: 'Serviços',
  SERVICES_DESC: 'Adicione os serviços que sua barbearia fornece para que os clientes possam realizar agendamentos',
  SERVICE_NAME: 'Nome',
  SERVICE_NAME_PLACEHOLDER: 'Nome do serviço',
  TIME: 'Tempo',
  TIME_PLACEHOLDER: 'hora:minuto',
  PRICE: 'Valor',
  SERVICES_LOAD_ERROR: 'Falha ao carregar os serviços',
  ADD_SERVICE: 'Serviço adicionado com sucesso!',
  ADD_SERVICE_PLUS: '+ Adicionar serviço',
  ADD_SERVICE_ERROR: 'Falha ao salvar serviço',
  REMOVE_SERVICE: `Serviço removido com sucesso!`,
  REMOVE_SERVICE_ERROR: 'Falha ao remover serviço!',

  // /cadastro/equipe
  TEAM: 'Equipe',
  TEAM_DESC: 'Envie convites para sua equipe de barbeiros ou adicione um perfil de barbeiro à essa conta.',
  BARBER_BUTTON: 'Também sou barbeiro',
  BARBER_NAME: 'Nome',
  INVITES: 'Convites',
  BARBERS: 'Barbeiros',
  DELETE_INVITE: 'Convite deletado!',
  BARBER_DELETED: 'Barbeiro deletado!',
  INVITE: 'Convite enviado!',
  FINISH_CONFIGURATION: 'Concluir configuração',

  // /cadastro-concluido
  REGISTER_COMPLETED: 'Cadastro Concluído!',
  PROFILE_COMPLETED: 'Você concluiu os passos necessários para começar a utilizar o BestBarbers.',
  USE_APP_1: 'Clique em ',
  USE_APP_2: 'Ativar minha barbearia',
  USE_APP_3: 'e comece a receber agendamentos!',
  USE_APP_4: 'Caso você ainda não esteja pronto, você poderá ativá-la mais tarde através do seu perfil.',
  PUBLISH_SUCCESS: 'Barbearia publicada com sucesso!',
  PUBLISH_ERROR: 'Erro ao publicar barbearia',
  CONTINUE_WITHOUT_ACTIVATE: 'Continuar sem ativar',

  // /checkout
  CREATE_MEMBERSHIP_SUCCESS: 'Assinatura criada com sucesso!',
  CREATE_MEMBERSHIP_ERROR: 'Houve um erro ao realizar a assinatura.',
  ADD_PAYMENT_CARD: 'Adicionar meio de pagamento',
  ADD_PAYMENT_CARD_DETAILS: 'Para prosseguir com sua assinatura, coloque a seguir as informações do seu cartão.',
  SAFE_BUY_1: 'Compra',
  SAFE_BUY_2: 'Segura',
  SATISFACTION_GUARANTEED_1: 'Satisfação',
  SATISFACTION_GUARANTEED_2: 'Garantida',
  PRIVACY_PROTECTED_1: 'Privacidade',
  PRIVACY_PROTECTED_2: 'Protegida',
  CARD_NUMBER: 'Número do Cartão',
  CARD_NUMBER_PLACEHOLDER: '0000 0000 0000 0000',
  VERIFICATION_CODE_PLACEHOLDER: '000',
  SURNAME: 'Sobrenome',
  EXPIRATION_MONTH: 'Mês de Validade',
  EXPIRATION_YEAR: 'Mês de Validade',
  SUBSCRIBE: 'Assinar',

  // TABLE
  NAME_TABLE: 'Nome',
  EMAIL_TABLE: 'EMAIL',
  PHONE_TABLE: 'TELEFONE',
  TIME_TABLE: 'HORÁRIO',
  CLIENT_TABLE: 'CLIENTE',
  BARBER_TABLE: 'BARBEIRO',
  SERVICE_TABLE: 'SERVIÇO',
  PAYMENT_TABLE: 'PAGAMENTO',
  VALUE_TABLE: 'VALOR',
  STATUS_TABLE: 'STATUS',
  DATE_TABLE: 'DATA',
  DESCRIPTION_TABLE: 'DESCRIÇÃO',
  CATEGORY_TABLE: 'CATEGORIA',
  TOTAL_VALUE_TABLE: 'VALOR TOTAL',
  RECEIVED_VALUE_TABLE: 'VALOR RECEBIDO',
  APPOINTMENTS_TABLE: 'AGENDAMENTOS',
  COMMISSION_TABLE: '% COMISSÃO',
  TOTAL_SALES_TABLE: 'TOTAL EM VENDAS',
  TOTAL_COMMISSION_TABLE: 'TOTAL DE COMISSÕES',
  GROSS_VALUE_TABLE: 'VALOR BRUTO',
  NET_VALUE_TABLE: 'VALOR LÍQUIDO',
  COMMISSION_VALUE_TABLE: 'VALOR COMISSÃO',
  PROMOTIONAL_TABLE: 'PROMOCIONAL',
  PROMOTIONAL_VALUE_TABLE: 'VALOR PROMOCIONAL',
  RATING_TABLE: 'Avaliação',

  // LOCALE
  LOCALE: 'pt-BR',

  // HISTORIC SCREEN
  HISTORIC_TITLE: 'Histórico',
  LOAD_HISTORIC_ERROR: 'Houve um erro ao carregar os agendamentos!',

  // HISTORY LIST COMPONENT
  EMPTY_LIST_MESSAGE: 'Nenhuma informação encontrada.',

  // SELECT DATE MODAL
  SELECT_DATE_MODAL_TITLE: 'Selecionar Data',

  // CLIENTS SCREEN
  LOAD_CLIENTS_ERROR: 'Falha ao carregar clientes.',
  SEARCH_CLIENT_ERROR: 'Falha ao pesquisar clientes.',
  CLIENTS_TITLE: 'Clientes',
  CLIENTS_ACTION_BUTTON: 'Adicionar Clientes',
  SEARCH_CLIENT: 'Pesquisar por cliente',
  LOAD_MORE: 'Carregar mais',

  // ADD CLIENTS MODAL
  REGISTER_CLIENT: `Cliente adicionado com sucesso!`,
  REGISTER_CLIENT_ERROR: 'Erro ao cadastrar cliente.',
  ADD_CLIENT_TITLE: 'Adicionar Cliente',
  CLIENT_NAME: 'Nome',
  CLIENT_NAME_PLACEHOLDER: 'Nome do cliente',
  CLIENT_PHONE_PLACEHOLDER: 'Celular',
  CLIENT_EMAIL: 'E-mail (opcional)',
  REGISTER_CLIENT_BUTTON: 'Adicionar cliente',

  // EDIT CLIENTS MODAL
  EDIT_CLIENT: 'Editar cliente',
  EDIT_CLIENT_SUCCESS: 'Cliente editado com sucesso!',
  EDIT_CLIENT_ERROR: 'Falha ao editar cliente.',
  BIRTH_DATE: 'Data de nascimento',

  // CLIENT DETAILS MODAL
  DELETE_CLIENT: 'Cliente excluído com sucesso!',
  DELETE_CLIENT_ERROR: 'Erro ao excluir cliente.',
  DELETE_CLIENT_BUTTON: 'Excluir Cliente',
  DELETE_CLIENT_MODAL_TITLE: 'Excluir cliente',
  DELETE_CLIENT_MODAL: 'Deseja excluir o cliente ',
  DELETE_CLIENT_CONFIRM: 'Excluir',
  DELETE_CLIENT_WARNING:
    'Esta ação é irreversível e apagará todos os dados do cliente relacionados à sua barbearia, como valores recebidos e históricos de atendimento.',
  WHATSAPP_BLOCKED: 'Chamar os clientes no WhatsApp é exclusivo para assinantes Premium',

  // FINANCIAL SCREEN
  FINANCIAL_TITLE: 'Financeiro',
  LOAD_FINANCIAL_DATA_ERROR: 'Houve um erro ao carregar dados financeiros.',
  ADD_RELEASE: 'Adicionar Lançamento',
  APPOINTMENTS_TOTAL: 'Agendamentos',
  APPOINTMENTS_TOTAL_DESC: 'Total',
  APPOINTMENTS_RECEIVED: 'Agendamentos',
  APPOINTMENTS_RECEIVED_DESC: 'Recebido',
  EXPENSES: 'Despesas',
  EXPENSES_DESC: 'Lançadas',
  PROFITS: 'Receitas',
  PROFITS_DESC: 'Lançadas',
  MONTH_PROJECTION: 'Balanço do Mês',
  MONTH_PROJECTION_DESC: 'Balanço do Mês',
  LATE_PAYMENT: 'Atrasado',
  PENDING_PAYMENT: 'Pendente',
  PAID_PAYMENT: 'Pago',
  ALL: 'Todos',

  // FINANCE RELEASE MODAL
  LOAD_CATEGORIES_ERROR: 'Houve um erro ao carregar as categorias.',
  DELETE_RELEASE: `Lançamento excluído com sucesso!`,
  DELETE_RELEASE_ERROR: `Houve um erro ao excluir o lançamento!`,
  ADD_RELEASE_SUCCESS: `Lançamento criado com sucesso!`,
  EDIT_RELEASE_SUCCESS: `Lançamento atualizado com sucesso!`,
  ADD_RELEASE_ERROR: `Falha ao criar lançamento!`,
  EDIT_RELEASE_ERROR: `Falha ao atualizar lançamento!`,
  EDIT_RELEASE: 'Editar Lançamento',
  RELEASE_TYPE: 'Tipo de Lançamento',
  PROFIT: 'Receita',
  EXPENSE: 'Despesa',
  EX_REVENUE: 'Ex: Venda de Produtos',
  EX_NOT_REVENUE: 'Ex: Conta de Luz',
  title: 'Título',
  VALUE: 'Valor',
  DUE_DATE: 'Data de Vencimento',
  CATEGORY: 'Categoria',
  CATEGORY_SHOP: 'Infraestrutura',
  CATEGORY_PEOPLE: 'Funcionários',
  CATEGORY_MONEY: 'Pagamentos Diversos',
  CATEGORY_STOCKS: 'Material',
  CHOOSE_RELEASE_STATUS: 'Status do lançamento',
  SAVE: 'Salvar',
  DELETE: 'Excluir',
  UPDATE: 'Atualizar',
  DELETE_RELEASE_TITLE: 'Excluir Lançamento',
  CONFIRM_DELETE_RELEASE: 'Deseja excluir o lançamento ',

  // APPOINTMENT FINANCE MODAL
  APPOINTMENT_FINANCE_MODAL_TITLE: 'Detalhes Financeiros do Agendamento',
  ADDITIONAL_PRODUCTS: 'Produtos',
  FINANCIAL_DESCRIPTIVE: 'Descritivo financeiro',
  PAYMENT_METHOD: 'Meio de Pagamento',
  COMMISSION: 'Comissão',
  NET_TOTAL: `Total após descontos`,

  // PAYMENT METHODS SCREEN
  PAYMENT_METHODS: 'Métodos de Pagamento',
  PAYMENT_METHODS_DESC: 'Adicione os métodos de pagamento utilizados na sua barbearia.',
  ADD_PAYMENT_METHOD: 'Adicionar Meio de Pagamento',
  LOAD_PAYMENT_METHODS_ERROR: 'Falha ao carregar meios de pagamento.',
  PERCENTAGE: 'Percentual',
  DUE_IN_DAYS: 'Recebimento (Em dias)',
  DISCOUNT_FROM_BARBER: 'Descontar do barbeiro',
  PAYMENT_METHODS_BLOCK_MESSAGE_HEADER: 'Limite a 2 na versão gratuita',
  PAYMENT_METHODS_BLOCK_MESSAGE: 'Adicione quantos métodos de pagamento você quiser na versão premium!',

  // PAYMENT METHOD MODAL
  DELETE_PAYMENT_METHOD_SUCCESS: `Meio de pagamento excluído com sucesso!`,
  DELETE_PAYMENT_METHOD_ERROR: `Houve um erro ao excluir o meio de pagamento!`,
  SAVE_PAYMENT_METHOD: `Meio de pagamento salvo com sucesso!`,
  SAVE_PAYMENT_METHOD_ERROR: 'Erro ao salvar meio de pagamento.',
  CREATE_PAYMENT_METHOD: 'Adicionar meio de pagamento',
  UPDATE_PAYMENT_METHOD: 'Atualizar meio de pagamento',
  PAYMENT_METHOD_EXAMPLE: 'Ex: Visa - Crédito',
  PERCENTAGE_EXAMPLE: 'Ex: 30%',
  DUE_IN_DAYS_EXAMPLE: 'Ex: 0 (mesmo dia) ou 30 (para 30 dias)',
  DELETE_PAYMENT_METHOD: 'Excluir Meio de Pagamento',
  CONFIRM_DELETE_PAYMENT_METHOD: 'Deseja excluir o meio de pagamento',
  DISCOUNT_BARBER_TITLE: 'Este percentual é descontado dos barbeiros?',
  DISCOUNT_BARBER_SUBTITLE:
    'Caso a comissão do barbeiro seja calculada após o desconto do percentual do pagamento sobre o valor total do serviço, marque sim.',

  // PAYMENT METHOD CARD

  DAYS: 'dias',

  // COMMISSIONS SCREEN
  LOAD_COMMISSIONS_ERROR: 'Houve um erro ao carregar dados das comissões.',
  COMMISIONS_SCREEN_TITLE: 'Relatório de Comissões',
  APPOINTMENTS_COUNT: 'Total de Agendamentos',
  APPOINTMENTS_COUNT_DESC: 'Valor referente aos agendamentos a serem pagos nesse mês.',
  TOTAL_SALES_DESC: 'Valor total faturado dos agendamentos.',
  TOTAL_COMMISSION_DESC: 'Somatória do valor de todas comissões.',
  SALES: 'Vendas',
  COMMISSIONS: 'Comissões',

  // COMMISSIONS REPORT DETAILS SCREEN
  COMMISSION_REPORT_DETAILS_TITLE: 'Relatório detalhado',
  GROSS_VALUE: 'Total em Vendas (Bruto)',
  GROSS_VALUE_DESC: 'Valor total bruto dos agendamentos pagos neste mês.',
  NET_VALUE: 'Total em Vendas (Líquido)',
  NET_VALUE_DESC: 'Valor total dos agendamentos pagos neste mês, descontando as taxas e encargos.',
  COMMISSIONS_TOTAL: 'Total em Comissões',
  COMMISSIONS_TOTAL_DESC: 'Valor total da comissão paga neste mês.',

  // YUP
  PERCENTAGE_REQUIRED: 'Percentual é obrigatório',
  DUE_IN_DAYS_REQUIRED: 'Dias para recebimento é obrigatório',

  TITLE: 'Título deve ter ao menos 3 caracteres',
  TITLE_REQUIRED: 'Título é obrigatório',
  DESCRIPTION: 'Descrição deve conter mais de 10 caracteres',
  DESCRIPTION_REQUIRED: 'Descrição é obrigatória',

  INCOMPLETE_CEP: 'CEP incompleto',
  CEP_REQUIRED: 'CEP é obrigatório',
  STATE_REQUIRED: 'Estado é obrigatório',
  CITY_REQUIRED: 'Cidade é obrigatório',
  STREET_REQUIRED: 'Endereço é obrigatório',
  STREET_NUMBER_REQUIRED: 'Número é obrigatório',
  INVALID_CPF: 'CPF ou CNPJ inválido!',
  CPF_REQUIRED: 'CPF ou CNPJ é obrigatório!',

  SERVICE: 'Serviço deve ter ao menos 4 caracteres',
  SERVICE_REQUIRED: 'Serviço é obrigatório',
  TIME_REQUIRED: 'Tempo é obrigatório',
  INVALID_TIME: 'Duração de tempo inválida',
  PRICE_REQUIRED: 'Valor do serviço é obrigatório',

  INVALID_EMAIL: 'E-mail inválido',
  EMAIL_REQUIRED: 'E-mail é obrigatório!',
  INVALID_CELLPHONE: 'Celular inválido',
  CELLPHONE_REQUIRED: 'Celular é obrigatório!',
  NAME: 'Nome deve ter ao menos 3 caracteres',
  NAME_REQUIRED: 'Nome é obrigatório!',
  INVALID_LAST_NAME: 'Sobrenome deve ter ao menos 3 caracteres',
  LAST_NAME_REQUIRED: 'Sobrenome é obrigatório',
  PASSWORD: 'Senha deve ter ao menos 6 caracteres',
  PASSWORD_REQUIRED: 'Senha é obrigatório',
  CONFIRM_PASSWORD: 'Senhas devem ser iguais',

  DUE_DATE_REQUIRED: 'Data de Vencimento é obrigatório',
  VALUE_REQUIRED: 'Valor do lançamento é obrigátorio',
  CATEGORY_REQUIRED: 'Categoria é obrigatório',

  INVALID_CARD: 'Cartão inválido',
  CARD_REQUIRED: 'Numero do cartão é obrigatório',
  INVALID_VERIFICATION_CODE: 'Código de verificação inválido',
  VERIFICATION_CODE_REQUIRED: 'Código de verificação é obrigatório',
  INVALID_EXPIRATION_MONTH: 'Mês de Validade inválido!',
  EXPIRATION_MONTH_REQUIRED: 'Mês de Validade é obrigatório',
  INVALID_EXPIRATION_YEAR: 'Ano de Validade inválido',
  EXPIRATION_YEAR_REQUIRED: 'Ano de Validade é obrigatório',

  // TOASTS

  // success
  UPDATED_DATA: 'Dados atualizados com sucesso',

  // error
  REVIEW_INPUTS: 'Por favor, revise os dados inseridos',

  // DAYS OF THE WEEK
  MONDAY: 'Seg',
  MONDAY_LETTER: 'S',
  MONDAY_DESC: 'Segunda-feira',

  TUESDAY: 'Ter',
  TUESDAY_LETTER: 'T',
  TUESDAY_DESC: 'Terça-feira',

  WEDNESDAY: 'Qua',
  WEDNESDAY_DESC: 'Quarta-feira',
  WEDNESDAY_LETTER: 'Q',

  THURSDAY: 'Qui',
  THURSDAY_DESC: 'Quinta-feira',
  THURSDAY_LETTER: 'Q',

  FRIDAY: 'Sex',
  FRIDAY_DESC: 'Sexta-feira',
  FRIDAY_LETTER: 'S',

  SATURDAY: 'Sáb',
  SATURDAY_DESC: 'Sábado',
  SATURDAY_LETTER: 'S',

  SUNDAY: 'Dom',
  SUNDAY_DESC: 'Domingo',
  SUNDAY_LETTER: 'D',

  MONDAY_DESC_DATE: 'Toda segunda-feira',

  TUESDAY_DESC_DATE: 'Toda terça-feira',

  WEDNESDAY_DESC_DATE: 'Toda quarta-feira',

  THURSDAY_DESC_DATE: 'Toda quinta-feira',

  FRIDAY_DESC_DATE: 'Toda sexta-feira',

  SATURDAY_DESC_DATE: 'Todo sábado',

  SUNDAY_DESC_DATE: 'Todo domingo',

  // CURRENCY
  CURRENCY: 'R$',

  // HOME (FreeTrialModal)
  FREE_TRIAL_MODAL_TITLE_FIRST_PART: 'Ganhe ',
  FREE_TRIAL_MODAL_TITLE_SPAN: 'uma semana ',
  FREE_TRIAL_MODAL_TITLE_SECOND_PART: 'de teste por nossa conta!',
  FREE_TRIAL_MODAL_SUBTITLE:
    'Tenha acesso a todas as funcionalidades exclusivas e melhore a experiência de atendimento e gestão da sua barbearia!',
  FREE_TRIAL_PERIOD: 'Primeiros 7 dias gratuitos',
  AFTER_FREE_TRIAL: 'Após este período ',
  AFTER_FREE_TRIAL_SPAN: 'R$ 69,90/mês',
  FREE_TRIAL_MODAL_BUTTON: 'Começar meu teste grátis',
  MEMBERSHIP_BENEFITS: 'Veja os benefícios da assinatura',
  MEMBERSHIP_BENEFIT_1: 'Gestão de agendamentos',
  MEMBERSHIP_BENEFIT_2: 'Número ilimitado de barbeiros',
  MEMBERSHIP_BENEFIT_3: 'Criação de serviços personalizados',
  MEMBERSHIP_BENEFIT_4: 'Controle financeiro automatizado',
  MEMBERSHIP_BENEFIT_5: 'Gestão de clientes',
  MEMBERSHIP_BENEFIT_6: 'Acesso exclusivo para cada barbeiro',
  MEMBERSHIP_BENEFIT_7: 'Notificação de agendamentos',
  START_FREE_TRIAL: 'Começar meu teste grátis',
  AUTOMATIC_RENOVATION: 'Renovação automática. Cancele quando quiser.',

  // HOME (ConfirmFreeTrialModal)
  CONFIRM_FREE_TRIAL_MODAL_TITLE: 'Seu teste grátis começou!',
  CONFIRM_FREE_TRIAL_MODAL_SUBTITLE:
    'Nós enviamos um email com informações de sua assinatura e cobrança para o email: ',
  ATENTION: 'Atenção',
  REMEMBER_TO_PAY:
    'Lembre-se de realizar o pagamento da assinatura após o período de testes para não perder o acesso as funcionalidades!',
  OPEN_BILLING_EMAIL: 'Abra o email de cobrança',
  WE_WILL_SEND_EMAIL: 'Enviaremos um email com informações sobre sua assinatura e um link para pagamento da mesma.',
  PAY_MEMBERSHIP: 'Realize o pagamento da assinatura',
  OPEN_THE_LINK:
    'Acesse o link dentro do email e preencha os seus dados de pagamento. Pode ficar tranquilo, é seguro! ',
  ALRIGHT: 'Tudo certo!',
  USE_THE_APP: 'Caso tudo ocorra bem com seu pagamento, você não precisará fazer mais nada. Agora é só utilizar o app!',

  // ExpiredFreeTrialModal
  EXPIRED_FREE_TRIAL_MODAL_TITLE: 'Seu período de teste grátis chegou ao fim.',
  EXPIRED_FREE_TRIAL_MODAL_SUBTITLE:
    'Assine para continuar usufruindo de todos os recursos e facilidades para sua barbearia.',
  SUBSCRIBE_PRICE: 'Assine por R$ 69,00/mês',

  //HOME (subscription banner)
  TRIAL_ENDING: 'Restam apenas ',
  TRIAL_ENDING_2: ' dias de Teste Grátis',
  TRIAL_ENDING_SUBTITLE: 'Assine o Premium e tenha acesso a ferramentas exclusivas no app.',
  TRIAL_ENDED: 'Sua barbearia pode muito mais!',
  TRIAL_ENDED_SUBTITLE: 'Assine para continuar usufruindo de todos os recursos e facilidades para sua barbearia.',
  SUBSCRIPTION_SUSPENDED: 'Sua assinatura foi suspensa',
  SUBSCRIPTION_SUSPENDED_SUBTITLE: 'Para ter acesso novamente, entre em contato com nosso suporte clicando aqui.',
  SUBSCRIPTION_PENDING_FEE: 'Sua assinatura está com problemas!',
  SUBSCRIPTION_LINK: 'Existe um problema com sua assinatura. Para corrigi-lo, clique no link abaixo:',
  REDIRECT: 'Redirecionar',
  PENDING_BILL: 'Fatura pendente',
  BILL_LINK: 'Sua fatura já está disponível para o pagamento. Para completá-lo, clique no link abaixo:',

  //HOME (stat card)
  INCOME: 'Faturamento',
  MONTHLY_NEW_CLIENTS: 'Novos clientes',
  THIS_MONTH: 'Neste mês',

  SERVICES_DETAILS: 'Detalhamento de serviços',

  //HOME (Month appointments chart)
  DAILY_INCOMING_CHART: 'Gráfico de Receitas por Dia',
  INCOMING: 'Receitas',

  //HOME (Month appointments products)
  SERVICES_REPORT: 'Detalhamento de serviços',
  INCOME_TABLE: 'Receita',
  QUANTITY: 'QTE',

  //APPOINTMENTS
  APPOINTMENTS: 'Agendamentos',
  NEW_APPOINTMENTS: 'Novo agendamento',
  APPOINTMENT_ERROR: 'Houve um erro ao carregar os agendamentos!',
  APPOINTMENT_ERROR_HOUR: 'Horário não disponível',
  BARBERSHOP_CANNOT_SCHEDULE: 'Sua barbearia não pode realizar agendamentos',
  VERIFY_STATUS_TRY_AGAIN: 'Verifique o status e tente novamente.',
  SELECT_DATE: 'Selecionar data',
  NEW_APPOINTMENT: 'Novo agendamento',
  SELECT_CUSTOMER: 'Selecionar Cliente',
  SELECT_BARBER: 'Selecionar Barbeiro',
  SELECT_SERVICE: 'Selecionar Serviço',
  SELECT_DATE_TIME: 'Selecionar Data e Horário',

  //APPOINTMENT MODAL
  CUSTOMER: 'Cliente',
  APPOINTMENT_TYPE: 'Tipo de agendamento',
  STANDARD: 'Padrão',
  FIT: 'Encaixe',
  BARBER: 'Barbeiro',
  SELECT_SOURCE: 'Fonte de agendamento',

  DATE_TIME: 'Data e Horário',
  CONFIRM: 'Confirmar',
  APPOINTMENT_CREATED_SUCCESSFULLY: 'Agendamento criado com sucesso!',
  TIME_START_END: 'Horário de Início  e Fim são obrigatórios.',
  START_TIME_UNDER_END: 'Horário de Início deve ser menor do que o horário de Fim.',

  MORNING: 'Manhã',
  EVENING: 'Tarde',
  NIGHT: 'Noite',

  DATE: 'Data',

  AVAILABLE_TIME: 'Horários Disponíveis',
  START_TIME: 'Horário de início',
  END_TIME: 'Horário de fim',

  AVAILABLE_STEPS: 'Para mostrar os horários disponíveis, selecione a Data, Serviço e Barbeiro.',
  NO_AVAILABLE_TIME: 'Nenhum horário disponível no período',

  NAME_SIZE: 'Nome deve conter no minímo 3 caracteres',

  PHONE_REQUIRED: 'Telefone é obrigatório',

  CUSTOMER_REGISTERED: 'Cliente cadastrado',
  CUSTOMER_NOT_REGISTERED: 'Cliente sem cadastro',
  SEARCH_CUSTOMER: 'Buscar cliente',
  CUSTOMER_NAME: 'Nome do cliente',
  PHONE_NUMBER: 'Telefone celular',
  ADD_CUSTOMER: 'Adicionar Cliente',

  //APPOINTMENT DETAIL MODAL
  APPOINTMENT_DETAILS: 'Detalhes da comanda',
  SELECT_PAYMENT_METHOD: 'Selecionar meio de pagamento',

  FINISHED_APPOINTMENT: 'Agendamento finalizado com sucesso!',
  FINISH_APPOINTMENT_ERROR: 'Houve um erro ao finalizar o agendamento!',
  CANCELED_APPOINTMENT: 'Agendamento cancelado com sucesso!',
  CANCEL_APPOINTMENT_ERROR: 'Houve um erro ao cancelar o agendamento!',

  PRODUCT_NAME_SIZE: 'Nome do produto deve conter no minímo 3 caracteres',
  PRODUCT_NAME_REQUIRED: 'Nome do produto é obrigatório',
  PRODUCT_PRICE_REQUIRED: 'Valor do produto é obrigatório',
  PRODUCT_NAME: 'Nome do produto',
  ADD_PRODUCT: 'Adicionar Produto',
  ADD_PRODUCTS: 'Adicionar produtos',
  DISCOUNT: 'Desconto',
  ADD_DISCOUNT: 'Adicionar Desconto',
  DISCOUNT_DESCRIPTION: 'Descrição do desconto',
  VALUES: 'Valores',

  SUBMIT: 'Finalizar',

  EXCLUDE_APPOINTMENT_CUSTOMER: 'Deseja excluir o agendamento com o cliente "',
  EXCLUDE_APPOINTMENT: 'Excluir Agendamento',

  OPTIONAL_EMAIL: 'E-mail (opicional)',
  ERROR_LOADING_TIMES: 'Houve um erro ao carregar os horários!',

  RATING: 'AVALIAÇÃO',
  LENGTH: 'TEMPO',

  // RATINGS SCREEN
  RATINGS: 'Avaliações',

  // BARBERSHOP RATING
  AVERAGE_BARBERSHOP_RATING: 'Avaliação média da barbearia',
  BASED_IN_30_DAYS: 'Baseado nos últimos 30 dias',
  RECEIVED_RATINGS_TOTAL: 'Total de avaliações recebidas',

  // SERVICES SCREEN
  LOAD_SERVICES_ERROR: 'Falha ao carregar os serviços',
  SERVICES_TITLE: 'Serviços',
  ADD_SERVICE_BUTTON: 'Adicionar Serviço',
  SELECT: 'Selecionar',
  SERVICES_BLOCK_MESSAGE_HEADER: 'Limite a 4 na versão gratuita',
  SERVICES_BLOCK_MESSAGE: 'Adicione quantos serviços você quiser na versão premium!',

  // SERVICES MODAL
  SERVICE_CREATED_SUCCESS: 'Serviço criado/atualizado com sucesso!',
  ADD: 'Adicionar',
  EDIT_SERVICE: 'Editar',
  _SERVICE: 'Serviço',
  MAKE_PROMOTIONAL: 'Colocar em promoção',
  IN_PROMOTION_DAYS: 'Dias em Promoção',
  DELETE_SERVICE: 'Excluir Serviço',
  DELETE_SERVICE_CONFIRM: 'Deseja excluir o serviço ',
  SERVICE_UPDATED: 'Serviço atualizado com sucesso!',
  SERVICE_ADDED: 'Serviço adicionado com sucesso!',
  ERROR_SAVING_SERVICE: 'Falha ao salvar serviço',
  SERVICE_DELETED: 'Serviço excluído com sucesso!',
  ERROR_EXCLUDING_SERVICE: 'Falha ao excluir serviço!',

  // TEAM SCREEN
  DELETE_BARBER_SUCCESS: 'Barbeiro deletado com sucesso',
  DELETE_BARBER_ERROR: 'Erro ao deletar barbeiro',
  TEAM_TITLE: 'Equipe',
  ADD_TEAM: 'Adicionar Equipe',
  SEARCH_BARBER: 'Pesquisar por colaborador',
  DELETE_BARBER: 'Excluir colaborador',
  DELETE_BARBER_CONFIRM: 'Tem certeza que deseja excluir ',
  TEAM_BLOCK_MESSAGE_HEADER: 'Limite a 2 barbeiros na versão gratuita',
  TEAM_BLOCK_MESSAGE: 'Adicione quantos barbeiros você quiser na versão premium!',
  ADD_OTHER_FUNCTIONS: 'Adicione outras funções na versão premium!',

  // BARBER CARD
  BARBER_PHONE: 'Telefone',

  //TEAM MODAL
  ADD_BARBER_SUCCESS: 'Barbeiro adicionado com sucesso!',
  ADD_BARBER_ERROR: 'Falha ao adicionar barbeiro',
  ADD_BARBER: 'Adicionar Colaborador',
  BARBER_NAME_PLACEHOLDER: 'Nome do barbeiro',
  PHONE_PLACEHOLDER: 'Telefone celular',
  BARBER_LIMIT: 'Você não pode adicionar mais barbeiros. Assine o plano para ter acesso a mais funcionalidades.',

  // EDIT TEAM
  EDIT_TEAM: 'Editar Colaborador',
  EDIT_TEAM_SUCCESS: 'Colaborador editado com sucesso!',
  EDIT_TEAM_ERROR: 'Houve um erro ao editar colaborador.',

  // BARBER MODAL
  EDIT_COMMISSION_ERROR: 'Falha ao editar comissão do barbeiro',
  EDIT_COMMISSION_SUCCESS: 'Comissão alterada com sucesso',
  RATINGS_AVERAGE: 'Média de avaliações',
  BARBER_COMMISSION_SERVICE: 'Comissão serviços',
  BARBER_COMMISSION_SERVICE_: 'COMISSÃO SERVIÇOS',
  BARBER_COMMISSION_PRODUCT: 'Comissão produtos',
  BARBER_COMMISSION_PRODUCT_: 'COMISSÃO PRODUTOS',
  BARBER_COMMISSION_SERVICE_TABLE: '(%) Serviços',
  BARBER_COMMISSION_PRODUCT_TABLE: '(%) Produtos',
  SEE_ALL_MESSAGES: 'Ver todas',

  // ACTION MODAL
  DELETING: 'Deletando',

  // EDIT APPOINTMENT
  EDIT_APPOINTMENT: 'Editar Agendamento',
  EDIT_APPOINTMENT_SUCCESS: 'Agendamento alterado com sucesso!',
  EDIT_APPOINTMENT_ERROR: 'Houve um erro ao alterar agendamento!',
  //MENU (Status Modal)
  BARBERSHOP_STATUS: 'Status da Barbearia alterado!',
  BARBERSHOP_STATUS_ERROR: 'Erro. Status não alterado.',
  SET_STATUS: 'Definir status',
  CHOOSE_STATUS: 'Escolha entre as opções abaixo qual o status da sua barbearia',
  ACTIVE: 'Ativa',
  ACTIVE_DESC: 'Sua barbearia ficará disponível para realizar agendamentos e os clientes poderão encontrá-la no app.',
  PRIVATE: 'Privada',
  PRIVATE_DESC:
    'Sua barbearia ficará disponível para realizar agendamentos mas os clientes NÃO poderão encontrá-la no app.',
  PENDING: 'Pendente',
  PENDING_DESC: 'Sua barbearia está despublicada no sistema. Você tem os pré requisitos necessárias para publicá-la.',
  NOT_PUBLISHED: 'Inativa',
  NOT_PUBLISHED_DESC: 'Sua barbearia NÃO poderá realizar agendamentos e os clientes NÃO poderão encontrá-la no app.',
  BLOCKED: 'Bloqueada',
  BLOCKED_DESC:
    'Existe uma pendência na sua assinatura do BestBarbers. Por favor, acesse nossa central de assinaturas.',

  //MENU (Menu itens)
  SCHEDULES: 'Horários',
  AGENDA: 'Agenda',
  HISTORIC: 'Histórico',
  FINANCES: 'Finanças',
  PAYMENTS: 'Pagamentos',
  REPORTS: 'Relatórios',
  ADM: 'Administrativo',
  CLIENTS: 'Clientes',
  PROFILE: 'Perfil',
  //BARBERS SCREEN
  BARBERS_REPORT: 'Relatório de Barbeiros',
  FINISHED_APPOINTMENTS_COUNT: 'Agendamentos finalizados',
  FINISHED_APPOINTMENTS_COUNT_DESC: 'Quantidade de agendamentos que já foram finalizados.',

  //PERFIL (profile menu)
  //PROFILE (profile menu)
  MAIN_DATA: 'Dados principais',
  LOGIN_DATA: 'Dados de acesso',
  BARBERSHOP_LINK: 'Link da barbearia',
  BLOCKED_TIME: 'Horários bloqueados',
  PASSWORD_PROFILE: 'Senha',
  EXIT: 'Sair',

  //PROFILE (Dados principais)
  CHANGE_LOGO_BUTTON: 'Alterar logo',
  BARBERSHOP_DESC_PLACEHOLDER: 'Descreva aqui seus serviços, diferenciais...',
  MAIN_PHONE: 'Telefone principal',
  SAVE_CHANGES: 'Salvar alterações',
  CPF: 'CPF',
  CNPJ: 'CNPJ',

  //PROFILE (Share link)
  UPDATED_LINK: 'Link atualizado com sucesso',
  COPIED_LINK: 'Link copidado',
  LINK_DESC:
    'O link é uma forma fácil de você compartilhar com seus clientes o perfil da sua barbearia e eles conseguirem fazer o agendamento com sua barbearia de uma forma prática e simples.',
  LINK_NOTE:
    'Obs: O Link não pode conter acentos, caracteres especiais ou espaços em branco. Ele só estará disponível caso sua barberia esteja com o status "Ativa".',
  COPY_LINK: 'Copiar link',

  //PROFILE (Location)
  ADDRESS_ERROR: 'Falha ao carregar o endereço.',

  //PROFILE (Working hours)
  WORKING_HOURS_ERROR: 'Falha ao carregar os horários de funcionamento',
  WEEK_DAY: 'Dia da semana',
  REPEAT_TIME: 'Repetir este horário para outros dias da semana',

  //PROFILE (photos/action modal)
  EXCLUDING: 'Excluindo',
  SENDING: 'Enviando',
  EXCLUDE_PHOTO: 'Excluir foto',
  EXCLUDE_PHOTO_CONFIRMATION: 'Tem certeza que deseja excluir essa foto?',

  //PROFILE (Blocked times)
  BLOCKED_TIME_TOAST: 'Horário bloqueado com sucesso.',
  UNBLOCKED_TIME_TOAST: 'Horário desbloqueado com sucesso.',
  OPTIONS: 'Opções',
  REPEAT_TIME_EVERYDAY: 'Repetir todos os dias',
  ADD_TIME: 'Adicionar horário.',
  BLOCK_TIME_CONFIRMATION: 'Deseja deletar esse horário bloqueado?',
  YES: 'Sim',
  NO: 'Não',

  //PROFILE (Plan/plan invoice card)
  MY_PLAN: 'Meu plano',
  CURRENT_PLAN: 'Plano atual:',
  SING_UP_DATE: 'Data de assinatura:',
  LAST_PAYMENTS: 'Últimos pagamentos:',
  PAYMENT_NOT_FOUND: 'Nenhum pagamento encontrado',
  VIEW: 'Visualizar',
  EXPIRED: 'Expirou',

  //PROFILE (Change password)
  ACTUAL_PASSWORD: 'Senha atual',
  NEW_PROFILE_PASSWORD: 'Nova senha',
  ACTUAL_PASSWORD_REQUIRED: 'Senha atual é obrigatória',
  NEW_PASSWORD_REQUIRED: 'Nova senha é obrigatória',
  NEW_PASSWORD_LENGTH: 'Nova senha deve conter ao menos 6 caracteres',
  CHANGE_PASSWORD_SUCCESS: 'Senha atualizada com sucesso',
  CHANGE_PASSWORD_ERROR: 'Erro ao atualizar senha',

  //PROFILE (Logout modal)
  EXIT_CONFIRMATION: 'Deseja realmente sair?',
  BARBERSHOP_PROFILE: 'Perfil da barbearia',
  IMAGE: 'Imagem',

  //Profile -> Blocked times
  EVERYBODY: 'Todos',
  EVERYDAY: 'Todos os dias',
  EVERY: 'Toda',

  PAID: 'Pago',
  CANCELED: 'Cancelado',
  PARTIALLY_PAID: 'Pago parcialmente',
  // REFUNDED: 'Devolvido',
  AUTHORIZED: 'Autorizado',
  IN_PROTEST: 'Em protesto',
  NO_STATUS: 'Sem status',

  // ClientDetails
  INFORMATIONS: 'Informações',
  RECENT_OBSERVATIONS: 'Últimas observações',
  SEE_ALL: 'Ver todas',
  NO_OBSERVATIONS: 'Nenhuma observação foi feita',
  NEXT_APPOINTMENT: 'Próximo agendamento',
  NO_APPOINTMENT: 'Nenhum agendamento marcado para este cliente.',
  OBSERVATIONS: 'Observações',
  OBSERVATION_SAVE_ERROR: 'Erro ao salvar observação.',
  DESCRIPTION_PLACEHOLDER: 'Descrição',
  CUSTOMER_STATISTICS: 'Estatísticas do cliente',
  FINISHED: 'Finalizados',
  AVERAGE_TICKET: 'Ticket médio',
  TOTAL_PROFIT: 'Total recebido',
  DELETE_OBSERVATION: 'Excluir observação',
  DELETE_OBSERVATION_DESC: 'Deseja excluir essa observação?',
  DELETE_OBSERVATION_SUCCESS: 'Observação excluída com sucesso!',
  DELETE_OBSERVATION_ERROR: 'Falha ao excluir observação.',
  CASHIER: 'Caixa',

  // PREMIUM FEATURES
  BLOCKED_FEATURE:
    'Esta funcionalidade é exclusiva para nossos assinantes. Clique no botão abaixo para adquirir sua assinatura e obter acesso a esta e muitas outras funcionalidades.',
  SIGN_NOW: 'Quero ser premium!',
  HOME_BLOCKED: 'Relatório do mês é exclusivo para assinantes.',
  LINK_BLOCKED: 'Link personalizado de agendamento é exclusivo para assinantes.',
  BARBERS_REPORT_BLOCKED: 'Relatório de barbeiros é exclusivo para assinantes.',
  COMMISSIONS_REPORT_BLOCKED: 'Relatório de comissões é exclusivo para assinantes.',
  BLOCKED_FEATURE_TITLE: '{{section}} é exclusivo para assinantes.',

  //SOURCES
  CLIENT_MOBILE_APP: 'Agendado pelo Cliente no App',
  BARBER_MOBILE_APP: 'Agendado pelo Barbeiro - no App',
  BARBERSHOP_MOBILE_APP: 'Agendado pela Barbearia no App',
  BARBERSHOP_MOBILE_APP_WPP: 'Agendado pela Barbearia - WhatsApp',
  BARBERSHOP_MOBILE_APP_PRESENTIAL: 'Agendado pela Barbearia - Presencial',
  BARBERSHOP_MOBILE_APP_PHONE_CALL: 'Solicitou pelo telefone (ligação)',

  //ADDITIONAL PRODUCTS
  ERROR_LOADING_PRODUCTS: 'Erro ao carregar produtos adicionais',
  SUCCESS_PRODUCTS_CREATED: 'Produto criado com sucesso',
  ERROR_CREATE_PRODUCTS: 'Erro ao criar produto',
  SUCCESS_PRODUCTS_DELETE: 'Produto deletado com sucesso',
  ERROR_DELETE_PRODUCTS: 'Erro ao deletar produto',
  SUCCESS_PRODUCTS_UPDATED: 'Produto alterado com sucesso',
  ERROR_UPDATE_PRODUCTS: 'Erro ao alterar produto',
  ADD_PRODUCT_BUTTON: 'Adicionar produto',
  CONFIRM_DELETE_PRODUCT: 'Deseja excluir o produto',
  ERROR_REMOVE_PRODUCT_APPOINTMENT: 'Erro ao remover produto',
  ADDITIONAL_PRODUCTS_PREMIUM_MESSAGE: 'Produtos é exclusivo para assinantes Premium',

  // FINANCES - v2
  TOTAL_REVENUE_TITLE: 'Receita bruta',
  TOTAL_REVENUE_SUBTITLE: 'Valor faturado até o momento',
  TOTAL_PREDICTION_SUBTITLE: 'Previsão de faturamento',
  // EXPENSES_TITLE: 'Despesas',
  EXPENSES_SUBTITLE: 'Lançadas',
  BALANCE: 'Balanço',
  MONTH: 'Mês',
  TOTAL: 'Total',
  BARBERS_COMMISSION: 'Total de Comissões',
  TOTAL_CLIENTS: 'Total de clientes',
  NEW_CLIENTS: 'Novos clientes',
  ATTENDED: 'Atendidos',
  AWAITING: 'Aguardando',
  OUTSTANDING: 'Pendentes',
  FINALIZED: 'Finalizados',
  SALES_PRODUCT: 'Vendas de produtos',
  SALES_SERVICE: 'Vendas de serviços',
  SALES_PRODUCT_TABLE: 'Vendas - produtos',
  SALES_SERVICE_TABLE: 'Vendas - serviços',

  BALANCE_MONTH: 'Saldo do mês',
  RELEASES: 'Lançamentos',
  REVENUES: 'Receitas lançadas',
  EXPENSES_TITLE: 'Despesas lançadas',
  PREDITICTION: 'Previsão de saldo do mês',
  PREDITICTION_SUBTITLE: 'Valor líquido aproximado',
  PREVISION: 'Previsão',
  GROSS_REVENUE: 'Faturamento bruto',
  APPOINTMENT: 'Agendamento',
  RECEIVED_UNTIL_NOW: 'Recebido até o momento',
  DISCOUNTS_UNTIL_NOW: 'Descontos até o momento',
  NO_DATA_RESUME: 'Nenhum dado a ser mostrado no mês selecionado',
  EXTRACT: 'Extrato',
  TOTAL_SALES: 'Total de vendas',
  TOTAL_COMMISSION: 'Valor total do período',
  APPOINTMENTS_BY_DAY: 'Agendamentos por dia',
  _REVENUES: 'Receita',
  _EXPENSES: 'Despesas',
  SELL_SERVICE: 'Venda de serviços',
  FILTER: 'Filtros',
  APPLY: 'Aplicar',
  NOT_INFORMED: 'Não informado',
  START: 'Início',
  END: 'Fim',
  PAYMENT: 'Pagamento',
  IN_TOTAL: 'clientes no total',
  CLIENT_CREATE_APPOINTMENT: 'Selecione o cliente na aba de clientes cadastrados',

  BF_BANNER_1: 'O melhor investimento para sua barbearia',
  BF_BANNER_2: 'com um desconto imperdível',
  FOR_MONTH: '/mês',
  FOR_ONLY: 'Por apenas',
  DURING_MONTH: 'durante 3 meses ou',
  ANUAL_PLAN: 'no plano anual (R$ 598,80)',
  BUT_HURRY: 'Mas corra, é por tempo limitado!',
  OFFER_UNTIL: 'Oferta disponível até 30/11/2022',
  SUBSCRIBE_NOW: 'Assinar agora',
  LEARN_MORE: 'Saiba mais',

  MODAL_1: 'Ganhe uma semana de teste por nossa conta!',
  GO_BACK: 'Voltar',
  STATUS_APPOINTMENT: 'Status do agendamento: ',
  AWAITING_: 'Aguardando',
  PENDING_: 'Pendente',
  FINISHED_: 'Finalizado',
  NO_DISCOUNT: 'Nenhum desconto adicionado',
  NO_PRODUCT: 'Nenhum produto adicionado',
  PAYMENT_FORM: 'Forma de pagamento',
  PAYMENT_DATE_DESC: 'Você irá receber este pagamento no dia ',
  PAYMENT_DONE: 'Pagamento recebido no dia ',
  PAYMENT_PENDING: 'Pagamento pendente',
  SUBTOTAL: 'Subtotal',
  SOURCE_FORM: 'Forma de agendamento não informada',
  PROFESSIONAL: 'Profissional',
  ADD_SOURCE: 'Adicionar fonte de agendamento',
  PERCENTAGE_PROD: 'Venda de produto - barbeiro: ',
  PERCENTAGE_SERV: 'Serviço do barbeiro: ',
  PERCENTAGE_PAY: 'Método do pagamento: ',
  PRODUCTS_COMISSION: 'Comissão de produtos',
  SERVICE_COMISSION: 'Comissão de serviços',
  BARBER_PROFILE: 'Barbeiro adicionado',

  TOTAL_ENTRIES: 'Total de entradas (receitas)',
  UNTIL_MOMENT: 'Até o momento',
  TOTAL_EXITS: 'Total de saídas (despesas)',
  PER_DAY: 'Por dia',
  CLIENT_AVERAGE: 'Média de clientes',
  DISCOUNTS: 'Descontos',
  OTHER_DISCOUNTS: 'Outros descontos oferecidos',
  LIQUID_VALUE: 'Valor líquido (Receitas - Despesas)',
  LIQUID_VALUE_MOBILE: 'Valor líquido (Receitas - Despesas)',

  APPOINTMENTS_MONTH: 'agendamentos nesse mês',
  NEXT_APPOINTMENTS: 'Próximos agendamentos de hoje:',
  NO_APPOINTMENT_TODAY: 'Nenhum agendamento próximo',
  SEE_APPOINTMENTS: 'Ver todos os agendamentos',
  SELECT_PRODUCTS: 'Selecionar produtos',
  BLOCKED_TIMES: 'Horário bloqueado',

  NOTIFICATIONS: 'Notificações',
  NOTIFICATIONS_DESC: 'Defina suas preferências quanto ao envio de notificação de e-mail e push',
  SEND_EMAIL: 'Seus clientes e barbeiros receberão notificações por e-mail',
  SEND_PUSH: 'Seus clientes e barbeiros receberão notificações por push no celular',
  HIDE_BLOCKED_TIMES: 'Exibir horários bloqueados',

  CONTENTS: 'Conteúdos',
  CONTENTS_SUBTITLE:
    'Fique sempre por dentro dos nossos conteúdos! Preparamos essa seção para nos você assistir onde e quando quiser.',
  EPISODES: 'Episódios',
  OTHER_EPISODES: 'Outros episódios',

  FUNCTION: 'Função',
  //Must stay in lower case to translate from db
  recepcionist: 'Recepcionista',
  manager: 'Gerente',
  collector: 'Caixa',
  finance: 'Financeiro',
  barber: 'Barbeiro',
  barbershopAndBarber: 'Barbeiro',
  ownerAndBarber: 'Barbeiro',
  ERROR_UPDATE_DATA: 'Erro ao atualizar dados',
  ERROR_DELETE_DATA: 'Erro ao deletar dado',
  DELETE_BLOCKED_HOUR: 'Deletar horário bloqueado',
  REPEAT_NEW_PASSWORD: 'Repita nova senha',
  COMPLEMENT: 'Complemento',

  ADD_BLOCK_TIMES: 'Adicionar horário bloqueado',
  ADD_PLUS: '+ Adicionar',
  BLOCKED_DATE: 'Data bloqueada',
  PERIOD: 'Período',
  DAY: 'Dia',
  DELETE_CONFIRMATION: 'Deseja deletar sua conta?',
  DELETE_ACCOUNT: 'Deletar conta',
  DELETE_ACCOUNT_SUCCESS: 'Conta deletada com sucesso',
  DELETE_ACCOUNT_ERROR: 'Erro ao deletar conta',

  MODAL_REFRESH_DATA_TITLE: 'Mantenha seus dados atualizados!',
  MODAL_REFRESH_DATA_SUBTITLE:
    'Para que sua barbearia ofereça um serviço de qualidade para os seus clientes, é fundamental que você mantenha seus dados sempre atualizados',
  MODAL_REFRESH_DATA_BUTTON: 'Continuar',
  ADD_BARBER_REGISTER: 'Adicionar barbeiro',
  PERCENTAGE_SERVICES: '(%) Serviços',
  PAYMENT_METHOD_REPORT: 'Detalhamento de pagamentos',
  CLIENTS_REPORT: 'Detalhamento de clientes',
  LAST_APPOINTMENT: 'ÚLTIMO ATENDIMENTO',
  TOTAL_PRODUCTS: 'Valor total produtos',
  TOTAL_SERVICES: 'Valor total serviços',
  MORE_SERVICE: 'Serviço + utilizado',
  SALES_PRODUCT_CLIENT: 'Vendas de produtos (finalizados)',
  SALES_SERVICE_CLIENT: 'Vendas de serviços (finalizados)',
  CLIENT: 'Cliente',
  TOTAL_DISCOUNTS_TABLE: 'TOTAL EM DESCONTOS',
  COMISSIONS_REPORT: 'Detalhamento de comissões',
  APPOINTMENTS_1: 'AGENDA-',
  APPOINTMENTS_2: 'MENTOS',
  HAVE: 'Há ',
  TOTAL_DISCOUNTS: 'Descontos oferecidos',
  CLIENT_UNTIL: 'Cliente desde ',
  product: 'Produto',
  BILLING_PERCENTAGE: '(%) FATURAMENTO',
  SELLS_REPORT: 'Detalhamento de vendas',
  SEE_MORE: 'Ver mais',
  PRODUCTS: 'Produtos',
  RESPONSABLED_NAME: 'Nome do responsável',
  RECURRENT: 'Recorrente?',
  SELLS: 'Vendas',

  TERMS_AND_POLICYS_1: 'Ao se cadastrar na plataforma, você está de acordo com',
  TERMS_AND_POLICYS_2: 'os',
  AND: 'e ',
  CELLPHONE: 'Celular',
  BARBERSHOP_NAME_PLACEHOLDER: 'Ex: Barbearia Best Barbers',
  OWNER_NAME_PLACEHOLDER: 'Ex: Antônio José',
  FREE_TRIAL_MODAL_TITLE_FIRST_PART_NEW: 'Você ganhou',
  BASIC: 'Básico',

  PAYMENT_MODAL_TITLE: 'Escolha a forma de pagamento que mais se encaixa com você',
  PIX: 'Pix',
  CREDIT: 'Crédito',
  TICKET: 'Boleto',
  PAYMENT_MODAL_SUBTITLE_1: 'Cadastre seu cartão',
  NOW: 'agora',
  AND_WIN: 'e ganhe',
  DISCOUNT_10_PERCENT: '15 dias gratuitos',
  CPF_OR_CNPJ: 'CPF ou CNPJ do titular',
  NUMBER_CARD: 'Número do cartão',
  COMPLETE_NAME_CARD: 'Nome completo (escrito no cartão)',
  CVV: 'CVV',
  EXPIRE_AT: 'Vencimento',
  CREDIT_CARD_ADVISE: 'Pode ser cobrado um valor de até R$ 1,00 para validação do seu cartão de crédito',
  ADD_CREDIT_CARD: 'Cartão adicionado com sucesso!',
  ADD_CREDIT_CARD_ERROR: 'Falha ao adicionar cartão!',
  ADD_CREDIT_CARD_ERROR_DESC: 'Por favor, revise os dados e tente novamente.',

  PREMIUM_EXCLUSIVE: 'Disponível apenas para assinantes Premium',
  BEGIN_PREMIUM: 'Iniciar Premium',

  // SIGN PREMIUM MODAL
  SIGN_PREMIUM_MODAL_TITLE: 'Sua barbearia pode ',
  SIGN_PREMIUM_MODAL_TITLE_SPAN: 'muito mais!',
  MEMBERSHIP_BENEFIT_1_NEW: 'Gestão de agendamentos',
  MEMBERSHIP_BENEFIT_2_NEW: 'Acesso exclusivo para cada barbeiro',
  MEMBERSHIP_BENEFIT_3_NEW: 'Link de agendamento personalizado',
  MEMBERSHIP_BENEFIT_4_NEW: 'Número ilimitado de barbeiros',
  MEMBERSHIP_BENEFIT_5_NEW: 'Número ilimitado de serviços',
  MEMBERSHIP_BENEFIT_6_NEW: 'Controle financeiro automatizado',
  MEMBERSHIP_BENEFIT_7_NEW: 'Relatórios e métricas do seu negócio',
  MEMBERSHIP_BENEFIT_8_NEW: 'Notificação de agendamentos',
  MEMBERSHIP_BENEFIT_9_NEW: 'Produtos e descontos nos agendamentos',
  SIGN_PREMIUM_MODAL_BUTTON: 'Assinar agora por {{currency}} 69,90/mês',

  SELECT_UNITY: 'Selecione sua unidade',
  PENDING_CONFIGURATION: 'Pendente configuração',
  UNITYS: 'Unidades',
  UNITY_TABLE: 'UNIDADE',
  ADD_UNITY: 'Adicionar unidade',
  UNITY_NAME: 'Nome da unidade',
  ADD_UNITY_SUCCESS: 'Unidade adicionada com sucesso',
  UNITY_BLOCKED: 'Múltiplas unidades são exclusivas para assinantes',
  SEND_NOTIFICATION: 'Enviar notificação',
  SEND_NOTIFICATION_TITLE: 'Envie uma mensagem em massa para todos os seus clientes de uma forma simples e prática.',
  SEND_NOTIFICATION_PLACEHOLDER: 'Ex: Nova promoção na barbearia',
  SEND_NOTIFICATION_SUCCESS: 'Mensagem enviada com sucesso',
  SEND_NOTIFICATION_ERROR: 'Erro ao enviar mensagem',
  SEND: 'Enviar',

  // SINGLE PRODUCT SELL
  SINGLE_PRODUCT_SELL: 'Venda avulsa',
  SINGLE_PRODUCT: 'Venda de produto',
  BARBER_OPTIONAL: 'Barbeiro (opcional)',
  SINGLE_SALE_SUCCESS: 'Venda avulsa criada com sucesso',
  SINGLE_SALE_ERROR: 'Houve um erro ao criar venda avulsa',
  SINGLE_SALE_DELETE_SUCCESS: 'Venda avulsa deletada com sucesso',
  SINGLE_SALE_DELETE_ERROR: 'Houve um erro ao deletar venda avulsa',
  SELECT_DISCOUNT: 'Selecionar desconto',
  GET_SINGLE_SALE_ERROR: 'Houve um erro ao carregar venda avulsa',
  CONFIRM_DELETE_SINGLE_SALE: 'Deseja deletar a venda avulsa?',
  DELETE_SINGLE_SALE_SUCCESS: 'Venda avulsa deletada com sucesso',
  DELETE_SINGLE_SALE_ERROR: 'Houve um erro ao deletar venda avulsa',

  MEET_US: 'Como nos conheceu?',
  MEET_US_DESC: 'Nos ajude informando onde conheceu o BestBarbers para que seja possível alcançarmos mais barbearias.',
  SAVE_DATA_SUCCESS: 'Dados salvos com sucesso',
  PRODUCT_SALE: 'Venda de produtos',

  ERROR_GET_UNITYS: 'Erro ao carregar unidades',
  CHANGE_UNITY: 'Trocar unidade',
  EDIT_PERMISSION: 'Editar Permissão',
  EDIT_PERMISSION_TITLE: 'Editar permissão do colaborador',
  FINANCE: 'Financeiro',
  SINGLE_PRODUCT_PERMISSION: 'Venda de produtos avulsos',
  APPOINTMENT_PERMISSION: 'Agendamentos',
  CLIENTS_PERMISSION: 'Clientes',
  HISTORIC_PERMISSION: 'Histórico',
  SERVICES_PERMISSION: 'Serviços',
  BLOCKED_HOURS_PERMISSION: 'Horários bloqueados',
  TEAM_PERMISSION: 'Equipe',
  ADDITIONAL_PRODUCTS_PERMISSION: 'Produtos adicionais',
  UNITYS_PERMISSION: 'Unidades',
  PAYMENT_METHODS_PERMISSION: 'Métodos de pagamentos',
  NOTIFICATIONS_PERMISSION: 'Notificações',
  BLOCKED_PERMISSION: 'Sem acesso',
  ONLY_READ: 'Somente leitura',
  EDIT: 'Criar e editar',
  PERMISSION_SUCCESS: 'Permissões atualizadas com sucesso',
  PERMISSION_ERROR: 'Erro ao atualizar as permissões',
  PERMISSION_DENIED: 'Você não tem permissão para realizar essa ação',
  NOTIFICATIONS_TITLE: 'Notificações',
  NOTIFICATIONS_DESC_BARBERSHOP:
    'Defina as preferências de notificações da sua barbearia quanto ao envio de e-mail e push',
  CREATE_UNITY_TITLE: 'Deseja criar uma nova unidade?',
  CREATE_UNITY_SUB_1: 'A criação de uma nova unidade custará ',
  CREATE_UNITY_SUB_2: ' que será cobrado imediatamente, e posteriormente incluída na sua fatura mensal',
  CREATE: 'Criar',
  FLAG: 'Bandeira',
  SELECT_FLAG: 'Selecione a bandeira',
  SELECT_FUNCTION: 'Selecione a função',
  SELECT_UNITY_: 'Selecione a unidade',
  CLOSED: 'Fechado',
  VALUE_PAYMENT: 'Valor do pagamento',
  DISCOUNT_BARBER_APPOINTMENT: 'Este valor é descontado dos barbeiros?',
  DISCOUNT_BARBER_SUBTITLE_APPOINTMENT:
    'Caso a comissão do barbeiro seja calculada após o valor de desconto, marque sim.',
  ALL_BARBERS: 'Todos os barbeiros?',
  LOGOUT: 'Sair',
  CHANGE_IMAGE: 'Alterar imagem',
  ENTER_TOKEN: 'Insira a nova senha desejada',
  PLACEHOLDER_BLOCKED: 'Ex: Horário de almoço',
  DESCRIPTION_: 'Descrição',
  BLOCKED_HOURS: 'Horários bloqueados',
  PAYMENT_METHOD_DESCRIPTION: 'Nome do método de pagamento',
  REOPEN_APPOINTMENT: 'Reabrir comanda',
  ERROR_ADD_SERVICE: 'Erro ao adicionar serviço',
  ERROR_REMOVE_SERVICE: 'Erro ao remover serviço',
  APPOINTMENT_REOPEN_SUCCESS: 'Agendamento reaberto com sucesso',
  APPOINTMENT_REOPEN_ERROR: 'Agendamento reaberto com sucesso',
  REOPENED_: 'Reaberta',
  CASHIER_PERMISSION: 'Caixa',
  REOPEN_APPOINTMENT_PERMISSION: 'Reabrir comanda',
  TYPE: 'Tipo',
  SELLS_REPORT_DESCRIPTION: 'Valores finananceiros considerados apenas dos agendamentos já finalizados',
  INSERT_BATCH: 'Inserir lote',
  STOCK: 'Estoque',
  FAILED_CREATE_STOCK: 'Falha ao salvar lançamento',
  FAILED_GET_STOCK: 'Erro ao carregar estoque',
  SUCCESS_CREATE_STOCK: 'Lançamento salvo com sucesso',
  SELECT_ADDITIONAL_PRODUCT: 'Selecione o produto',
  QUANTITY_: 'Quantidade',
  COST_PRICE: 'Preço de custo',
  SALE_PRICE: 'Preço de venda',
  MINIMUM_ALERT: 'Alerta mínimo de quantidade?',
  PRODUCER: 'Fabricante',
  FAILED_DELETE_STOCK: 'Falha ao excluir lançamento',
  SUCCESS_DELETE_STOCK: 'Lançamento salvo com sucesso',
  CONFIRM_DELETE_STOCK: 'Deseja excluir o lote?',
  ON_STOCK: 'Estoque:',
  PRODUCTS_MANAGER: 'Gerenciamento de produtos',
  PRODUCTS_REGISTERED: 'Produtos cadastrados',
  BATCHES_RECEIVED: 'Lotes recebidos',
  MISSING_PRODUCTS_LIST_TITLE: 'Lista de produtos pendentes!',
  MISSING_PRODUCTS_LIST_DESCRIPTION:
    'Lista de produtos que estão com a quantidade menor do que a configurada para alerta:',
  QUANTITY_LEFT: 'Quantidade restante',
  VERIFY: 'Verificar',
  STOCK_COST_VALUE: 'Custo na compra de produtos',
  PRODUCT_SELL: 'Compra de produto',
  CLIENT_BALANCE: 'Saldo do cliente',
  CREDIT_CLIENT_BALANCE: 'Adicionar crédito ao cliente?',
  CREDIT_CLIENT_BALANCE_DESC: 'Adicione o valor extra do seu cliente.',
  DEBIT_CLIENT_BALANCE: 'Adicionar débito ao cliente?',
  DEBIT_CLIENT_BALANCE_DESC: 'Adicione o valor devido do seu cliente.',
  CHARGE_CLIENT_BALANCE: 'Considerar abatimento de crédito ou debíto do cliente na comanda?',
  CHARGE_CLIENT_BALANCE_DESC: 'Adicione o valor de abatimento de crédito ou débito do seu cliente.',
  CLIENT_VALUE: 'Crédito do cliente',
  DISCOUNT_ON_COMMAND: 'Considerar valor nessa comanda?',
  CREDIT_DESC: 'Cliente ficará com o saldo positivo',
  DEBIT_DESC: 'Cliente ficará com o saldo negativo',
  TO_CHARGE_DESC_1: '1: Insira o valor que deseja abater do saldo do cliente.',
  TO_CHARGE_DESC_2: '2: Caso o saldo do cliente seja positivo, o valor será descontado da comanda.',
  TO_CHARGE_DESC_3: '3: Caso o saldo do cliente seja negativo, o valor será acrescido na comanda.',
  TO_CHARGE_DESC_4: '4: Não é necessário inserir valor negativo. Ex.: (-10,50)',
  USE_CREDIT: 'Usar crédito do cliente',
  USE_DEBIT: 'Considerar débito nessa comanda',
  CREDIT_DESC_: 'Cliente ficará com o saldo de: ',
  PRODUCT: 'Produto',
  COLLABORATOR: 'Colaborador',
  COLLABORATORS_REPORT: 'Relatório de colaboradores',
  COLLABORATOR_TYPE: 'Tipo de colaborador',
  SELLER_OPTIONAL: 'Vendedor (opcional)',
  OTHER_EMPLOYEES: 'Outros colaboradores',
  CLEARED_COMISSION: 'Dar baixa nas comissões',
  START_DATE: 'Data inicial',
  END_DATE: 'Data final',
  RESUME: 'Resumo',
  DATE_HOUR: 'Data e hora',
  NO_COMMISSION: 'Nenhuma comissão pendente para dar baixa no período e para o barbeiro selecionado',
  CREATE_COLLABORATOR_PASSWORD: 'Crie a senha do seu colaborador',
  CONFIRM_COLLABORATOR_PASSWORD: 'Confirme a senha do seu colaborador',
  MAXIMUM_SCHEDULE_TIME: 'Tempo máximo para agendamento',
  MAXIMUM_SCHEDULE_TIME_DESC:
    'Determine qual o prazo máximo para que o seu cliente consiga realizar a marcação de um agendamento (Ex.: 15 dias)',
  MINIMUM_CANCEL_TIME: 'Tempo mínimo de cancelamento',
  MINIMUM_CANCEL_TIME_DESC:
    'Determine qual o prazo mínimo para que o seu cliente consiga realizar o cancelamento de um agendamento (Ex.: 2 horas)',
  PERIOD_RECURRENCY: 'Período da recorrência',
  CHOOSE_DAY: 'Escolha o dia',
  entry: 'Entrada',
  exit: 'Saída',
  client_arrived: 'Cliente chegou',

  //Subscription --> Benefits -> SubscriptionModal
  REGULARIZE_SIGNATURE: 'Regularizar assinatura',
  SUBSCRIPTION_BENEFITS: 'Veja os benefícios da assinatura',
  SUBSCRIPTION_HAS_EXPIRED: 'Sua assinatura expirou!',
  SUBSCRIPTION_PLAN_BENEFITS:
    'Seu plano de assinatura do BestBarbers expirou! Não deixe seus clientes na mão! Regularize seu plano agora mesmo e volte a desfrutar de todos os benefícios da versão premium!',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_1: 'Notificação de agendamentos',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_2: 'Link de agendamentos personalizado',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_3: 'Controle financeiro 100% automatizado',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_4: 'Gestão de agendamentos',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_5: 'Número ilimitado de barbeiros',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_6: 'Acesso exclusivo para cada barbeiro',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_7: 'Gestão de clientes',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_8: 'Criação de serviços personalizados',
  CREATE_ACCOUNT_BENEFITS_BARBERSHOP_9: 'Relatórios e métricas do seu negócio',
  TO_GO_BACK: 'Voltar',
  PAYMENTS_MANAGER: 'Gestão de pagamentos',
  SIGNATURE_CLUB: 'Clube de assinatura',
  PAYMENT_ON_APP: 'Pagamento pelo app',
  SUBSCRIBERS: 'Assinantes',
  ANTECIPATIONS: 'Saldo',
  SIGNATURE_CLUB_DESC:
    'Com o clube de assinatura, seus clientes pagarão pelo cartão de crédito uma gama de serviços oferecidos pela barbearia e terá direito de usufruir desses serviços com um valor fixo no mês',
  SIGNATURE_BLOCKED: 'Clube de assinaturas é exclusivo para assinantes.',
  PAYMENT_ON_APP_BLOCKED: 'Pagamento pelo app é exclusivo para assinantes.',
  ANTECIPATIONS_BLOCKED: 'Saldo e antecipações é exclusivo para assinantes.',
  ACTIVATE_SIGNATURE_CLUB: 'Ativar serviço de assinaturas',
  SIGNATURE_WARN_1: 'O valor da assinatura será cobrado automaticamente do seu cliente mensalmente',
  SIGNATURE_WARN_2: 'Não se preocupe! Toda a gestão da assinatura será feita pelo BestBarbers',
  SIGNATURE_WARN_3: 'Lembre-se de clicar no botão salvar para aplicar as alterações',
  PAYMENT_APP_ON: 'Habilitar pagamento pelo app',
  PAYMENT_ON_APP_DESC:
    'Ao habilitar o pagamento pelo app, o cliente poderá realizar o pagamento pelo cartão de crédito ao realizar o agendamento',
  CHARGE_FORM: 'Forma de cobrança',
  ON_SCHEDULE: 'Cobrar do cliente no momento do agendamento',
  ON_FINISH: 'Cobrar do cliente ao finalizar agendamento',
  PAYMENT_ON_APP_DESC_2: 'O pagamento na barbearia continuará ativo, ficando como opção do cliente.', //ok
  CLICK_TO_SAVE: 'É preciso clicar em salvar para alterar as preferências',
  ON_SCHEDULE_DESC: 'Descrição: A cobrança no cartão será realizada no momento que o cliente realizar o agendamento', //ok
  ON_FINISH_DESC: 'Descrição: A cobrança no cartão será realizada no momento que a barbearia finalizar o agendamento', //ok
  CANCEL_TAXES_WARN:
    'Caso o cliente cancele o agendamento que já está pago, será cobrado uma taxa de 5% do valor da transação realizada',
  HABILITY: 'Habilitar',
  DAYS_TO_CHARGE: 'O saque do valor estará disponível 32 dias após o pagamento',
  ANTECIPATION_SUCCESS: 'Antecipação realizada com sucesso',
  ANTECIPATION_ERROR: 'Falha ao realizar antecipação',
  BALANCE_EXCHANGE: 'Saldo disponível para saque',
  PENDING_VALUES: 'Valores pendentes recebimento',
  SELECT_VALUES_ANTECIPATION: 'Selecione os valores que deseja antecipar',
  LIQUID_VALUE_ANTECIPATION: 'Valor líquido',
  PREVISION_EXCHANGE: 'Previsão de saque',
  ANTECIPATE: 'Antecipar',
  EXCHANGE: 'Sacar por pix',
  PIX_SUCCESS: 'Pix realizado com sucesso',
  PIX_ERROR: 'Erro ao realizar pix',
  PIX_TYPES: 'Tipos de chave',
  PIX_KEY: 'Chave PIX',
  CONFIRM_PIX: 'Deseja realmente sacar o valor de ',
  NO_SUBSCRIBERS: 'Nenhum assinante encontrado',
  SEARCH_SUBSCRIBERS: 'Pesquisar por assinante',
  PLAN_DETAIL: 'Detalhes do plano',
  CANCEL_SIGNATURE: 'Cancelar assinatura',
  EXPIRED_AT: 'Data do vencimento',
  CONFIRMED: 'Confirmado',
  STATUS: 'Status',
  CANCEL_SIGNATURE_DESC: 'Deseja cancelar a assinatura do cliente?',
  CANCEL_SIGNATURE_SUCCESS: 'Assinatura cancelada com sucesso',
  CANCEL_SIGNATURE_ERROR: 'Erro ao cancelar assinatura',
  CHANGE_SIGNATURE_SUCCESS: 'Assinatura alterada com sucesso',
  CHANGE_SIGNATURE_ERROR: 'Erro ao alterar assinatura',
  signature: 'Assinatura',
  normal: 'Normal',
  CHANGE_SIGNATURE: 'Alterar assinatura',
  MOBILE_PHONE: 'Telefone celular',
  CONFIRMATE_DATA: 'Confirmar dados',
  MODAL_CONFIRMATE_DATA_SUBTITLE:
    'Para que não ocorra nenhum problema com os pagamentos da sua barbearia, confira seus dados',
  CANT_SCHEDULE: 'Não é possível agendar dois horários pelo clube',
  CANT_SCHEDULE_: 'Você possui um horário marcado no dia: ',
  INACTIVE: 'Inativo',
  SIGNATURES: 'Assinaturas',
  ERROR_SIGNATURE: 'Erro ao carregar assinaturas',
  TOTAL_RECEIVED: 'Total recebido',
  COMISSIONS: 'Comissões',
  SIGNATURE_REPORT: 'Detalhamento de assinaturas',
  UNITY_VALUE: 'Valor unitário',
  QUANTITY_TABLE: 'ATIVOS',
  TOTAL_VALUE: 'Valor total',
  ACTIVES: 'Ativas',
  PENDINGS: 'Pendentes',
  recurring_service_package: 'Pacote recorrente de serviço',
  individual_service_package: 'Pacote individual de serviço',
  APPOINTMENT_CONDITION_CANCEL: 'Permitido agendar até: ',
  APPOINTMENT_CONDITION_DOWNGRADE: 'Permitido agendar em plano superior até: ',
  PIX_EXCHANGE: 'Sacar',
  SINGLE_PRODUCT_BARBER: 'Venda de produto para barbeiro',

  // Settings -> invoice issuer
  SETTINGS_INVOICE_ISSUER_TITLE: 'Emissão de nota fiscal',
  SETTINGS_INVOICE_ISSUER_DESC:
    "Ative essa funcionalidade para possibilitar a geração de notas fiscais ao finalizar uma venda ou serviço, disponibilizando um seletor nas áreas de 'Detalhes da comanda' e 'Venda de produtos' para indicar se deseja gerar uma nota fiscal ao finalizar. Na página 'Histórico', você poderá acessar as notas fiscais de suas vendas ou emitir para aquelas que ainda não possuem uma.",
  EDIT_INVOICE: 'Editar nota fiscal',
  MANAGER_PACKAGE_CLIENT: 'Gerenciar cliente',
  PACKAGE_PAID: 'Pacote contratado',
  QUANTITY_HIRED: 'Quantidade contratada',
  PACKAGE_DELETED_SUCCESS: 'Pacote excluído com sucesso',
  PACKAGE_DELETED_ERROR: 'Erro ao excluir pacote',
  PACKAGE_RENEWED_SUCCESS: 'Pacote renovado com sucesso',
  PACKAGE_RENEWED_ERROR: 'Erro ao renovar pacote',
  PACKAGE_DELETED_DESC: 'Deseja excluir o pacote?',
  DELETE_PACKAGE: 'Excluir pacote',
  REFRESH_PACKAGE: 'Renovar pacote',
  REFRESH: 'Renovar',
  REFRESH_PACKAGE_DESC: 'Os dados de quantidade serão inseridos automaticamente conforme última contratação',
  TOTAL_GOAL_TABLE: 'META PREVISTA',
  COMISSION: 'Comissão',
  SPECIFIC_DAYS: 'Apenas dias específicos?',
  SPECIFIC_DAYS_SUB: 'Caso o atendimento pelo clube aconteça somente em dias específicos marque essa opção.',
  SELECT_SERVICES: 'Selecionar serviços do plano',
  SELECT_CLIENT: 'Selecionar cliente',
  SELECT_PLAN: 'Selecionar plano',
  GENERATE_LINK: 'Gerar link de pagamento',
  awaiting_payment: 'Aguardando pagamento',
  PAYMENT_CREDIT_CARD_CAPTURE_REFUSED: 'Cobrança recusada pela operadora',
  PAYMENT_CREATED: 'Cobrança criada',
  PAYMENT_CONFIRMED: 'Pagamento confirmado',
  PAYMENT_DELETED: 'Pagamento excluido',
  PAYMENT_RECEIVED: 'Cobrança recebida',
  total_sales: 'Total em vendas',
  service: 'Serviços',
  product: 'Produtos'
}
