import React, { useEffect, useState, useMemo } from 'react'
import { format, startOfMonth, endOfMonth } from 'date-fns'
import {
  Layout,
  FinanceResumeCard,
  Table,
  SelectDateModal,
  FinanceReleaseModal,
  FeatureBlockedMessage,
  FilterModal,
  AppointmentDetailsModal,
  SingleProductModal,
  ClearedComissionModal,
  WorthBarberModal,
  ModalAlert,
  Text,
} from 'components'
import { Center, Flex, Spinner, useTheme, useToast } from '@chakra-ui/react'
import { usePremiumBlock } from 'services/hooks'
import { useTranslation } from 'react-i18next'
import { useBarbershopStore } from 'services/stores'
import './hideScrollbar.css'
import { financesStore } from 'services/stores/finances'
import { useAppointmentDetailStore } from 'services/stores'
import { columnsDetailed, firstRender, secondRender, secondCards } from './settings'

import { useMutation, useQuery } from 'react-query'
import {} from 'services/api/endpoints'
import { extractReports } from 'utils/mockData'
import { UnitysModal } from 'components/organisms/Unitys'
import { usePermissionCollaborator } from 'services/hooks'
import { useLogoutPaymentIssues } from 'services/hooks/use-is-paid'
import { Filters } from './filters'
import { useBarbershopFinance } from 'services/hooks'
import { useBarberReleases } from 'services/hooks/use-barber-releases'

export const ExtractDetailedScreen = ({ ...props }) => {
  const { userData } = useBarbershopStore()
  const theme = useTheme()
  const toast = useToast()
  const { t } = useTranslation()
  const { getExtract } = useBarbershopFinance()

  const [releaseModalOpen, setReleaseModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [appointmentModalOpen, setAppointmentModalOpen] = useState(false)
  const [singleProductModalOpen, setSingleProductModalOpen] = useState(false)
  const [dateModalOpen, setDateModalOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedItem, setSelectedItem] = useState()
  const [status, setStatus] = useState()
  const [barber, setBarber] = useState()
  const [service, setService] = useState()
  const [transactionType, setTransactionType] = useState({})
  const [dateFilterType, setDateFilterType] = useState({
    value: 'date',
    name: 'Data de lançamento',
  })
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [startDate, setStartDate] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
  const [endDate, setEndDate] = useState(format(endOfMonth(new Date()), 'yyyy-MM-dd'))
  const { setMonthExtract, unity, setUnity } = financesStore()
  const [unityModalOpen, setUnityModalOpen] = useState(false)
  const permission = usePermissionCollaborator('finance')
  const single_product = usePermissionCollaborator('single_product')
  const appointmentPermission = usePermissionCollaborator('appointment')
  const isBlocked = usePremiumBlock()
  const [clearedModalOpen, setClearedModalOpen] = useState(false)
  const [worthModalOpen, setWorthModalOpen] = useState(false)
  const [worth, setWorth] = useState(null)
  const [paidAppointment, setPaidAppointment] = useState(null)
  const { deletePaidAppointments, deleteCollaboratorPaidAppointments } = useBarberReleases()
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const [app, setApp] = useState()

  useEffect(() => {
    setUnity(userData)
  }, [userData])

  const { data, refetch, isLoading, isError } = useQuery(
    ['get-extract-resume', unity, dateFilterType?.value],
    () =>
      getExtract({
        start_date: startDate,
        end_date: endDate,
        barbershop_id: userData?.barbershop?.id || userData?.id,
        date_filter: dateFilterType?.value,
      }),
    {
      onSuccess: (value) => {
        setLoading(false)
        setMonthExtract(value)
      },
      onError: (error) => {
        setLoading(false)
        toast({
          title: t('LOAD_COMMISSIONS_ERROR'),
          description: error.toString().substring(7),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
  const mutate = useMutation(
    'delete-paid-barber-releases',
    (params) => (params?.collaborator ? deleteCollaboratorPaidAppointments(params) : deletePaidAppointments(params)),
    {
      onSuccess: () => {
        toast({
          title: 'Pagamento excluido com sucesso',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
      },
      onSettled: () => {
        setPaidAppointment(null)
        setAlertModalOpen(false)
        refetch()
      },
      onError: (error) => {
        toast({
          title: 'Erro ao excluir lançamento',
          description: error?.response?.data?.error ?? 'Favor contatar o suporte',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      },
    }
  )
  const onSelectRelase = (item) => {
    setSelectedItem(item)
    if (item?.type === 'singleProduct' || item?.type === 'singleProductBarber') {
      if (single_product !== 'blocked') {
        setSingleProductModalOpen(true)
      } else {
        toast({
          title: t('PERMISSION_DENIED'),
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      }
      return
    }
    if (permission !== 'blocked') {
      setReleaseModalOpen(true)
    } else {
      toast({
        title: t('PERMISSION_DENIED'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const onSelectAppointment = (appointment) => {
    if (appointmentPermission !== 'blocked') {
      setApp(appointment)
      setAppointmentModalOpen(true)
    } else {
      toast({
        title: t('PERMISSION_DENIED'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }

  const onSelect = (item) => {
    if (item?.subscription?.type === 'signature' || item?.subscription?.type === 'individual_package_service') {
      return
    }
    if (item?.type === 'worth' || (item?.type === 'revenue' && item?.description && item?.barber)) {
      setWorth(item)
      setWorthModalOpen(true)
      return
    }
    if (item?.type === 'paid_appointment') {
      setPaidAppointment(item)
      setAlertModalOpen(true)
      return
    }
    if (
      item.type === 'expense' ||
      item.type === 'revenue' ||
      item.type === 'singleProduct' ||
      item?.type === 'singleProductBarber'
    ) {
      onSelectRelase(item)
      return
    }

    onSelectAppointment(item)
  }

  const onCloseModal = () => {
    refetch()
    setUnityModalOpen(false)
    setReleaseModalOpen(false)
    setSingleProductModalOpen(false)
    setAppointmentModalOpen(false)
    setSelectedItem(null)
  }

  const { onLogout } = useLogoutPaymentIssues()

  useEffect(() => {
    onLogout()
  }, [userData])

  const transactions = useMemo(() => {
    const all_transactions = data?.all_transactions || []
    if (!transactionType?.name) {
      return all_transactions
    }

    if (transactionType?.value === 'single_product') {
      return all_transactions?.filter((t) => t?.type === 'singleProduct' || t?.type === 'singleProductBarber')
    }
    if (transactionType?.value === 'all_entries') {
      return all_transactions?.filter(
        (t) => t.type !== 'expense' && t.type !== 'worth' && t.type !== 'paid_appointment'
      )
    }
    if (transactionType?.value === 'all_exits') {
      return all_transactions?.filter(
        (t) => t.type === 'expense' || t.type === 'worth' || t.type === 'paid_appointment'
      )
    }
    if (transactionType?.value === 'package_and_signatures') {
      return all_transactions?.filter((t) => !!t.subscription)
    }
    if (transactionType?.value === 'appointment') {
      return all_transactions?.filter((t) => !!t.simple_date)
    }
    if (transactionType?.value === 'pending') {
      return all_transactions?.filter((t) => t.status === 'pending' || t.status === 'confirmed')
    }
    return all_transactions?.filter((t) => t.type === transactionType?.value)
  }, [transactionType, data])

  if (!data || isLoading) {
    return (
      <Layout>
        <Center w="100%">
          <Spinner color="textLight" />
        </Center>
      </Layout>
    )
  }

  return (
    <Layout
      {...props}
      isPremiumScreen
      headerTitle={t('Extrato Financeiro Detalhado')}
      modalFilter
      withouPading
      modalFilterWidth="12%"
      contentPosition={isBlocked && 'relative'}
      mt={isBlocked && theme.pxToRem(24)}
      p={isBlocked && theme.pxToRem(24)}
      pr={3}
      // secondActionClick={() => setUnityModalOpen(true)}
      iconSecondActionText={'Shop'}
      subTitle={`Unidade: ${unity?.name}`}
      iconColor="black"
      secondActionText={t('CHANGE_UNITY')}
      headerActionText={!isBlocked && permission === 'edit' && t('ADD_RELEASE')}
      headerActionClick={() => setReleaseModalOpen(true)}
      px={100}
      overflowX="auto"
      thirtyClick={{
        onClick: () => setClearedModalOpen(true),
        text: t('CLEARED_COMISSION'),
        icon: 'Money',
      }}
    >
      <Filters
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        loading={loading}
        setLoading={setLoading}
        refetch={refetch}
        setFilterModalOpen={setFilterModalOpen}
        dateFilterType={dateFilterType}
        setDateFilterType={setDateFilterType}
        barber={barber}
        setBarber={setBarber}
        service={service}
        setService={setService}
        status={status}
        setStatus={setStatus}
        transactionType={transactionType}
        setTransactionType={setTransactionType}
      />
      {isBlocked && <FeatureBlockedMessage title="FINANCIAL_BLOCKED" section="EXTRACT" />}
      <Flex
        w="100%"
        flexDir="column"
        padding={isBlocked && theme.pxToRem(24)}
        paddingRight={4}
        pointerEvents={isBlocked && 'none'}
      >
        <Flex w="100%" mb={4}>
          {firstRender(isBlocked ? extractReports : data)?.map((item) => (
            <FinanceResumeCard {...item} label={t(item?.label)} description={t(item?.description)} h="100%" />
          ))}
        </Flex>
        <Flex w="100%" mb={4}>
          {secondRender(isBlocked ? extractReports : data)?.map((item) => (
            <FinanceResumeCard {...item} label={t(item?.label)} description={t(item?.description)} h="100%" />
          ))}
        </Flex>
        <Flex w="100%" mb={4}>
          {secondCards(isBlocked ? extractReports : data)?.map((item) => (
            <FinanceResumeCard {...item} label={t(item?.label)} description={t(item?.description)} h="100%" />
          ))}
        </Flex>
        <Table
          loading={isLoading}
          data={transactions}
          columns={columnsDetailed}
          isSelectableRowVariabled={data?.all_transactions?.map(
            (i) => i?.subscription?.type !== 'individual_package_service' && i?.subscription?.type !== 'signature'
          )}
          deleteButtom={transactions?.map((i) => i?.type === 'paid_appointment')}
          onSelect={onSelect}
        />
      </Flex>

      {dateModalOpen && (
        <SelectDateModal
          onlyMonth
          onClose={() => setDateModalOpen(false)}
          isModalOpen={dateModalOpen}
          selectedDate={selectedDate}
          onSelectDate={(date) => {
            setSelectedDate(date)
            setDateModalOpen(false)
          }}
        />
      )}

      {releaseModalOpen && (
        <FinanceReleaseModal isModalOpen={releaseModalOpen} onClose={onCloseModal} releaseData={selectedItem} />
      )}

      {appointmentModalOpen && (
        <AppointmentDetailsModal
          isModalOpen={appointmentModalOpen}
          onClose={onCloseModal}
          setAppointmentId={setApp}
          app={app}
        />
      )}

      {filterModalOpen && (
        <FilterModal
          isModalOpen={filterModalOpen}
          setBarber={setBarber}
          setService={setService}
          setStatus={setStatus}
          barber={barber}
          service={service}
          status={status}
          goBack={() => setFilterModalOpen(false)}
          startDate={startDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          endDate={endDate}
        />
      )}

      {singleProductModalOpen && (
        <SingleProductModal isOpen={singleProductModalOpen} onClose={onCloseModal} selectedSale={selectedItem} />
      )}
      {unityModalOpen && (
        <UnitysModal isModalOpen={unityModalOpen} onClose={onCloseModal} selected={unity} setSelected={setUnity} />
      )}
      {clearedModalOpen && (
        <ClearedComissionModal isModalOpen={clearedModalOpen} onClose={() => setClearedModalOpen(false)} />
      )}
      {worthModalOpen && (
        <WorthBarberModal
          isModalOpen={worthModalOpen}
          onClose={() => setWorthModalOpen(false)}
          refetch={refetch}
          edit={worth}
        />
      )}
      <ModalAlert
        isOpen={alertModalOpen}
        onClose={() => setAlertModalOpen(false)}
        onConfirm={() =>
          mutate.mutate({
            collaborator: paidAppointment?.employee_id,
            body: {
              payment_id: paidAppointment?.id,
            },
          })
        }
        primaryButtonLabel={t('DELETE')}
        modalTitle="Deseja reverter pagamento de comissão"
        loading={mutate.isLoading}
      >
        <Text fontWeight="bold" m={`${theme.pxToRem(16)} 0`}>
          O pagamento de comissão de todos os agendamentos, vales e gorjetas abatidos serão revertidos
        </Text>
      </ModalAlert>
    </Layout>
  )
}

ExtractDetailedScreen.path = '/extrato-detalhado'
ExtractDetailedScreen.title = 'Extrato Detalhado'
ExtractDetailedScreen.secured = true
